import React from 'react';
import './LDabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function LDabout() {
    return (
        <div className="ldabout">
            <div className="la-about">
                <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link>
                <div className="ld-desc">
                    <h1>LOGO DESIGN</h1>
                    <p>Logo design is the utmost important start for any business. It dictates everything
                    from your message, business card, brochure, marketing materials, website, online
                    marketing.</p><br/>
                    <p>We craft meaningful brands through visual identity, print and digital experience for 
                    small to medium business all over the world.</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                        <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default LDabout

import React from 'react';
import MCabout from '../MCabout';
import MCdesc from '../MCdesc';
import MCexp from '../MCexp';
import MCpro from '../MCpro';
import {Helmet} from "react-helmet";

function Motor_Components() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Motor Components Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's helped Motor Components projects boost their online presence and increase sales. View our website and find out what we can do for you." />
            <meta name="keywords" content="Motor Components, Motor Components Project, One5's Motor Components Project" />
        </Helmet>
            <MCabout />
            <MCexp />
            <MCpro />
            <MCdesc />
        </React.Fragment>
    )
}

export default Motor_Components

import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import MWDabout from '../MWDabout';
import MWDabout2 from '../MWDabout2';
import MWDDesc from '../MWDDesc';
import MWD_contact from '../MWD_contact';

function Magento_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Enhance Your Online Presence with One5 Digital's Magento Website Development Services in Melbourne</title>
        <meta name="description" content="Looking for professional Magento website development services in Melbourne? One5 Digital offers expert solutions to boost your online presence and maximize your e-commerce potential. Contact us today!" />
        <meta name="keywords" content="Magento website development services, Melbourne, One5 Digital, e-commerce, online presence, professional solutions" />
      </Helmet>
      <MWDabout />
      <MWDabout2 />
      <MWDDesc />
      <FeaturedProject />
      <Testimonials />
      <MWD_contact />
    </React.Fragment>
  )
}

export default Magento_Website_Development
import React from 'react';
import './SEOsteps.css';

function SEOsteps() {
    return (
        <div className="seosteps">
            <div className="seosteps-desc">
                <div className="seosteps-left">
                    <h1>SEO STEPS & STRATEGY</h1>
                </div>
                <div className="seosteps-right">
                    <ul className="seosteps-list scrollbar-warning">
                        <li className="seosteps-items">
                            <h4><span style={{color: '#F04E31'}} >01.</span> ON PAGE OPTIMIZATION</h4>
                            <li className="seosteps-item">Keywords</li>
                            <li className="seosteps-item">Meta Tags Optimization</li>
                            <li className="seosteps-item">Homepage Content</li>
                            <li className="seosteps-item">Keyword rich content (Landing Pages)</li>
                            <li className="seosteps-item">Robots.txt Optimization</li>
                            <li className="seosteps-item">Adding Keyword rich alt text to images</li>
                            <li className="seosteps-item">Adding keyword rich titles to hyperlinks</li>
                            <li className="seosteps-item">Adding heading tags (H1...H6)</li>
                            <li className="seosteps-item">GEO Sitemap & XML file</li>
                            <li className="seosteps-item">Updating Xml Sitemap</li>
                            <li className="seosteps-item">Updating simple html sitemap</li>
                            <li className="seosteps-item">Canonicalization</li>
                            <li className="seosteps-item">Installing Google analytics Code</li>
                        </li><br/>
                        <li className="seosteps-items">
                            <h4><span style={{color: '#F04E31'}} >02.</span> LOCAL SEARCH ENGINE OPTIMIZATION</h4>
                            <li className="seosteps-item">Local Search Engine submission</li>
                            <li className="seosteps-item">Meta Tags Optimization</li>
                            <li className="seosteps-item">Schema Integration</li>
                            <li className="seosteps-item">Updating Pages for Local Search (adding <br/>Local address and testimonials)</li>
                            <li className="seosteps-item">Local Directory submission</li>
                            <li className="seosteps-item">Customer Review submission to local <br/> directories</li>
                        </li><br/>
                        <li className="seosteps-items">
                            <h4><span style={{color: '#F04E31'}} >03.</span> WEB 2.0 & SOCIAL MEDIA OPTIMIZATION</h4>
                            <li className="seosteps-item">Social Bookmarking</li>
                            <li className="seosteps-item">Video Submission</li>
                            <li className="seosteps-item">Customer Reviews & Discussions</li>
                            <li className="seosteps-item">Link Popularity Development</li>
                        </li><br/>
                        <li className="seosteps-items">
                            <h4><span style={{color: '#F04E31'}} >04.</span> LINK POPULARITY DEVELOPMENT</h4>
                            <li className="seosteps-item">One way Link Development</li>
                            <li className="seosteps-item">Directory Submissions</li>
                            <li className="seosteps-item">Article submission per month</li>
                            <li className="seosteps-item">LBlog Creation per month</li>
                            <p className="seop">Creating customer focused business environment , helps the company<br/> to focus on delivering results. These helps to ensure trust and using <br/>remarking tools, we 
                               re-engage customers who are already familiar with  <br/>your business.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SEOsteps

import React from 'react';
import './STDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function STDesignAbout() {
    return (
        <div className="STDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>STATIONARY DESIGN</h1>
                    <h6>Welcome to One5 Digital's Stationary Design Service!</h6>
                    <p>At One5 Digital, we understand the importance of having visually appealing and professional stationery designs that accurately reflect your brand identity. Whether you need business cards, letterheads, envelopes, or any other stationery design, our experienced designers can help you create custom designs that leave a lasting impression on your clients and customers</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default STDesignAbout

import React from 'react';
import './WOOabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function WOOabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>WOO COMMERCE WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's Woo Commerce Website Development Service!</h6>
                <p>Boost Your Online Business with One5 Digital's Woo Commerce Website Development Services in Melbourne</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>In today's digital age, having a strong online presence is crucial for the success of any business. If you're looking to establish or enhance your e-commerce platform, One5 Digital in Melbourne is your go-to solution. With their expert WooCommerce website development services, they can create a feature-rich and user-friendly online store that will drive sales and enhance customer experience.</p>
                        {/* <p className='smp'>PHP (Hypertext Preprocessor) is a widely-used scripting language that is particularly suited for web development. It is known for its flexibility, scalability, and compatibility with various platforms and frameworks. One5 Digital harnesses the power of PHP to create dynamic, interactive, and feature-rich websites that cater to the unique needs of your business.</p> */}
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./woo_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WOOabout

// import React, { useState, useEffect, useRef , Component} from 'react';
import React, { Component} from 'react';
import './RNDesign_contact.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";
import $ from 'jquery'
import Recaptcha from 'react-recaptcha'    

	
export default class RNDesign_contact extends Component {

	 state = {
    inputEmail: '',
    inputMessage: '',
    inputFname: '',
	inputLname: '',
	inputTel: '',
	selectedFile:null,
    isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false,
	isFormSubmitted: false
  }

  // Handle visitor's interaction with inputs
  handleInput = event => {
    // Test for input and length of the value
    if (event.target.value.length > 0 && event.target.name !== 'inputEmail') {
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    // If input is for email address validate it with regexp
    if (event.target.name === 'inputEmail') {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (reg.test(String(event.target.value).toLowerCase())) {
        this.setState({
          [event.target.name]: event.target.value
        })
      }
    }
  }

	// onFileChange = event => {
    //   this.setState({ selectedFile: event.target.files[0] });
    // };
  
  // Show message in console when reCaptcha plugin is loaded
  onCaptchaLoad = () => {
    console.log('Captcha loaded')
  }

  // Update state after reCaptcha validates visitor
  onCaptchaVerify = (response) => {
    this.setState({
      isCaptchaValid: true
    })
  }

  handleFormSubmit = event => {
    event.preventDefault()
	// Test
	if (this.state.inputEmail.length > 0 && this.state.inputFname.length > 0 && this.state.inputLname.length > 0 && this.state.inputMessage.length > 0 && this.state.inputTel.length > 0) {
      this.setState({
        isErrorShown: false,
        isFormValid: true
      })

	  const formData = new FormData(); 
     
      // Update the formData object 
	//   if(this.state.selectedFile != null){
	// 	  formData.append( 
	// 		"my_file", 
	// 		this.state.selectedFile, 
	// 	  );
	//    }
		formData.append( 
        "inputEmail", 
        this.state.inputEmail 
      );
	  formData.append( 
        "inputFname", 
        this.state.inputFname 
      );
	  formData.append( 
        "inputLname", 
        this.state.inputLname 
      );
	  formData.append( 
        "inputMessage", 
        this.state.inputMessage 
      );
	  formData.append( 
        "inputTel", 
        this.state.inputTel 
      );
	  
	  
      // Send the form with AJAX
      $.ajax({
        data:  formData,
		type: 'POST',
        url: 'https://one5.me/one5_digital/send_contact_mail.php',
		async:true,
        crossDomain:true,
		processData: false,
		contentType: false,
		success: function(data) {
          
		  // Reset state after sending the form
		 
        },
        error: function(xhr, status, err) {
          console.error(status, err.toString())
        }
      })
	
		 this.setState({
			inputEmail: '',
			inputMessage: '',
			inputFname: '',
			inputLname: '',
			inputTel: '',
			//selectedFile: null,
			isCaptchaValid: false,
			isErrorShown: false,
			isFormValid: false,
			isFormSubmitted: true
		  })
		  $('#contactform')[0].reset();
	  
    } else {
      // Show error message
      this.setState({
		isFormSubmitted: false,
        isErrorShown: true
      })
    }
  }
  
  fileUploadClick = () => {
        $('#real-file').trigger('click');
    }

	render() {
		return (
			<div className="contactpage">
				<div className="con-left" data-aos='fade-up' data-aos-duration="1500">
					<h1>READY TO GET STARTED?</h1>
					<p>If you're ready to create custom red notice website hacked services that accurately reflect your brand identity and values, then One5 Digital is here to help. Contact us today to schedule your consultation and get started on creating designs that leave a lasting impression on your clients and customers!</p>
					{/* <div className="con-wrapper">
						<ul className="add-1">
							<h6 >MELBOURNE</h6>
							<div className="callc1">
								<a className="callc1" href="tel:0430176036"><img className="call-icon" src="./phone-icon_One5Digital.png" alt="Loading..." /> 0430 176 036 </a>
							</div> 
						</ul>
						<ul className="add-2">
							<h6>KENTUCKY</h6>
							<div className="callc2">
								<a className="callc2" href="tel:7866140991"><img className="call-icon" src="./phone-icon_One5Digital.png" alt="Loading..." /> + 1 786 614 9091 </a> 
							</div>
						</ul>
					</div> */}
				</div>
				<div className="con-right" data-aos='fade-up' data-aos-duration="1500">
					<form id="contactform" action="#" enctype="multipart/form-data">
						<div className="form-row">
							<div className="input-data">
								<input type="text" onChange={this.handleInput} name="inputFname" id="inputName" required />
								<div className="underline"></div>
								<label style={{ color: '#BDBDBD' }}>First Name</label>   
							</div>
							<div className="input-data">
								<input type="text" onChange={this.handleInput} name="inputLname" id="inputName" required />
								<div className="underline"></div>
								<label style={{ color: '#BDBDBD' }}>Last Name</label>  
							</div>
						</div> 
						<div className="form-row">
							<div className="input-data">
								<input type="email" onChange={this.handleInput} name="inputEmail" id="inputEmail" required />
								<div className="underline"></div>
								<label style={{ color: '#BDBDBD' }}>Email Address</label>   
							</div>
							<div className="input-data">
								<input type="tel" maxLength="10" onChange={this.handleInput} name="inputTel" id="inputTel" required />
								<div className="underline"></div>
								<label style={{ color: '#BDBDBD' }}>Phone Number</label>  
							</div>
						</div>
						<div className="form-row">
							<div className="input-data textarea">
								<textarea onChange={this.handleInput} name="inputMessage" id="inputMessage" cols="30" rows="10" required />
								<div className="underline"></div>
								<label style={{ color: '#BDBDBD' }}>Describe your project</label>   
							</div>
						</div>
						{/* <div className="file-upload">
							<input type="file" id="real-file"  hidden="hidden" onChange={this.onFileChange} /> 
							<button className="file-upload__button" type="button" id="custom-button" onClick={this.fileUploadClick}><BsPaperclip className="attact"/>
							<span className="file-upload__label" type="custom-text" id="custom-label">ATTACH A FILE (DOC, PDF, ZIP)</span></button>
						</div> */}
						<div className="form-row">
							<Recaptcha
							  onloadCallback={this.onCaptchaLoad}
							  sitekey="6LfZSuYbAAAAAGl6bLfjOXhunfexB4m59WvoVumj"
							  render="explicit"
							  verifyCallback={this.onCaptchaVerify}
							/>
						</div>
						<div className="form-row submit-btn">
							<div className="inner"></div> 
							<button type="button" class='btn btn--outline btn--medium' onClick={this.handleFormSubmit} >Send Message</button>
						</div>
					</form>
					{this.state.isFormSubmitted && (
						<fieldset class="msg success">
						  <p>Thank you for contacting us.</p>
						</fieldset>
					  )}

					  {this.state.isErrorShown && (
						<fieldset class="msg">
						  <p>Please, make sure to fill all fields.</p>
						</fieldset>
					  )}
				</div>
			</div>
		)
	}
}


//export default RNDesign_contact

import React from 'react';
import './BrandingDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function BrandingDesc() {
    return (
        <div className="Branding-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our branding service includes:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA POSTS</h6>
                            <p data-aos='fade-up' className="service-desc">We'll work with you to develop a comprehensive branding strategy that accurately reflects your business values, personality, and unique selling proposition. Our branding experts will conduct extensive research and analysis to identify your target audience, competitors, and market trends to create a branding strategy that helps you stand out in your industry.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>LOGO DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom logo designs that accurately reflect your brand identity and values. Our logo designs are designed to be memorable, timeless, and visually appealing, helping you establish a strong brand identity.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>BRANDING GUIDELINES</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom brand guidelines that outline your brand identity, including your logo, colors, typography, and tone of voice. Our brand guidelines ensure that your brand remains consistent across all marketing materials, helping you establish a recognizable and trustworthy brand identity.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>MARKETING MATERIALS</h6>
                            <p data-aos='fade-up' className="service-desc">We can design custom marketing materials, such as business cards, letterheads, brochures, and flyers, that accurately reflect your brand identity and values. Our marketing materials are designed to be visually appealing and effective in promoting your products or services.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandingDesc
import React from 'react';
import './MalwareDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function MalwareDesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our Malware Removal services include:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            <img src="./malware_One5Digital.png" alt="Loading..." />
                            <h6>MALWARE DETECTION AND REMOVAL</h6>
                            <p className="smd-desc">Our team uses state-of-the-art tools and techniques to identify and remove any malware from your systems. We thoroughly analyze your systems and identify potential vulnerabilities to prevent future attacks.
                            </p>
                        </ul>
                        <ul className="smdservice-2">
                            <img src="./responsive_One5Digital.png" alt="Loading..." />
                            <h6>SYSTEM OPTIMIZATION</h6>
                            <p className="smd-desc">We optimize your systems to ensure they operate at peak performance, reducing the risk of future malware infections.</p>
                        </ul>
                        <ul className="smdservice-3">
                            <img src="./website_One5Digital.png" alt="Loading..." />
                            <h6>SYSTEM SECURITY</h6>
                            <p className="smd-desc">We implement advanced security measures to protect your systems from future attacks, including firewalls, antivirus software, and intrusion detection systems.</p>
                        </ul>
                        <ul className="smdservice-4">
                            <img src="./server_One5Digital.png" alt="Loading..." />
                            <h6>DATA BACKUP AND RECOVERY</h6>
                            <p className="smd-desc">We provide data backup and recovery services to ensure your critical data is always safe and accessible, even during a malware attack.</p>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='smdesc'>
                <p className='smp'>At One5 Digital, we understand that every business is unique, and there is no one-size-fits-all solution for Malware Removal. That's why we work closely with our clients to understand their needs and tailor our services accordingly. Our team of experts is available 24/7 to provide support and assistance, ensuring your systems are always secure.</p>
                <p className='smp'>In conclusion, malware attacks can have severe consequences for businesses, making investing in professional Malware Removal services essential. One5 Digital's expert team of cybersecurity professionals provides comprehensive Malware Removal services in Melbourne, ensuring your systems are secure and free from any malicious software. Contact us today to learn more about how we can protect your business from cyber threats.</p>
            </div>
        </div>
    )
}

export default MalwareDesc
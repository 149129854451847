import React from 'react';
import TestimonialPage from '../TestimonialPage';
import {Helmet} from "react-helmet";

function Testimonials() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>One5 Digital Agency - Client Testimonials & Reviews</title>
				<meta name="description" content="One5 is the trusted partner of businesses across Melbourne and beyond, with real client testimonials and feedback from happy customers." />
				<meta name="keywords" content="One5 - Client Testimonials & Reviews" />
			</Helmet>
            <TestimonialPage />
        </React.Fragment>
    )
}

export default Testimonials

import React from 'react';
import './SEOdetails.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SEOabout() {
    return (
        <div className="seodetails">
            <div className="seo-details">
                <div className="seo-desc">
                    <h1>What is included with this Monthly SEO service by One5 Digital?</h1>
                    <ul className="exp-list">
                        <li data-aos='fade-up' className="exp-items">Keyword Research – what keywords should you target? What keywords are your competitors targeting that you should target too?</li>
                        <li data-aos='fade-up' className="exp-items">Identifying Technical Issues and provide recommendations for optimization</li>
                        <li data-aos='fade-up' className="exp-items">Identifying On-page Issues and provide recommendations</li>
                        <li data-aos='fade-up' className="exp-items">Link building (Off-Page link building with content creation included)</li>
                    </ul>
                    <h1>Why choose this Monthly SEO ?</h1>
                    <ul className="exp-list">
                        <li data-aos='fade-up' className="exp-items">We listen to what you need. Each service is tailored to your website. There is no one-size fits all delivery with our gig.</li>
                        <li data-aos='fade-up' className="exp-items">We give you a road map on how to maximize your Google ranking for your target keywords.</li>
                        <li data-aos='fade-up' className="exp-items">All of our recommendations that we provide are easy to work through and implement.</li>
                        <li data-aos='fade-up' className="exp-items">Friendly service, ask us anything! There are no silly questions.</li>
                        <li data-aos='fade-up' className="exp-items">If you succeed, we succeed!</li>
                        <li data-aos='fade-up' className="exp-items">6 or 12 months agreement.</li>
                    </ul>
                    <h1>All enquiries...</h1>
                    <p className="seo-desc">We provide free report stats for your website, just for messaging about our monthly seo service. We can then advise on how we can help. Your Google ranking can only get better from here!
                    </p>
                    <div className='seodetails-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default SEOabout

import React from 'react';
import './MCDesignDesc3.css';

function MCDesignDesc3() {
  return (
    <div className="MCDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./malicious_code2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>At One5 Digital, we understand that every website is unique and may require different levels of security. Therefore, we offer customizable malware removal services to suit your specific needs. Our services include a thorough scan of your website to identify any malware, removal of malware and malicious code, and post-removal checks to ensure that your website is completely free from malware.</p>
            <p>We offer fast and reliable services to ensure your website stays up and running without delays or downtime. Our team is available 24/7 to address any issues you may have, and we provide ongoing support to ensure that your website remains secure and protected.</p>
            <p>In conclusion, having reliable and professional malware removal services is essential for securing your website. One5 Digital's malicious code removal services in Melbourne are designed to protect your website from malware attacks and ensure that your online presence remains safe and secure. Contact us today to learn more about our services and how we can help you protect your website.</p>
        </div>
    </div>
  )
}

export default MCDesignDesc3
import React from 'react';
import AXabout from '../AXabout';
import AXdesc from '../AXdesc';
import AXexp from '../AXexp';
import AXpro from '../AXpro';
import {Helmet} from "react-helmet";

function Axlefix() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Axlefix Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Axlefix project provides mechanical repairs, servicing and maintenance for all vehicle types. Visit us online for more information or to book a service." />
            <meta name="keywords" content="Axlefix, Axlefix Project, One5's Axlefix Project" />
        </Helmet>
            <AXabout />
            <AXexp />
            <AXpro />
            <AXdesc />
        </React.Fragment>
    )
}

export default Axlefix

import React from 'react';
import './SDwork.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SDwork() {
  return (
    <div className='sdwork'>
      <div className='sdwork-container'>
        <div className='sd-work'>
          <div className="sdleft-desc">
            <h1 data-aos='fade-up' className='sdworkh'>Some of our <span style={{ color: '#F04E31' }} > Work </span> that we are pretty damn proud of ...</h1>
          </div>
          <div data-aos='fade-up' className="sdright-desc">
            <p className="sd-desc">One5 Digital is a Shopify Partner Agency, we place equal value on the power of Shopify web development and outstanding visual web design. Our Shopify developers adopt a strategic and reliable approach towards ecommerce website development to ensure every stage is secure and future-proof. Focused on quality, our Shopify developers use best-practice code techniques incorporating SEO, responsiveness to screen size and optimised for speed. <br />All of our  developers have an outstanding design eye to ensure the execution and creation of our websites are pixel perfect to the designs. We craft bespoke themes from scratch to create unique, engaging and conversion focused ecommerce websites. We exclusively build with Shopify meaning we understand the platform's architecture inside and out and offer true expertise within our field. We design, develop and optimize eCommerce websites & email marketing campaigns for some of the world's most popular brands.</p>
          </div>
        </div>
        <div className='sdworks'>
          <div className='sdworkcol-left'>
            <a href="https://superstarboards.com.au/" target="blank"><img src="./SD_SB_One5Digital.png" alt="Loading..." className="sdwimg" /></a>
            <p className='sdwork-desc-left'><a href="https://superstarboards.com.au/" target="blank" className='sda'>Super Star Boards</a></p>
          </div>
          <div className='sdworkcol-right'>
            <a href="https://www.amazingamazon.com.au/" target="blank"><img src="./AA_SD_One5Digital.png" alt="Loading..." className="sdwimg" /></a>
            <p className='sdwork-desc-left'><a href="https://www.amazingamazon.com.au/" target="blank" className='sda'>Amazing Amazon</a></p>
          </div>
        </div>
        <div className='sdworks'>
          <div className='sdworkcol-left'>
            <a href="https://venusvirgintomarz.space/" target="blank"><img src="./SD_VV_One5Digital.png" alt="Loading..." className="sdwimg" /></a>
            <p className='sdwork-desc-left'><a href="https://venusvirgintomarz.space/" target="blank" className='sda'>Venus Virgin</a></p>
          </div>
          <div className='sdworkcol-right'>
            <a href="https://ausport.com.au/" target="blank"><img src="./SD_AS_One5Digital.png" alt="Loading..." className="sdwimg" /></a>
            <p className='sdwork-desc-left'><a href="https://ausport.com.au/" target="blank" className='sda'>Ausport</a></p>
          </div>
        </div>
        <div className='sdw-btn'>
            <Link exact to="./Shopify_Projects">
            <Button className='btn' buttonStyle='btn--primary'>View All</Button>
            </Link>
        </div>
      </div>
    </div>
  )
}

export default SDwork
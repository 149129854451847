import React from 'react';
import './LSdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function LSdesc() {
    return (
        <div className="lsdesc">
            <div className="lsdesc-cat2">
                <div className="lsdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>CLADCRAFT</h1>
                    <p>CladCraft offer’s an always expanding range of metal cladding solutions for your projects. We are accustomed to working with designers and architects for a unique design 
                aesthetic for many Melbourne City commercial & residential properties. </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Cladcraft">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="lsdesc2-right">
                <img className="lsdesc2ri" src="./CladcraftProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default LSdesc
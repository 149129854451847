/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './BDabout.css';
import { Link } from 'react-router-dom';

function BDabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <div className = 'bdabout'>
            <div className = 'bdabout-container'>
				<div className = 'bdaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>BEAR DOG WALKING SERVICE</h1>
                </div>
                <div className = 'bdaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'bdabout-desc'>Bear Dog Walking Service was developed to provide a quality service to families who wanted their dogs walked. As our lives get busier due to work,
                     school or life events why should our pets miss out on their daily exercise?
                    </p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='bdabout-btn'>
                        <a href="https://beardogwalking.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="bdaboutcol-right">
                    <img className="bdimg" src= "./BeardogAbout_One5Digital.png" alt= "Loading..." />
                    <img className="bdimg2" src= "./BeardogAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default BDabout

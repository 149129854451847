import React from 'react';
import './BAabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function BAabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>BING ADS</h1>
                <h6>Welcome to One5 Digital's Bing Ads Service!</h6>
                <p>Are you looking to grow your business's online presence through Bing Ads? Look only as far as One5 Digital, your one-stop destination for Bing Ads services in Melbourne!</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>One5 Digital is a leading digital marketing agency specializing in online advertising services, including Bing Ads. Our team of experts has years of experience in designing, creating, and optimizing Bing Ads campaigns to help our clients achieve their business objectives.</p>
                        <p className='smp'>At One5 Digital, we understand that every business is unique, so we take a personalized approach to our Bing Ads services. We work closely with our clients to understand their business goals, target audience, and budget, and then create a tailored Bing Ads campaign that drives results.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./bingads_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BAabout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './PSabout.css';
import { Link } from 'react-router-dom';

function PSabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'psabout'>
            <div className = 'psabout-container'>
				<div className = 'psaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>YOUR ONLINE PET STORE</h1>
                </div>
                <div className = 'psaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'psabout-desc'>Explore our extensive selection of foods, toys, treats, and more, designed to provide your beloved pet with the best in nutrition and comfort. Store pride theirselves on speedy and efficient online service. They have all the big brands available for sale online as well as the smaller more unique items for your pet.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2023</p>
                    <div className='psabout-btn'>
                        <a href="https://youronlinepetstore.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="psaboutcol-right">
                    <img className="psimg" src= "./PetStoreAbout_One5Digital.png" alt= "Loading..." />
                    <img className="psimg2" src= "./PetStoreAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default PSabout

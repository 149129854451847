import React from 'react';
import './EComWDAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function EComWDAbout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>ECOMMERCE WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's ECommerce Website Development Service!</h6>
                <p>In today's fast-paced digital world, having a strong online presence is crucial for the success of any business.</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>With the growing popularity of e-commerce, it is essential to have a well-designed and functional online store that not only attracts customers but also drives sales. If you're in Melbourne and searching for reliable e-commerce website development services, look no further than One5 Digital.</p>
                        <p className='smp'>One5 Digital is a leading digital agency based in Melbourne, specializing in web development, design, and digital marketing solutions. With a team of experienced professionals, they have successfully delivered exceptional e-commerce websites for businesses across various industries.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./ecom_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EComWDAbout

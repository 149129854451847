import React from 'react';
import './SWexp.css';

function SWexp() {
    return (
        <div className="swexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Samantha Willis is a young Australian writer, at homepage of Samantha's website we've animated banner, cover details about the book, author. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="sw-video" autoPlay loop muted>
                    <source src="./Samantha_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Samantha_One5Digital.gif" alt= "Loading..." className="sw-video"  />
            </div>
        </div>
    )
}

export default SWexp

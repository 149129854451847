import React from 'react';
import './WDesignDesc2.css';

function WDesignDesc2() {
    return (
        <div className="WDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Website Design Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>PERSONALIZED APPROACH </h6>
                            <p className="ec-desc">We take a personalized approach to each website design project, ensuring that your website accurately reflects your brand identity and meets your specific business needs.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>PROFESSIONAL DESIGNERS</h6>
                            <p className="ec-desc">Our team of designers and developers have years of experience in website design and development, ensuring that your website is both visually appealing and functional.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>AFFORDABLE PRICING </h6>
                            <p className="ec-desc">We offer affordable pricing for our website design and services, so you can get a professional website that fits within your budget.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>FAST TURNAROUND </h6>
                            <p className="ec-desc">We aim to deliver your website design within 2-3 weeks, so you can start using your new website as soon as possible.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>CUSTOMER SATISFACTION GUARANTEED</h6>
                            <p className="ec-desc">We want you to be completely satisfied with your website design and services, which is why we offer unlimited revisions until you're happy with the final product.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WDesignDesc2
import React from 'react';
import './MalwareAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function MalwareAbout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>MALWARE REMOVAL</h1>
                <h6>Welcome to One5 Digital's Malware Removal Service!</h6>
                <p>In today's digital age, businesses of all sizes rely heavily on technology to operate efficiently. However, this increased reliance on technology makes businesses more vulnerable to cyber threats like malware.</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>Malware is malicious software designed to cause damage to computer systems, steal sensitive information, and disrupt business operations. Malware attacks can lead to significant financial losses, damage to a company's reputation, and potential legal liabilities. Therefore, businesses must protect themselves from malware attacks by investing in professional Malware Removal services.</p>
                        <p className='smp'>One5 Digital is a Melbourne-based company that provides comprehensive Malware Removal services to businesses across various industries. Our team of cybersecurity professionals understands the complexity of malware attacks and the devastating effects they can have on businesses. We work tirelessly to identify and remove any malicious software from your systems and ensure your business is secure from any future attacks.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./malware_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MalwareAbout

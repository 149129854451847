import React from 'react';
import LDabout from '../LDabout';
// import LDfeature from '../LDfeature';
import LDpricing from '../LDpricing';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import Getintouch from '../Getintouch';

function Logo_Design_Melbourne() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Best Custom Logo Design Services in Melbourne | One5</title>
				<meta name="description" content="One5 is one of the leading best custom logo design services company in Melbourne, Australia. Creating memorable brands and unique logo designs that are the focal point of your business." />
				<meta name="keywords" content="Logo Design, Best Logo Design, Logo Design Service, Logo Design Melbourne, Business Logo Design, Custom Logo Design, Logo Design Services in Melbourne, Logo Design Services in Australia, Professional Logo Design, Company logo design, Creative Logo Design" />
			</Helmet>
            <LDabout />
            {/* <LDfeature /> */}
            <LDpricing />
            <FeaturedProject />
            <Testimonials />
            <Getintouch />
        </React.Fragment>
    )
}

export default Logo_Design_Melbourne

import React from 'react';
import './SDpartners.css';

function SDpartners() {
  return (
    <div className='sdpartner'  data-aos='fade-up' data-aos-duration="500">
      <div>
        <h1>OUR PARTNERS</h1>
      </div>
      <div className="sdp-logos">
        <div className="sdplogo1">
          {/* <img className="sdplogo" src="./SDcart2cart_One5Digital.png" alt="Loading..." /> */}
          <img className="sdplogo" src="./SDgorgias_One5Digital.png" alt="Loading..." />
          <img className="sdplogo" src="./SDklarna_One5Digital.png" alt="Loading..." />
          <img className="sdplogo" src="./SDklaviyo_One5Digital.png" alt="Loading..." />
          <img className="sdplogo" src="./SDmailchimp_One5Digital.png" alt="Loading..." />
        </div>
        <div className="sdplogo2">
          <img className="sdplogo" src="./SDoptimizely_One5Digital.png" alt="Loading..." />
          <img className="sdplogo" src="./SDshipstation_One5Digital.png" alt="Loading..." />
          {/* <img className="sdplogo" src="./SDshopify_One5Digital.png" alt="Loading..." /> */}
          <img className="sdplogo" src="./SDskubana_One5Digital.png" alt="Loading..." />
        </div>
      </div>
    </div>
  )
}

export default SDpartners
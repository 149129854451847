import React from 'react';
import './PHPabout2.css';

function PHPabout2() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./website_hacked2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>PHP (Hypertext Preprocessor) is a widely-used scripting language that is particularly suited for web development. It is known for its flexibility, scalability, and compatibility with various platforms and frameworks. One5 Digital harnesses the power of PHP to create dynamic, interactive, and feature-rich websites that cater to the unique needs of your business.</p>
            <p>What sets One5 Digital apart is their commitment to delivering exceptional results. The team at One5 Digital understands that every business has its own set of goals and requirements. They take the time to thoroughly analyze your needs and objectives before embarking on the development process. This ensures that the end product aligns perfectly with your vision.</p>
        </div>
    </div>
  )
}

export default PHPabout2
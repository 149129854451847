/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Portfolio() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="portfolio">
            <h1 data-aos='fade-up'>One5 Digital works with you to understand your business, your product, your competitors 
            and most importantly your customers.</h1>
            <div data-aos='fade-up' className='portfolio-btn'>
                <Link excat to="/Projects">
                    <Button className='btn' buttonStyle='btn--white' >Explore Our Work</Button>
                </Link>
            </div> 
        </div>
    )
}

export default Portfolio

import React from 'react';
import TFabout from '../TFabout';
import TFdesc from '../TFdesc';
import TFexp from '../TFexp';
import TFpro from '../TFpro';
import {Helmet} from "react-helmet";

function Miss_Australia_Tiffany_Shen() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Tiffany Shen Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's helped Miss Australia Tiffany Shen with her online presence. Read about this and more of our successful projects on our website." />
            <meta name="keywords" content="Tiffany Shen, Tiffany Shen Project, One5's Tiffany Shen project" />
        </Helmet>
            <TFabout />
            <TFexp />
            <TFpro />
            <TFdesc />
        </React.Fragment>
    )
}

export default Miss_Australia_Tiffany_Shen

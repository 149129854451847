import React from 'react';
import './SWdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SWdesc() {
    return (
        <div className="swdesc">
            <div className="swdesc-cat2">
                <div className="swdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>SUPER STAR BOARDS</h1>
                    <p data-aos='fade-up'>Super Star Boards have all had a poster of our heroes on our wall, whether it be Buddy, Billy Slater or Ellyse Perry. Now YOU can give the gift of daring to dream by 
                    creating a Superstar Board for your kid, grandchild... or big kid. It even makes a great MVP or Coaches Award!</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Super_Star_Boards">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="swdesc2-right">
                <img className="swdesc2ri" src="./SSbProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default SWdesc

import React from 'react';
import SDproject from '../SDproject';
import {Helmet} from "react-helmet";

function Shopify_Projects() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Shopify Developers Melbourne | Setup, Design, Theme Development | Shopify Marketing</title>
            <meta name="description" content="Are you looking for Melbourne Shopify Experts ? We’re one of the leading shopify developers with 10+ years of exp. Call your e-commerce specialist One5 Digital on 043017 6036" />
            <meta name="keywords" content="Shopify Developers Melbourne , Shopify Experts Melbourne" />
        </Helmet>
            <SDproject />
        </React.Fragment>
    )
}

export default Shopify_Projects;

import React from 'react';
import '../App';
import TnC from '../TnC';
import {Helmet} from "react-helmet";

function TermsandConditions() {
    return (
        <React.Fragment>
			<Helmet> 
				<title>Terms & Conditions | One5 Digital</title>
				<meta name="description" content="The Terms & Conditions of One5 Digital. By making your first payment to One5 Digital you are confirming that you have read, and are in agreement with, the terms and conditions" />
				<meta name="keywords" content="Terms & Conditions" />
			</Helmet>
            <TnC />
        </React.Fragment>
    )
}

export default TermsandConditions

import React from 'react';
import './BMexp.css';

function BMexp() {
  return (
    <div className="bmexp">
        <div>
            <h1>HOMEPAGE</h1>
            <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Moving your home and office across all Melbourne. Website design is simple and easy to use. Visit website for more look.</p>
        </div>
        <div>
            {/* <video className="bm-video" autoPlay loop muted>
                <source src="./MotorComponent_One5Digital.mp4" type="video/mp4" />
            </video> */}
            <img src="./BeatTheRestMovers_One5Digital.gif" alt= "Loading..." className="bm-video"  />
        </div>
    </div>
  )
}

export default BMexp
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Getintouch2.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Getintouch2() {
    const [, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="contact2">
            <div className="desc2">
                <p data-aos='fade-up' className = 'contact-shortdesc'>───── HAVE A PROJECT IN MIND?</p>
                <div className="left-contact2">
                    <h1 data-aos='fade-up'>LET US UNDERSTAND YOUR BUSINESS TO PROVIDE A SOLUTION</h1>
                    <div data-aos='fade-up' className='contact-btn'>
						<Link exact to="/Contact_One5_Digital">
							<Button className='btn' buttonStyle='btn--white' >Get in Touch</Button>
						</Link>
					</div>
				</div>
                {/* <div className="right-contact">
                    <img className="red-ball" src= "./red-ball.gif" alt= "Loading..." />
                </div> */}
            </div>
        </div>
    )
}

export default Getintouch2

import React from 'react';
import './CPexp.css';

function CPexp() {
    return (
        <div className="cpexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Clean Power Co. has the website design has attractive design and color pallet to the customers. Visit website for more look. </p>
            </div>
            <div>
                {/* <video className="cp-video" autoPlay loop muted>
                    <source src="./Cleanpower_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Cleanpower_One5Digital.gif" alt= "Loading..." className="cp-video"  />
            </div>
        </div>
    )
}

export default CPexp

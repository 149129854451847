import React from 'react';
import CCabout from '../CCabout';
import CCdesc from '../CCdesc';
import CCexp from '../CCexp';
import CCpro from '../CCpro';
import {Helmet} from "react-helmet";

function Cladcraft() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Cladcraft Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Cladcraft Project provides stunning, bespoke exterior cladding solutions for your project." />
            <meta name="keywords" content="Cladcraft, Cladcraft Project, One5's Cladcraft Project" />
        </Helmet>
            <CCabout />
            <CCexp />
            <CCpro />
            <CCdesc />
        </React.Fragment>
    )
}

export default Cladcraft
import React from 'react';
import './BMdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function BMdesc() {
    return (
        <div className="pedesc">
            <div className="pedesc-cat2">
                <div className="pedesc2-left">
                    <p>───── NEXT CASE STUDY</p>
                    <h1>WILKINSON PROPERTY SERVICE</h1>
                    <p>At Wilkinson Property Service, our Carpet Cleaning, we provide top-notch services to meet all your carpet cleaning needs. With years of experience, a team of skilled professionals, advanced cleaning equipment, and trusted cleaning solutions, we are the leading service provider in Melbourne.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Wilkinson_Property_Service">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div className="pedesc2-right">
                    <img className="pedesc2ri" src="./WilkinsonProject_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default BMdesc
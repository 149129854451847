import React from 'react';
import SBabout from '../SBabout';
import SBdesc from '../SBdesc';
import SBexp from '../SBexp';
import SBpro from '../SBpro';
import {Helmet} from "react-helmet";

function SnS_Baby_Care() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>SN'S Babycare Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's SN'S Babycare Project is your go-to destination for quality baby products. Shop online today and get your baby's essentials delivered straight to your doorstep!" />
            <meta name="keywords" content="SN'S Babycare, SN'S Babycare Project, One5's SN'S Babycare Project" />
        </Helmet>
            <SBabout />
            <SBexp />
            <SBpro />
            <SBdesc />
        </React.Fragment>
    )
}

export default SnS_Baby_Care

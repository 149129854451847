import React from 'react';
import './GBdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function GBdesc() {
    return (
        <div className="gbdesc">
            <div className="gbdesc-cat2">
                <div className="gbdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>NATURAL BY TARA</h1>
                    <p data-aos='fade-up'>Natural by Tara is a registered business that qualifies for the distribution of human care products around the globe. 
                        Australian made with Australian Ingredients. All Natural skincare designed to keep you glowing for the long term.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Natural_By_Tara">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="gbdesc2-right">
                <img className="gbdesc2ri" src="./NatureByTaraProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default GBdesc

import React from 'react';
import './LDpricing.css';
import { Button } from './Button';
import { FiCheckCircle } from "react-icons/fi";
import { Link } from 'react-router-dom';

function LDpricing() {
    return (
        <div className="ldprice">
            <div className="ld-price">
                <div className="ldf-desc">
                    <h1>OUR PRICING PLANS</h1>
                    <ul className="ldfp">
                        <li className="ldfli-col1">
                            <div className="ldf-col1">
                                <h4 className="ldfb">BASIC</h4>
                                <p><FiCheckCircle className="check"/> Instant Design Concept</p>
                                <p><FiCheckCircle className="check"/> Logo Transparency</p>
                                <p><FiCheckCircle className="check"/> Basic Design</p>
                                <p><FiCheckCircle className="check"/> Text Based Logo</p>
                                <p><FiCheckCircle className="check"/> One Revision</p>
                                <p><FiCheckCircle className="check"/> JPG File</p>
                                <div className='ldbasic-btn'>
                                    <Link excat to="/Contact_One5_Digital" style={{ textDecoration: 'none' }}>
                                        <Button className='btn' buttonStyle='btn--orange'>GET IN TOUCH</Button>
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li className="ldfli-col2"> 
                            <div className="ldf-col2">
                                <h4 className="ldfw">STANDRED</h4>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> 2 Custom Logo Concept</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> TYPO Text</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> Logo Transparency</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> Vector File</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> High Resolution</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> Source File</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> Two Revision</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> 100% Satisfaction</p>
                                <p className="ldfw"><FiCheckCircle className="checkw"/> 3D Mockup</p>
                                <div className='ldstandred-btn'>
                                    <Link excat to="/Contact_One5_Digital" style={{ textDecoration: 'none' }}>
                                        <Button className='btn' buttonStyle='btn--owhite' >GET IN TOUCH</Button>
                                    </Link>
                                </div>
                            </div>
                        </li>
                        <li className="ldfli-col3">
                            <div className="ldf-col3">
                                <h4 className="ldfb">PREMIUM</h4>
                                <p><FiCheckCircle className="check"/> 2 Custom Logo Concept</p>
                                <p><FiCheckCircle className="check"/> MASCOT + Design</p>
                                <p><FiCheckCircle className="check"/> Logo Transparency</p>
                                <p><FiCheckCircle className="check"/> Vector File</p>
                                <p><FiCheckCircle className="check"/> High Resolution</p>
                                <p><FiCheckCircle className="check"/> Source File</p>
                                <p><FiCheckCircle className="check"/> Unlimited Revision</p>
                                <p><FiCheckCircle className="check"/> Business Card Design</p>
                                <p><FiCheckCircle className="check"/> 100% Satisfaction</p>
                                <p><FiCheckCircle className="check"/> Social Media Kit</p>
                                <div className='ldpremium-btn'>
                                    <Link excat to="/Contact_One5_Digital" style={{ textDecoration: 'none' }}>
                                        <Button className='btn' buttonStyle='btn--orange'>GET IN TOUCH</Button>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
           </div>
        </div>
    )
}

export default LDpricing

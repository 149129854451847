import React from 'react';
import './Ecomservice.css';

function Ecomservice() {
    return (
        <div className="ecservice">
        <div>
            <h1>OUR ECOMMERCE SERVICES</h1>
        </div>
        <div className="ecservice-wrapper">
            <ul className="ecservice-1">
                <h1 className="ecno">01</h1>
                <h6>ECOMMERCE WEBSITE DESIGN </h6>
                <p className="ec-desc">Our Ecommerce services bring value to your business by delivering the right custom solution that your business demands.
                </p>
            </ul>
            <ul className="ecservice-2">
                <h1 className="ecno">02</h1>
                <h6>INTEGRATION, UPGRADE, AND OPTIMIZATION</h6>
                <p className="ec-desc">With our experience in delivering what the client needs, we can build your Ecommerce website with no hassle.</p>
            </ul>
            <ul className="ecservice-3">
                <h1 className="ecno">03</h1>
                <h6>WEBSITE PERFORMANCE ANALYSIS </h6>
                <p className="ec-desc">We make sure your website displays the most important content as quickly as possible and don’t suffer unexpected downtimes.</p>
            </ul>
            <ul className="ecservice-4">
                <h1 className="ecno">04</h1>
                <h6>ONGOING SUPPORT AND MAINTENANCE </h6>
                <p className="ec-desc">To keep your eCommerce website running smoothly we offer complete maintenance and on-demand support to grow your business.</p>
            </ul>
        </div>
        <div className="ecdesc">
            <div className="ecleft-desc">
                <h1>WE OFFER DIFFERENT 
                ECOMMERCE SOLUTIONS</h1>
            </div>
            <div className="ecright-desc">
                <p className="ecproject-desc">We provide top e-commerce websites using the best e-commerce platforms keeping in mind your requirements and target audience.</p>     
            </div>
        </div>
        <div className="ec-logos">
            <div className="eclogo1">
            <img className="eclogo" src="./wordpress_One5Digital.png" alt="Wordpress Logo" />
            <img className="eclogo" src="./shopify_One5Digital.png" alt="Shopify Logo" />
            <img className="eclogo" src="./shopifyplus_One5Digital.png" alt="Shopify Plus Logo" />
            <img className="eclogo" src="./magento_One5Digital.png" alt="Magento Logo" />
            <img className="eclogo" src="./woo_One5Digital.png" alt="Woo-Commerce Logo" />
            <img className="eclogo" src="./bigcom_One5Digital.png" alt="Big-Commerce Logo" />
            </div>
            <div className="eclogo2">
            <img className="eclogo" src="./volusion_One5Digital.png" alt="Volusion Logo" />
            <img className="eclogo" src="./sitecore_One5Digital.png" alt="Sitecore Logo" />
            <img className="eclogo" src="./squarespace_One5Digital.png" alt="Squarespace Logo" />
            <img className="eclogo" src="./wix_One5Digital.png" alt="Wix Logo" />
            <img className="eclogo" src="./bigcartel_One5Digital.png" alt="Bigcartel Logo" />
            <img className="eclogo" src="./oracle_One5Digital.png" alt="Oracle Logo" />
            </div>
        </div>
    </div> 
    )
}

export default Ecomservice

import React from 'react';
import './PEdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function PEdesc() {
    return (
        <div className="mgdesc">
            <div className="mgdesc-cat2">
                <div className="mgdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>MICHAEL GAZZOLA</h1>
                    <p>Michael Gazzola is an experienced professional photographer in his 20th year, based in the heart of Fitzroy. IMG.CO Pty Ltd is short for Image Company. The IMG part has been borrowed from the default file name used in many digital cameras [eg img001.jpg] and brought together with the formation of the company.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Michael_Gazzola">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="mgdesc2-right">
                <img className="mgdesc2ri" src="./MichaelGazzolaProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default PEdesc

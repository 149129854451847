import React from 'react';
import WDabout from '../WDabout';
import WDservice from '../WDservice';
import Getintouch from '../Getintouch';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';


function Website_Design_Melbourne() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Best Website Design Services Company in Melbourne | One5</title>
				<meta name="description" content="One5 is one of the leading best website design services company in Melbourne, Australia. We have been mastering the art of website design and development, creating stunning websites for businesses." />
				<meta name="keywords" content="Best Website Design, Website Design Service, Website Design Melbourne, Responsive Website Design, Website Design Company, Custom Website Design, Best Web Design Company in Australia, Best Website Designing Company in Australia, Web Design Company in Australia, Web Designing Company in Australia, Web Design Agency, Melbourne, Web Design Company Melbourne, Website Design Company Melbourne" />
			</Helmet>
            <WDabout />
            <WDservice />
            <FeaturedProject />
            <Testimonials />
            <Getintouch />
        </React.Fragment>
    )
}

export default Website_Design_Melbourne

import React from 'react';
import '../App.css';
import  HeroSection from '../HeroSection';
import OurProjects from '../OurProjects';
import OurServices from '../OurServices';
import AboutUs from '../AboutUs';
import RequestQuote from '../RequestQuote';
import {Helmet} from "react-helmet";

function Home() {  
    return (
		<React.Fragment> 
			<Helmet> 
				<title>Empowering Businesses with Innovative IT Solutions | One5 - Your Trusted IT Partner</title>
				<meta name="description" content="Discover cutting-edge IT services and solutions tailored to meet the unique needs of your business. We are a leading IT company in Melbourne, Australia dedicated to delivering top-notch technology solutions, from software development and cloud services to cybersecurity and IT consulting. Partner with us today and unlock the full potential of your business with our expertise and innovation." />
				<meta name="keywords" content="One5, IT Solutions, IT Agency, IT Company, Best IT Company in Melbourne, Best IT Company in Australia, Business Solutions, IT Services, IT Counsultant" />
			</Helmet>
			<HeroSection />
            <OurServices />
            <OurProjects />
            <AboutUs />
            <RequestQuote />
        </React.Fragment>
    )
}

export default Home;
import React from 'react';
import './WDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function WDesignDesc() {
    return (
        <div className="WDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our website design and services include:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>CREATIVE CONSULTATION</h6>
                            <p data-aos='fade-up' className="service-desc">We'll start with a consultation to learn more about your business, target audience, and overall design preferences.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>CUSTOM DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">Our experienced designers will create a custom website that reflects your brand identity and meets your specific business needs.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>RESPONSIVE DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">We ensure that your website is optimized for all devices, including desktops, laptops, tablets, and smartphones.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>CONTENT CREATION</h6>
                            <p data-aos='fade-up' className="service-desc">Our team of writers can create high-quality content for your website, including product descriptions, blog posts, and more.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>E-COMMERCE SOLUTIONS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create an e-commerce website that allows you to sell your products and services online, including payment processing and shipping integrations.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>SEARCH ENGINE OPTIMIZATION (SEO)</h6>
                            <p data-aos='fade-up' className="service-desc">We optimize your website for search engines to improve your visibility and increase traffic to your site.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>WEBSITE MAINTENANCE</h6>
                            <p data-aos='fade-up' className="service-desc">We offer ongoing website maintenance to ensure that your website is always up-to-date, secure, and running smoothly.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WDesignDesc
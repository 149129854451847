import React from 'react';
import './SMDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SMDesignAbout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>SOCIAL MEDIA ADVERTISING</h1>
                <h6>Welcome to One5 Digital's Social Media Advertising Service!</h6>
                <p>Social media has transformed how businesses reach their target audience. With a growing number of people spending more time on social media platforms, brands have realized the importance of using them to promote their products and services.</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>However, creating a robust online presence requires more than just posting updates and sharing content. To truly stand out on social media, businesses need to leverage the power of social media advertising. This is where One5 Digital comes in with its social media advertising services in Melbourne.</p>
                        <p className='smp'>One5 Digital is a leading digital marketing agency that provides comprehensive solutions for businesses looking to enhance their online presence. Their social media advertising services are tailored to meet each client's unique needs, helping them reach their target audience and achieve their business goals.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./social_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SMDesignAbout

import React from 'react';
import VPDesignAbout from '../VPDesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import VPDesign_contact from '../VPDesign_contact';
import VPDesignDesc from '../VPDesignDesc';
import FeaturedProject from '../FeaturedProject';
import VPDesignDesc2 from '../VPDesignDesc2';
import VPDesignDesc3 from '../VPDesignDesc3';

function VPS_Managed_Hosting() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>VPS Managed Hosting Services by One5 Digital Melbourne</title>
				<meta name="description" content="One5 Digital provides reliable and secure VPS Managed Hosting services in Melbourne, with 24/7 technical support and a range of customizable plans to suit your needs." />
				<meta name="keywords" content="VPS Managed Hosting, One5 Digital, Melbourne, reliable, secure, technical support, customizable plans." />
			</Helmet>
            <VPDesignAbout />
            <VPDesignDesc />
            <VPDesignDesc2 />
            <VPDesignDesc3 />
            <FeaturedProject />
            <Testimonials />
            <VPDesign_contact />
        </React.Fragment>
    )
}

export default VPS_Managed_Hosting
import React from 'react';
import VDesignAbout from '../VDesignAbout';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import VDesignDesc from '../VDesignDesc';
import VDesign_contact from '../VDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Visual_Design() {
  return (
    <React.Fragment>
      <Helmet>
        <title>One5 Digital's Visual Identity Design Services in Melbourne</title>
        <meta name="description" content="Discover One5 Digital's comprehensive visual identity design services in Melbourne, from logo creation to branding strategies, and enhance your business's image." />
        <meta name="keywords" content="visual identity design, logo creation, branding strategies, Melbourne, One5 Digital" />
      </Helmet>
      <VDesignAbout />
      <VDesignDesc />
      <FeaturedProject />
      <Testimonials />
      <VDesign_contact />
    </React.Fragment>
  )
}

export default Visual_Design
import React from 'react';
import './GPabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function GPabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>GOOGLE PPC</h1>
                <h6>Welcome to One5 Digital's Google PPC Service!</h6>
                {/* <p>In today's fast-paced digital world, having a strong online presence is crucial for the success of any business.</p> */}
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>Are you looking for a powerful way to increase your online visibility, attract more customers, and grow your business? Look no further than One5 Digital's Google PPC services in Melbourne.</p>
                        <p className='smp'>As a leading digital marketing agency, One5 Digital specializes in helping businesses of all sizes leverage the power of Google PPC (Pay-Per-Click) advertising to reach their target audience and achieve their marketing goals.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./gppc_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GPabout

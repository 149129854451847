import React from 'react';
import './SEODesignDesc3.css';

function SEODesignDesc3() {
    return (
        <div className="SEODesign-about2">
            <div data-aos='fade-up' className="sleft-side">
                <img data-aos='fade-up' className="designimg" src="./seo2_design_One5Digital.png" alt="Loading..." />
            </div>
            <div className='sright-side'>
                <p>Technical SEO involves optimizing the technical aspects of your website, including site speed, mobile responsiveness, and security. Our team ensures that your website is optimized for the latest search engine algorithms and follows best practices for website development.</p>
                <p>At One5 Digital, we understand the importance of transparency and communication in delivering effective SEO services. That's why we provide regular reports on your website's performance and progress, as well as ongoing support and consultation.</p>
                <p>In conclusion, if you want to improve your website's visibility on search engines and attract more qualified traffic, One5 Digital's SEO services in Melbourne are the solution you need. Contact us today to discuss your SEO needs and learn how we can help you achieve your online goals.</p>
            </div>
        </div>
    )
}

export default SEODesignDesc3
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './AAabout.css';
import { Link } from 'react-router-dom';

function AAabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <div className = 'aaabout'>
            <div className = 'aaabout-container'>
				<div className = 'aaaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>AMAZING AMAZON</h1>
                </div>
                <div className = 'aaaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'aaabout-desc'>Amazing Amazon - We pride ourselves on our expertise with Reptiles, Aquarium and Birds. We are a specialist shop that does not sell Dogs or Cats.
                    Amazing Amazon is the premier Reptile shop in Melbourne having over 400 square metres of reptiles, aquariums and accessories.
                    </p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2021</p>
                    <div className='aaabout-btn'>
                        <a href="https://www.amazingamazon.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="aaaboutcol-right">
                    <img className="aaimg" src= "./AmazingAmazonAbout_One5Digital.png" alt= "Loading..." />
                    <img className="aaimg2" src= "./AmazingAmazonAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default AAabout

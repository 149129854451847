import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import PHPabout from '../PHPabout';
import PHPDesc from '../PHPDesc';
import PHP_contact from '../PHP_contact';
import PHPabout2 from '../PHPabout2';

function PHP_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>One5 Digital: Offering Top-notch PHP Website Development Services in Melbourne</title>
        <meta name="description" content="Looking for professional PHP website development services in Melbourne? One5 Digital is your go-to solution. With a team of skilled developers, we provide high-quality PHP website development services tailored to your business needs." />
        <meta name="keywords" content="PHP website development, Melbourne, One5 Digital, professional services, skilled developers" />
      </Helmet>
      <PHPabout />
      <PHPabout2 />
      <PHPDesc />
      <FeaturedProject />
      <Testimonials />
      <PHP_contact />
    </React.Fragment>
  )
}

export default PHP_Website_Development
import React from 'react';
import './SDservices.css';

function SDservices() {
    return (
        <div className="sdservice">
        <div>
            <h1>OUR SHOPIFY SERVICES</h1>
        </div>
        <div className="sdservice-wrapper">
            <ul className="sdservice-1">
                {/* <h1 className="ecno">01</h1> */}
                <h6>Shopify Theme Tweaks & Changes </h6>
                <p className="ec-desc">Need changes to your shopify site? We can code anything into any shopify theme.
                </p>
            </ul>
            <ul className="sdservice-2">
                {/* <h1 className="ecno">02</h1> */}
                <h6>Complete Redesign or Store Facelift</h6>
                <p className="ec-desc">We can give your store the overhaul it needs.</p>
            </ul>
            <ul className="sdservice-3">
                {/* <h1 className="ecno">03</h1> */}
                <h6>Shopify SEO & Content Marketing</h6>
                <p className="ec-desc">Crush the competition in Google with our SEO packages.</p>
            </ul>
            <ul className="sdservice-4">
                {/* <h1 className="ecno">04</h1> */}
                <h6>Shopify Plus Support & Services</h6>
                <p className="ec-desc">A dedicated & experienced enterprice Shopify team.</p>
            </ul>
        </div>
        <div className="sdservice-wrapper">
            <ul className="sdservice-1">
                {/* <h1 className="ecno">01</h1> */}
                <h6>Monthly Shopify Support Retainers</h6>
                <p className="ec-desc">For those who need monthly support from us.</p>
            </ul>
            <ul className="sdservice-2">
                {/* <h1 className="ecno">02</h1> */}
                <h6>Custom Shopify Development</h6>
                <p className="ec-desc">Whatever your development needs we have the skills.</p>
            </ul>
            <ul className="sdservice-3">
                {/* <h1 className="ecno">03</h1> */}
                <h6>Migration to Shopify - Support & Planning</h6>
                <p className="ec-desc">We've migrated many sites including a listed company to Shopify.</p>
            </ul>
            <ul className="sdservice-4">
                {/* <h1 className="ecno">04</h1> */}
                <h6>Shopify Audits & Consultancy</h6>
                <p className="ec-desc">For those who need advice & strategy.</p>
            </ul>
        </div>
        <div className="sdservice-wrapper">
            <ul className="sdservice-1">
                {/* <h1 className="ecno">01</h1> */}
                <h6>Headless Solutions</h6>
                <p className="ec-desc">headless ecommerce is a separation of the front end and back end of an ecommerce application. This architecture offers you the freedom of expression to build whatever and however we want.
                </p>
            </ul>
            <ul className="sdservice-2">
                {/* <h1 className="ecno">02</h1> */}
                <h6>Paid Advertising & Data Feed</h6>
                <p className="ec-desc">Your groth is only limited by your marketing.</p>
            </ul>
            <ul className="sdservice-3">
                {/* <h1 className="ecno">03</h1> */}
                <h6>Custom Shopify Theme Development</h6>
                <p className="ec-desc">Custom designed Shopify themes are what we do best.</p>
            </ul>
            <ul className="sdservice-4">
                {/* <h1 className="ecno">04</h1> */}
                <h6>Logo & Branding</h6>
                <p className="ec-desc">Logo & branding for Shopify stores.</p>
            </ul>
        </div>
    </div> 
    )
}

export default SDservices
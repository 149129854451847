/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './TMabout.css';
import { Link } from 'react-router-dom';

function TMabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'tmabout'>
            <div className = 'tmabout-container'>
				<div className = 'tmaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." /> BACK TO PROJECTS</p></Link>
                    <h1>TRINITY MAINTENANCE <br/> GROUP</h1>
                </div>
                <div className = 'tmaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'tmabout-desc'>Trinity Maintenance Group pledge to provide accountable, knowledgeable cooling and heating service in Melbourne and surroundings, no matter the manufacturer. 
                    We always strive to be your one-stop, hassle-free solution to indoor air quality.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2022</p>
                    <div className='tmabout-btn'>
                        <a href="https://trinitymaintenance.net.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="tmaboutcol-right">
                    <img className="tmimg" src= "./TrinityAbout_One5Digital.png" alt= "Loading..." />
                    <img className="tmimg2" src= "./TrinityAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default TMabout
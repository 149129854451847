import React from 'react';
import WDesignAbout from '../WDesignAbout';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import WDesignDesc from '../WDesignDesc';
import WDesignDesc2 from '../WDesignDesc2';
import WDesign_contact from '../WDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Website_Design() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Professional Website Design Services | One5 Graphic Design Agency</title>
        <meta name="description" content="One5 Graphic Design Agency provides professional website design services in Australia. We create visually appealing and responsive websites that are optimized for user experience and search engines. Our team of experienced designers will work with you to create a website that meets your business needs and goals. Contact us today for a free consultation!" />
        <meta name="keywords" content="website design, web design, professional website design, responsive design, graphic design" />
      </Helmet>
      <WDesignAbout />
      <WDesignDesc />
      <WDesignDesc2 />
      <FeaturedProject />
      <Testimonials />
      <WDesign_contact />
    </React.Fragment>
  )
}

export default Website_Design
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './OurServices.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function OurServices() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return(
    <div className="our-services">
        <div>
            <p data-aos='fade-up' className = 'service-shortdesc' style={{ color: '#F04E31' }}>───── OUR SERVICES</p>
            <h1 data-aos='fade-up' >CREATIVE DESIGNS <br/> 
            THAT GENERATE <span style={{color: '#F04E31'}} >RESULTS </span></h1>
            <p data-aos='fade-up' className="services-desc">We offer highly innovative designs for the web and mobile. Right from user 
            interfaces to complete campaigns, our approach to design stems from a vision 
            for the perfect marriage of aesthetics and functionality.</p>
        </div>
        <div className="service-wrapper">
            <ul className="service-1">
                <h6 data-aos='fade-up'>WEB DESIGN</h6>
                <p data-aos='fade-up' className="service-desc">Our goal is to build you an amazing online web presence. Through 
                 intense eseaech we design a website to become a sales tool for your business.</p>
                 <div data-aos='fade-up' className='service-btn'>
                <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link>
                </div>
            </ul> 
            <ul className="service-2">
                <h6 data-aos='fade-up'>DIGITAL MARKETING</h6>
                <p data-aos='fade-up' className="service-desc">We offer a complete suite of marketing services to fully support clients from initial research 
                 and planning through execution and optimization.</p>
                 <div data-aos='fade-up' className='service-btn'>
                 <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link>
                </div>
            </ul>
            <ul className="service-3">
                <h6 data-aos='fade-up'>LOGO DESIGN</h6>
                <p data-aos='fade-up' className="service-desc">We craft meaningful brands through visual identity, print and digital experience 
                    for small to medium businesses.</p>
                 <div data-aos='fade-up' className='service-btn'>
                 <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link>
                </div>
            </ul>
            <ul className="service-4"> 
                <h6 data-aos='fade-up'>SOFTWARE DEVELOPMENT</h6>
                <p data-aos='fade-up' className="service-desc">We will help you to organise and manage all your sales, stock, accounting, shipping and 
                    customer data from single site. </p>
                 <div data-aos='fade-up' className='service-btn'>
                 <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link>
                </div>
            </ul>
        </div>
    </div>
    )
}

export default OurServices;
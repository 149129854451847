import React from 'react';
import PPabout from '../PPabout';
import PPdesc from '../PPdesc';
import PPexp from '../PPexp';
import PPpro from '../PPpro';
import {Helmet} from "react-helmet";

function PokeNPop_Collect() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Poke N Pop Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's is the world of collectibles with PokeNPop Collect! Unique items, rare finds, and more Start your collection today!" />
            <meta name="keywords" content="Poke N Pop, Poke N Pop Project, One5's Poke N Pop Project" />
        </Helmet>
            <PPabout />
            <PPexp />
            <PPpro />
            <PPdesc />
        </React.Fragment>
    )
}

export default PokeNPop_Collect

import React from 'react';
import './ABexp.css';

function ABexp() {
    return (
        <div className="abexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>The Artisans Bottega has website design that is simple and easy to use to the users. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="ab-video" autoPlay loop muted>
                    <source src="./Artisans_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Artisans_One5Digital.gif" alt= "Loading..." className="ab-video"  />
            </div>
        </div>
    )
}

export default ABexp

import React from 'react';
import './SWDabout2.css';

function SWDabout2() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./shopify2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>One5 Digital is a leading digital agency based in Melbourne that specializes in providing top-notch solutions for businesses across various industries. With their in-depth expertise and extensive experience, they have become the go-to destination for professional Shopify website development services in the region.</p>
            <p>At One5 Digital, their team of highly skilled and creative professionals understands the significance of a well-crafted online store in today's competitive market. They are dedicated to creating engaging and user-friendly e-commerce websites on the Shopify platform that drive conversions and boost your brand's online presence.</p>
        </div>
    </div>
  )
}

export default SWDabout2
import React from 'react';
import './CCexp.css';

function CCexp() {
    return (
        <div className="ccexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>At Cladcraft's the simple and elegant look, it has the design that shows all they work in short frame. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="cc-video" autoPlay loop muted>
                    <source src="./HardwareJournal_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Cladcraft_One5Digital.gif" alt= "Loading..." className="cc-video"  />
            </div>
        </div>
    )
}

export default CCexp

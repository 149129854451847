import React from 'react';
import './WOOabout2.css';

function WOOabout2() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./woo2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>Why Choose One5 Digital for WooCommerce Website Development?</p>
            <p>One5 Digital is a reputable digital agency in Melbourne known for its expertise in website development and design. They specialize in creating tailored solutions to meet the unique requirements of businesses across various industries. When it comes to WooCommerce, their team of skilled developers possesses extensive knowledge and experience in leveraging the power of this popular e-commerce platform.</p>
            <p>One5 Digital in Melbourne is your trusted partner for WooCommerce website development services. With their expertise in creating feature-rich, user-friendly, and SEO-optimized e-commerce platforms, they can help you establish a strong online presence and drive business growth. Don't miss out on the opportunity to enhance your online business. Contact One5 Digital today and take your e-commerce store to new heights.</p>
        </div>
    </div>
  )
}

export default WOOabout2
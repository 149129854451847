import React from 'react';
import './MCDesignAbout.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function MCDesignAbout() {
    return (
        <div className="MCDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>MALICIOUS CODE REMOVAL</h1>
                    <h6>One5 Digital Offers Malicious Code Removal Services in Melbourne</h6>
                    <p>If you own a website, one of the most important things you need to do is ensure its security. Malicious code or malware can infect your website and cause severe damage, including data theft, website downtime, and loss of credibility.</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MCDesignAbout
import React from 'react';
import './RNDesignDesc2.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function RNDesignDesc2() {
  return (
    <div className="RNDesign-desc">
        <div data-aos='fade-up' className="design-desc">
            <p>If the Red Notice website is hacked, it can have serious consequences. Not only can it compromise the safety of law enforcement officers, but it can also lead to the release of confidential information. This is where One5 Digital's Red Notice website hacked retrieval service comes in.</p>
            <p>One5 Digital's team of cybersecurity experts can help you retrieve your hacked Red Notice website quickly and efficiently. Their expertise in cybersecurity means they can identify the cause of the breach and implement measures to prevent it from happening again.</p>
        </div>
    </div>
  )
}

export default RNDesignDesc2
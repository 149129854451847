import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import SMDesignAbout from '../SMDesignAbout';
import SMDesign_contact from '../SMDesign_contact';
import SMDesignDesc from '../SMDesignDesc';

function Social_Media_Advertising() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Boost Your Brand's Reach with One5 Digital's Social Media Advertising Services in Melbourne</title>
        <meta name="description" content="Looking for effective social media advertising services to enhance your brand's online presence in Melbourne? One5 Digital has covered you with its comprehensive social media marketing solutions." />
        <meta name="keywords" content="social media advertising services, Melbourne, One5 Digital, online presence, social media marketing" />
      </Helmet>
      <SMDesignAbout />
      <SMDesignDesc />
      <FeaturedProject />
      <Testimonials />
      <SMDesign_contact />
    </React.Fragment>
  )
}

export default Social_Media_Advertising
import React from 'react';
import './EBexp.css';

function EBexp() {
    return ( 
        <div className="ebexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 3rem 0 3rem'}}>Essentially Brands' website design has the colorful pallets, so it looks more attractive to users and easy to use. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="eb-video" autoPlay loop muted>
                    <source src="./EssentiallyBrands_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./EssentiallyBrands_One5Digital.gif" alt= "Loading..." className="eb-video"  />
            </div>
            <div className="eb-cat">
                <div className="eb-left">
                    <h1 data-aos='fade-up'>CATALOGUE</h1>
                    <p data-aos='fade-up'>Armed with 50 years of experience they specialise in 
                    delivering top quality products for the Australian and 
                    export market.</p>
                </div>
                <div data-aos='fade-up' className="eb-right">
                    <img className="ebri" src="./Essentially_Category_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default EBexp

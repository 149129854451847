import React from 'react';
import './VVexp.css';

function VVexp() {
    return (
        <div className="vvexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 4rem 0 4rem'}}>At Venus Virgin's website, we've the different way to represent their categories of collections. We use image banner but in different style. Homepage has vibrant color pallets. All the colors makes homepage more attractive and alive.  Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="vv-video" autoPlay loop muted>
                    <source src="./VenusVirgin_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./VenusVirgin_One5Digital.gif" alt= "Loading..." className="vv-video"  />
            </div>
        </div>
    )
}

export default VVexp

import React from 'react';
import './Softwaredev.css';

function Softwaredev() {
    return (
        <div className="softwaredev">
            <div className="soft-desc">
                <div className="soft-left">
                    <h1 data-aos='fade-up' data-aos-duration="500">SOFTWARE DEVELOPMENT</h1>
                    <p data-aos='fade-up' data-aos-duration="1500">Organise and manage all your sales, stock, accounting, shipping and customer data from single site.</p> <br/>
                    <p data-aos='fade-up' data-aos-duration="2000">A custom software solution will provide the utmost flexibility for your business, as it can be tailored to almost every need you may have.</p> 
                </div>
            </div>
            <div className="soft-right">
            <img className="vector" src="./vector_One5Digital.svg" alt="Loading..." /> 
            </div>
        </div>
    ) 
}

export default Softwaredev

import React from 'react';
import './PSexp.css';

function PSexp() {
  return (
    <div className="psexp">
        <div>
            <h1>HOMEPAGE</h1>
            <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Explore our extensive selection of foods, toys, treats, and more, designed to provide your beloved pet with the best in nutrition and comfort.</p>
        </div>
        <div>
            {/* <video className="ps-video" autoPlay loop muted>
                <source src="./MotorComponent_One5Digital.mp4" type="video/mp4" />
            </video> */}
            <img src="./YourOnlinePetStore_One5Digital.gif" alt= "Loading..." className="ps-video"  />
        </div>
    </div>
  )
}

export default PSexp
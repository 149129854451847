import React from 'react';
import './DGdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function DGdesc() {
    return (
        <div className="dgdesc">
            <div className="dgdesc-cat2">
                <div className="dgdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>PAUL RYAN PLUMBING & CONTRACTING</h1>
                    <p data-aos='fade-up'>Paul Ryan Plumbing & Contracting P/L is a family owned and operated business 
                        that has been serving the Wyndham area and surrounding communities since 2003. </p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Paul_Ryan_Plumbing">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="dgdesc2-right">
                <img className="dgdesc2ri" src="./PaulRyanProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default DGdesc

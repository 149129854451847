import React from 'react';
import CPabout from '../CPabout';
import CPdesc from '../CPdesc';
import CPexp from '../CPexp';
import CPpro from '../CPpro';
import {Helmet} from "react-helmet";

function Clean_Power_Co() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Clean Power Co Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Get reliable and sustainable energy solutions with Clean Power Co project. We are committed to reducing your carbon footprint. Contact us today!" />
            <meta name="keywords" content="Clean Power Co, Clean Power Co Project, One5's Clean Power Co Project" />
        </Helmet>
            <CPabout />
            <CPexp />
            <CPpro />
            <CPdesc />
        </React.Fragment>
    )
}

export default Clean_Power_Co

import React from 'react';
import './SHWabout2.css';

function SHWabout2() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./html2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>What are Static HTML Websites?</p>
            <p>Static HTML websites are websites that consist of pre-built HTML files, which are served directly to the user's browser without any server-side processing. Unlike dynamic websites that rely on server-side scripting and database queries, static websites offer a lightweight and straightforward approach to web development. They are built using HTML, CSS, and JavaScript, enabling faster loading times, enhanced security, and excellent reliability.</p>
            <p>Harnessing the Power of Static Websites:</p>
            <p>One5 Digital understands the immense potential of static websites and leverages this technology to provide exceptional digital solutions.</p>
        </div>
    </div>
  )
}

export default SHWabout2
import React from 'react';
import './SHWDesignDesc2.css';

function SHWDesignDesc2() {
    return (
        <div className="SDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>One6 Digital's Static HTML Website Development Services</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>CUSTOM DESIGN & DEVELOPMENT </h6>
                            <p className="ec-desc">One5 Digital creates bespoke website designs tailored to the brand identity and objectives of the business. They develop clean, modern, and visually appealing websites that captivate the target audience.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>RESPONSIVE & MOBILE-FRIENDLY</h6>
                            <p className="ec-desc">Recognizing the significance of mobile devices in today's digital landscape, One5 Digital ensures that all static websites are fully responsive and optimized for seamless mobile experiences.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>SEO OPTIMIZATION</h6>
                            <p className="ec-desc">One5 Digital employs industry best practices to optimize static websites for search engines. They implement proper meta tags, schema markup, and other essential elements to enhance the website's visibility in search engine results.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>PERFORMANCE OPTIMIZATION</h6>
                            <p className="ec-desc">One5 Digital pays meticulous attention to website performance by optimizing code, compressing images, and leveraging caching techniques. This results in lightning-fast loading times and an exceptional user experience.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>ONGOING MAINTENANCE & SUPPORT</h6>
                            <p className="ec-desc">One5 Digital offers comprehensive maintenance and support services to ensure that static websites continue to perform at their best. They provide timely updates, security patches, and regular backups to safeguard.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SHWDesignDesc2
import React from 'react';
import './GPDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function GPDesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our social media advertising services include:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." /> */}
                            <h6>INCREASED ONLINE VISIBILITY</h6>
                            <p className="smd-desc">One5 Digital's Google PPC services ensure your brand appears prominently on relevant search results, significantly increasing your online visibility. By targeting specific keywords, demographics, and geographic locations, they help you reach potential customers precisely when they are searching for products or services like yours.</p>
                        </ul>
                        <ul className="smdservice-2">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h6>ENHANCED WEBSITE TRAFFIC</h6>
                            <p className="smd-desc">By optimizing your PPC campaigns, One5 Digital drives qualified traffic to your website. They employ strategic bidding techniques, compelling ad copy, and appealing landing pages to attract users who are more likely to convert into paying customers. This not only boosts website traffic but also improves your chances of generating valuable leads and sales.</p>
                        </ul>
                        <ul className="smdservice-3">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h6>CUSTOMIZED CAMPAIGNS</h6>
                            <p className="smd-desc">One5 Digital understands that each business has unique goals and target audiences. Their team of experienced professionals creates tailored PPC campaigns that align with your specific business objectives. They conduct thorough keyword research, analyze competition, and optimize ad targeting to ensure your campaigns deliver the best possible results.</p>
                        </ul>
                        <ul className="smdservice-4">
                            {/* <img src="./server_One5Digital.png" alt="Loading..." /> */}
                            <h6>MAXIMIZING ROI</h6>
                            <p className="smd-desc">With One5 Digital's Google PPC services, you can make the most of your advertising budget. Their strategic approach to PPC advertising ensures that you get the highest return on investment (ROI) by focusing on the most effective keywords, demographics, and ad placements. By continuously optimizing your campaigns, they minimize wasteful spending and maximize conversions.</p>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className='smdesc'>
                <p className='smp'>By leveraging One5 Digital's social media advertising services, businesses can increase their brand's reach and engagement on social media, ultimately driving more traffic and sales to their website.</p>
                <p className='smp'>In conclusion, social media advertising has become essential to any successful digital marketing strategy. With One5 Digital's social media advertising services in Melbourne, businesses can create an effective social media marketing plan that helps them achieve their business objectives. So why wait? Contact One5 Digital today to boost your brand's online presence and reach your target audience on social media!</p>
            </div> */}
        </div>
    )
}

export default GPDesc
import React from 'react';
import './TMexp.css';

function TMexp() {
    return (
        <div className="tmexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>At Trinity Maintenance Group's site we've the image hover banners, expertise section, and another sections like services, details of locations of company, contact form available. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="tm-video" autoPlay loop muted>
                    <source src="./HardwareJournal_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Trinity_One5Digital.gif" alt= "Loading..." className="tm-video"  />
            </div>
        </div>
    )
}

export default TMexp

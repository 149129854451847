/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './CRabout.css';
import { Link } from 'react-router-dom';

function CRabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'crabout'>
            <div className = 'crabout-container'>
				<div className = 'craboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>CAMPUS RADAR</h1>
                </div>
                <div className = 'craboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'crabout-desc'>To provide a quality one-stop higher education jobs board that specifically engages the whole spectrum of campus workers, enables cross-fertilisation of talent across all campus types, provides recruitment tools for employers to create comprehensive ads,
                    showcases graduate students' research, and opens up new opportunities for higher education job seekers.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Custom CMS</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2022</p>
                    <div className='crabout-btn'>
                        <a href="https://campusradarjobs.com/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="craboutcol-right">
                    <img className="mgimg" src= "./CampusRadarAbout_One5Digital.png" alt= "Loading..." />
                    <img className="mgimg2" src= "./CampusRadarAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
} 

export default CRabout

import React from 'react';
import './Sitemappage.css';
import { Link } from 'react-router-dom';

function sitemappage() {
    return (
        <div className="sitemap-page">
            <div className="sitemap-cat">
                <h1 data-aos='fade-up' data-aos-duration="500" >SITEMAP</h1>
                <div data-aos='fade-up' data-aos-duration="1500" className="mainpages">
                    <div className="line"></div>
                    <div className="title">
                        <h2>PAGES</h2>
                    </div>
                    <div className="line"></div>
                    <div className="site-data">
                        <Link exact to="/">
                            <p className="data">Home</p>   
                        </Link>
                        <Link exact to="/about-us">
                            <p className="data">About One5 Digital</p>   
                        </Link>
                        <Link exact to="/projects">
                            <p className="data">Projects</p>   
                        </Link>
                        <Link exact to="/services">
                            <p className="data">Services</p>   
                        </Link>
                        <Link exact to="/software-design">
                            <p className="data">Software Design</p>   
                        </Link>
                        <Link exact to="/shopify-website-design">
                            <p className="data">Shopify Website Design</p>   
                        </Link>
                        <Link exact to="/shopify-projects">
                            <p className="data">Shopify Projects</p>   
                        </Link>
                        <Link exact to="/testimonials">
                            <p className="data">Testimonials</p>   
                        </Link>
                        <Link exact to="/contact-us">
                            <p className="data">Contact One5 Digital</p>   
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="2000" className="servicepages">
                    <div className="line"></div>
                    <div className="title">
                        <h2>SERVICES</h2>
                    </div>
                    <div className="line"></div>
                    <div className="site-data">
                        <Link exact to="/services/logo-design">
                            <p className="data">Logo Design</p>   
                        </Link>
                        <Link exact to="/services/website-design">
                            <p className="data">Website Design</p>   
                        </Link>
                        <Link exact to="/services/ecommerce-website-design">
                            <p className="data">E-Commercr Website</p>   
                        </Link>
                        <Link exact to="/services/seo-company">
                            <p className="data">Search Engine Optimization (SEO)</p>   
                        </Link>
                        <Link exact to="/services/pay-per-click">
                            <p className="data">Pay Per Click (PPC)</p>   
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="2500" className="projectspages">
                    <div className="line"></div>
                    <div className="title">
                        <h2>PROJECTS</h2>
                    </div>
                    <div className="line"></div>
                    <div className="site-data siteprojects">
                        <Link exact to="/projects/accord-retail">
                            <p className="data">Accord Retail </p>   
                        </Link>
                        <Link exact to="/projects/amazing-amazon">
                            <p className="data">Amazing Amazon</p>   
                        </Link>
                        <Link exact to="/projects/ausport-superstore">
                            <p className="data">Ausport Superstore</p>   
                        </Link>
                        <Link exact to="/projects/axlefix">
                            <p className="data">Axlefix</p>   
                        </Link>
                        <Link exact to="/projects/bear-dog-walking-service">
                            <p className="data">Bear Dog Walking Service</p>   
                        </Link>
                        <Link exact to="/projects/campus-radar">
                            <p className="data">Campus Radar</p>   
                        </Link>
                        <Link exact to="/projects/cladcraft">
                            <p className="data">CladCraft</p>   
                        </Link>
                        <Link exact to="/projects/clean-power-co">
                            <p className="data">Clean Power Co.</p>   
                        </Link>
                        <Link exact to="/projects/dr-green-cosmetic-group">
                            <p className="data">Dr Green Cosmetic Group</p>   
                        </Link>
                        <Link exact to="/projects/dr-green-shop">
                            <p className="data">Dr Green Shop</p>   
                        </Link>
                        <Link exact to="/projects/essentially-brands">
                            <p className="data">Essentially Brands</p>   
                        </Link>
                        <Link exact to="/projects/the-australian-hardware-journal">
                            <p className="data">Hardware Journal</p>   
                        </Link>
                        <Link exact to="/projects/langys">
                            <p className="data">Langys</p>   
                        </Link>
                        <Link exact to="/projects/michael-gazzola">
                            <p className="data">Michael Gazzola</p>   
                        </Link>
                        <Link exact to="/projects/miss-australia-tiffany-shen">
                            <p className="data">Miss Tiffany Shen</p>   
                        </Link>
                        <Link exact to="/projects/motor-components">
                            <p className="data">Motor Components</p>   
                        </Link>
                        <Link exact to="/projects/natural-by-tara">
                            <p className="data">Natural By Tara</p>   
                        </Link>
                        <Link exact to="/projects/paul-ryan-plumbing">
                            <p className="data">Paul Ryan Plumbing & Contracting</p>   
                        </Link>
                        <Link exact to="/projects/pokenpop-collect">
                            <p className="data">Poke N Pop Collect</p>   
                        </Link>
                        <Link exact to="/projects/power-equipment-australasia">
                            <p className="data">Power Power Equipment Australasia</p>   
                        </Link>
                        <Link exact to="/projects/samantha-j-willis">
                            <p className="data">Samantha J Willis</p>   
                        </Link>
                        <Link exact to="/projects/sns-baby-care">
                            <p className="data">Sn'S Babycare</p>   
                        </Link>
                        <Link exact to="/projects/staged-homes-by-melissa">
                            <p className="data">Staged Homes by Melissa</p>   
                        </Link>
                        <Link exact to="/projects/super-star-boards">
                            <p className="data">Super Star Boards</p>   
                        </Link>
                        <Link exact to="/projects/the-artisans-bottega">
                            <p className="data">The Artisans Bottega</p>   
                        </Link>
                        <Link exact to="/projects/the-grand-band">
                            <p className="data">The Grand Band</p>   
                        </Link>
                        <Link exact to="/projects/trinity-maintenance-group">
                            <p className="data">Trinity Maintenance Group</p>   
                        </Link>
                        <Link exact to="/projects/venus-virgin-tomarz">
                            <p className="data">Venus Virgin Tomarz</p>   
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="3000" className="servicepages">
                    <div className="line"></div>
                    <div className="title">
                        <h2>COMPANY INFO</h2>
                    </div>
                    <div className="line"></div>
                    <div className="site-data">
                        <Link exact to="/TermsandConditions">
                            <p className="data">Terms & Conditions</p>   
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    ) 
}

export default sitemappage

import React from 'react';
import './SLDesignDesc2.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function SLDesignDesc2() {
  return (
    <div className="SLDesign-desc">
        <div data-aos='fade-up' className="design-desc">
            <p>Our SSL Certification installation services also include ongoing maintenance and support to ensure that your SSL Certificate is always up to date and functioning correctly. We understand the importance of keeping your website secure and will work with you to ensure that your website is always protected.</p>
        </div>
    </div>
  )
}

export default SLDesignDesc2
import React from 'react';
import './PSdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function PSdesc() {
    return (
        <div className="pedesc">
            <div className="pedesc-cat2">
                <div className="pedesc2-left">
                    <p>───── NEXT CASE STUDY</p>
                    <h1>CAMPUS RADAR</h1>
                    <p>Campus Radar has been developed by academics and search consultants with decades of higher education experience. It's a one-stop-site for employers to showcase their opportunities, and for job seekers to explore the next step in their careers. Take advantage of multiple recruitment tools...all in one place.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Campus_Radar">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div className="pedesc2-right">
                    <img className="pedesc2ri" src="./CampusRadarProject_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default PSdesc
import React from 'react';
import './BrandingDesc2.css';

function BrandingDesc2() {
    return (
        <div className="Branding-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Branding Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>PERSONALIZED APPROACH </h6>
                            <p className="ec-desc">We take a personalized approach to each branding project, ensuring that your branding accurately reflects your business values, personality, and unique selling proposition.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>PROFESSIONAL BRANDING EXPERTS</h6>
                            <p className="ec-desc">Our branding experts have years of experience in creating branding strategies that help businesses stand out in their respective industries.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>AFFORDABLE PRICING </h6>
                            <p className="ec-desc">We offer affordable pricing for our branding services, so you can establish a strong brand identity without breaking the bank.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>FAST TURNAROUND </h6>
                            <p className="ec-desc">We aim to deliver your branding project within 5-7 business days, so you can start using your new branding materials as soon as possible.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>SATISFACTION GUARANTEED </h6>
                            <p className="ec-desc">We want you to be completely satisfied with your branding project, which is why we offer unlimited revisions until you're happy with the final product.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandingDesc2
import React from 'react';
import LSabout from '../LSabout';
import LSdesc from '../LSdesc';
import LSexp from '../LSexp';
import LSpro from '../LSpro';
import {Helmet} from "react-helmet";

function Langys() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Langys Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Langys project increased their online presence and quality electrical, plumbing, and air conditioning services." />
            <meta name="keywords" content="Langys, Langys project, One5's Langys project" />
        </Helmet>
            <LSabout />
            <LSexp />
            <LSpro />
            <LSdesc />
        </React.Fragment>
    )
}

export default Langys
import React from 'react';
import BrandingAbout from '../BrandingAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import BrandingDesc from '../BrandingDesc';
import BrandingDesc2 from '../BrandingDesc2';
import Branding_contact from '../Branding_contact';
import FeaturedProject from '../FeaturedProject';

function Branding() {
  return (
    <React.Fragment> 
        <Helmet> 
            <title>Professional Branding Services | One5 Graphic Design Agency</title>
            <meta name="description" content="One5 Graphic Design Agency offers professional branding services in Australia. Our team of experienced designers can help you establish a strong brand identity, develop a brand strategy, and design a logo and visual identity that effectively communicates your message to your audience. We work with businesses of all sizes to create unique and memorable brand identities that help them stand out in a crowded marketplace. Contact us today for a free consultation and take the first step towards building a strong brand for your business." />
            <meta name="keywords" content="branding, brand identity, brand strategy, logo design, visual identity" />
        </Helmet>
        <BrandingAbout />
        <BrandingDesc />
        <BrandingDesc2 />
        <FeaturedProject />
        <Testimonials />
        <Branding_contact />
    </React.Fragment>
  )
}

export default Branding
import React from 'react';
import './SLDesignAbout.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SLDesignAbout() {
    return (
        <div className="SLDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>SSL CERTIFICATION INSTALLATION</h1>
                    <h6>One5 Digital Offers SSL certification installation Services in Melbourne</h6>
                    <p>In today's digital age, website security is more important than ever. One5 Digital Melbourne understands the importance of securing your website from potential threats and offers SSL Certification installation services to help protect your website from online attacks.</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SLDesignAbout
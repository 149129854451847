import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import WOOabout from '../WOOabout';
import WOODesc from '../WOODesc';
import WOO_contact from '../WOO_contact';
import WOOabout2 from '../WOOabout2';

function Woo_Commerce_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Boost Your Online Business with One5 Digital's WooCommerce Website Development Services in Melbourne</title>
        <meta name="description" content="Looking to establish a robust online presence for your business? One5 Digital, based in Melbourne, offers top-notch WooCommerce website development services. Discover how our expert team can create a feature-rich and user-friendly e-commerce platform that drives sales and enhances customer experience." />
        <meta name="keywords" content="WooCommerce website development, Melbourne, e-commerce platform, online business, One5 Digital" />
      </Helmet>
      <WOOabout />
      <WOOabout2 />
      <WOODesc />
      <FeaturedProject />
      <Testimonials />
      <WOO_contact />
    </React.Fragment>
  )
}

export default Woo_Commerce_Website_Development
import React from 'react';
import './AUdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function AUdesc() {
    return (
        <div className="audesc">
            <div className="audesc-cat2">
                <div className="audesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>VENUS VIRGIN TOMARZ</h1>
                    <p>My world offers to make yours fun and stylish in a sparkling new way.
                        I’ve become a whole new person to accommodate style adventurers just like you.
                        We can all be part of my parallel universe. You can have some of that added value.
                        I’ll propel you across the solar winds of modernity.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Venus_Virgin_Tomarz">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="audesc2-right">
                <img className="audesc2ri" src="./VenusVirginProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default AUdesc

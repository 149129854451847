/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Testimonials.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { slideInRight } from 'react-animations';
 
function Testimonials() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    const quotes = {
        0: {
            quote: "I love working with Prince, he is always so helpful with any questions I have. I am very happy with the service he has provided, and does it all in a kind nature, uses his initiative and gives genuine advice.",
            client: "Tara Grimes"
        },
        1: {
            quote: "Prince created a website for my author client, and we were extremely impressed with his professionalism and the website he created.  Thank you Prince.",
            client: "Kerry McDuling"
        },
        2: {
            quote: "Wonderful to work with Prince. Extremely reliable with a creative flare, able to interpret ideas and excellent customer service. Will definitely  be using One5 Digital again.",
            client: "Hardware Journal"
        },
        3: {
            quote: "He is a good bloke! He knows what he is doing. We're ranking on first page on Google now. Highly recommend Prince - One5 Digital",
            client: "Jeff Cain"
        }
    }

    const [current, setCurrent] = useState(quotes[0])
    const [active, setActive] = useState(0)
    const handleSetClick = (event) => {
        setCurrent(quotes[event.target.getAttribute("data-quote")])
        setActive(event.target.getAttribute("data-quote"))
    }

    return (
        <div className="testimonials">
            <h1 data-aos='fade-up'>what Our clients say</h1>
            <p data-aos='fade-up'>{current.quote}</p>
            <p data-aos='fade-up' style={{ color: '#F04E31', marginBottom: '0.5rem' }}>──────────</p>
            <p data-aos='fade-up'>{current.client}</p> 
            <div data-aos='fade-up' className="test">
                {Object.keys(quotes).map(index => (
                    <span className="test-span" 
                        onClick={event => handleSetClick(event)}
                        data-quote={index}
                        key={index}
                    />
                ))}    
            </div> 
            <Link excat to="/Testimonials" data-aos='fade-up'>
                <Button className='btn' buttonStyle='btn--primary' >Testimonials</Button>
            </Link>          
        </div>
    )
}

slideInRight.defaultProps = {
    slides: [],
    autoPlay: null
}
 
export default Testimonials

import React from 'react';
import './RNDesignDesc.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function RNDesignDesc() {
  return (
    <div className="RNDesign-about">
        <div data-aos='fade-up' className="left-side">
            <p>One5 Digital is a leading cybersecurity company in Melbourne that offers a range of services designed to protect your online assets. One of the most popular services offered by One5 Digital is Red Notice website hack retrieval.</p>
            <p>Red Notice is an international alert system designed to help law enforcement agencies locate and arrest individuals wanted for extradition. The website acts as a platform for law enforcement agencies to share information and collaborate on cases. However, the website is also a target for cybercriminals looking to steal sensitive information or cause disruption.</p>
        </div>
        <div className='right-side' style={{textAlign: 'center'}}>
            <img data-aos='fade-up' className="designimg" src="./website_hacked_desc_One5Digital.png" alt="Loading..." />
        </div>
    </div>
  )
}

export default RNDesignDesc
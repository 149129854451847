import React from 'react';
import '../App';
import ContactPage from '../ContactPage';
import {Helmet} from "react-helmet";

function Contact_One5_Digital() {
    return (
            <React.Fragment> 
				<Helmet> 
					<title>Contact One5 - IT solution Agency in Melbourne</title>
					<meta name="description" content="Contact One5 Digital to discover how our range of IT solutions and digital marketing services can help your business grow and succeed online." />
					<meta name="keywords" content="Contact One5" />
				</Helmet>
                <ContactPage />
            </React.Fragment>
    )
}

export default Contact_One5_Digital;

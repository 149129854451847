import React from 'react';
import MGabout from '../MGabout';
import MGdesc from '../MGdesc';
import MGexp from '../MGexp';
import MGpro from '../MGpro';
import {Helmet} from "react-helmet";

function Michael_Gazzola() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Michael Gazzola Group Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Discover Michael Gazzola's project amazing artworks and explore his unique style. Buy original paintings and prints online." />
            <meta name="keywords" content="Michael Gazzola Group, Michael Gazzola Group Project, One5's Michael Gazzola Group Project" />
        </Helmet>
            <MGabout />
            <MGexp />
            <MGpro />
            <MGdesc />
        </React.Fragment>
    )
}

export default Michael_Gazzola
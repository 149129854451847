import React from 'react';
import VVabout from '../VVabout';
import VVdesc from '../VVdesc';
import VVexp from '../VVexp';
import VVpro from '../VVpro';
import {Helmet} from "react-helmet";

function Venus_Virgin_Tomarz() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Venus Virgin Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Revolutionize your intimate care with Venus Virgin Tomarz project. Natural, organic, and vegan products for every woman's health and wellness." />
            <meta name="keywords" content="Venus Virgin, Venus Virgin Project, One5's Venus Virgin Project" />
        </Helmet>
            <VVabout />
            <VVexp />
            <VVpro />
            <VVdesc />
        </React.Fragment>
    )
}

export default Venus_Virgin_Tomarz

import React from 'react';
import './HJdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function HJdesc() {
    return (
        <div className="hjdesc">
            <div className="hjdesc-cat2">
                <div className="hjdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>POWER EQUIPMENT AUSTRALASIA</h1>
                    <p>Power Equipment was established on October 1st, 1990 and is the exclusive authorised Australian, New Zealand, PNG and the South Pacific Distributor of Yanmar Marine and Industrial 
                        diesel engines importing product from Yanmar plants all around the world. </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Power_Equipment_Australasia">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="hjdesc2-right">
                <img className="hjdesc2ri" src="./PowerEquipProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default HJdesc

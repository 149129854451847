import React from 'react';
import HJabout from '../HJabout';
import HJdesc from '../HJdesc';
import HJexp from '../HJexp';
import HJpro from '../HJpro';

function The_Australian_Hardware_Journal() {
    return (
        <React.Fragment> 
            <HJabout />
            <HJexp />
            <HJpro />
            <HJdesc />
        </React.Fragment>
    )
}

export default The_Australian_Hardware_Journal

import React from 'react';
import ABabout from '../ABabout';
import ABdesc from '../ABdesc';
import ABexp from '../ABexp';
import ABpro from '../ABpro';
import {Helmet} from "react-helmet";

function The_Artisans_Bottega() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>The Artisans Bottega Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's is a shop for handcrafted, unique artisanal products at The Artisans Bottega project. Discover one-of-a-kind items made by talented artisans." />
            <meta name="keywords" content="The Artisans Bottega, The Artisans Bottega Project, One5's The Artisans Bottega Project" />
        </Helmet>
            <ABabout />
            <ABexp />
            <ABpro />
            <ABdesc />
        </React.Fragment>
    )
}

export default The_Artisans_Bottega

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './HJabout.css';
import { Link } from 'react-router-dom';

function HJabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'hjabout'>
            <div className = 'hjabout-container'>
				<div className = 'hjaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." /> BACK TO PROJECTS</p></Link>
                    <h1>HARDWARE JOURNAL</h1>
                </div>
                <div className = 'hjaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'hjabout-desc'>The Australian Hardware Journal is the gateway to the hardware industry in Australia. The magazine has
                         been published since 1886 and is well positioned to deliver the latest news, views and stories on Australian hardware.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='hjabout-btn'>
                        <a href="https://hardwarejournal.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="hjaboutcol-right">
                    <img className="hjimg" src= "./HardwareJournalAbout_One5Digital.png" alt= "Loading..." />
                    <img className="hjimg2" src= "./HardwareJournalAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default HJabout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './BMabout.css';
import { Link } from 'react-router-dom';

function BMabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'bmabout'>
            <div className = 'bmabout-container'>
				<div className = 'bmaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>BEAT THE REST MOVERS</h1>
                </div>
                <div className = 'bmaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'bmabout-desc'>Moving furniture can be a daunting task, especially if you’re not experienced in doing so. In addition, it can be challenging to move heavy and bulky items without causing any damage or injury. That’s where Beat The Rest Movers comes in – we are your trusted furniture removalist service in Melbourne.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2023</p>
                    <div className='bmabout-btn'>
                        <a href="https://beattherestmovers.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="bmaboutcol-right">
                    <img className="bmimg" src= "./BTRMAbout_One5Digital.png" alt= "Loading..." />
                    <img className="bmimg2" src= "./BTRMAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default BMabout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './EBabout.css';
import { Link } from 'react-router-dom';

function EBabout() {
    
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'ebabout'>
            <div className = 'ebabout-container'>
				<div className = 'ebaboutcol-full'>
                    <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">ESSENTIALLY BRANDS</h1>
                </div>
                <div className = 'ebaboutcol-left'>
                    <h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'ebabout-desc'>Essentially Brands is an Australian owned and operated family
                    business. Armed with 50 years of experience they specialise in
                    delivering top quality products for the Australian and export market.</p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2020</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='ebabout-btn'>
                        <a href="https://essentiallybrands.com/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="ebaboutcol-right">
                    <img className="ebimg" src= "./EssentiallyBrandsAbout_One5Digital.png" alt= "Loading..." />
                    <img className="ebimg2" src= "./EssentiallyBrandsCrop_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default EBabout

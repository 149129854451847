import React from 'react';
import './TnC.css';

function TnC() { 
    return (
        <div className="tnc">
            <div className="tnc-card">
                <h1 data-aos='fade-up' data-aos-duration="1500">TERMS & CONDITIONS</h1>
                <div data-aos='fade-up' data-aos-duration="1500" className="terms scrollbar-warning">
                    <p>Please read below important information about working with One5 Digital (OK PATEL TRUST).</p>
                    <h6>GENERAL</h6>
                    <p>By making your first payment to One5 Digital (OK PATEL TRUST) you are confirming that you have read, and are in agreement with,<br/> the terms and conditions outlined below.</p>
                    <br/>
                    <h6>DEFINITIONS</h6>
                    <p>One5 Digital (OK PATEL TRUST), We: One5 Digital (OK PATEL TRUST) Client, You, Your: The person or entity requesting the services of<br/> One5 Digital (OK PATEL TRUST) Pty Ltd. Website: This term is used for all electronic media design. Including, but not limited to, micro <br/>sites, virtual tours, interactive maps, e-books, e-brochures, Content Management Systems. Project: Refers to anything that is created<br/> by One5 Digital (OK PATEL TRUST). Including, but not limited to, graphic design, website design, photography, video and programming.</p>
                    <br/>
                    <h6>COMMUNICATION, REQUESTS, APPROVALS AND SIGN-OFF</h6>
                    <p>One5 Digital (OK PATEL TRUST) accepts emails as a legally binding form of communication. All project requests; approvals and final<br/> sign-off should be made via email even if verbal confirmation was given.</p>
                    <br/>
                    <h6>QUOTATION</h6>
                    <ul>
                        <li>1. Before a project quotation can be given, One5 Digital (OK PATEL TRUST) requires a full project brief to be supplied by the client. A PDF document <br/>entitled One5 Digital (OK PATEL TRUST) Design Brief Guide will be supplied to help you with creating your project brief.</li>
                        <li>Quotes are valid for 30 days from the date of issue.</li>
                        <li>3. If the quote is not accepted via an email confirmation within the 30 days, and project deposit not paid (see Payment Terms), then a new quote <br/>must be obtained. Fees may change due to material costs or One5 Digital (OK PATEL TRUST) fee alterations.</li>
                        <li>4. Should the client make any variations or additions to the design brief after receiving the quotation, or during project development, then a new <br/>quote must be obtained.</li>
                        <li>5. All prices quoted are estimates only. Quotations are based upon projected working hours and costs of materials at the time of quotation and <br/>are subject to amendment to meet any rise or fall in such rates/costs.</li>
                    </ul>
                    <br/>
                    <h6>PAYMENT TERMS</h6>
                    <ul>
                        <li>1. The full project cost above $10,000 is split into three payments of 50%, 25%, 25%. Else 50%, 50%</li>
                        <li>2. 50% deposit is required, plus all content and materials (see Supply of Content), before any work commences on the clients project.</li>
                        <li>3. 25% milestone payment is required at concept acceptance. Work will continue on the project after payment has been received. For projects<br/> where concept work is not necessary One5 Digital (OK PATEL TRUST) will determine the milestone date.</li>
                        <li>4. 25% final payment is due before receipt of finished artwork or website going live. Proofs or full working demonstrations will be shown for client’s<br/> final approval at this stage. Client must officially sign-off on finished artwork via email.</li>
                        <li>5. No finished artwork will be supplied, nor will a website go live, until final payment is made.</li>
                        <li>6. The client agrees to make payments within 7 days or 14 days respective of the invoice.</li>
                        <li>7. One5 Digital (OK PATEL TRUST) would like to note that it prioritises early paying clients. Also, One5 Digital (OK PATEL TRUST) reserves the right to <br/>cease production of a project for clients who have not paid. Said project may also be placed at the bottom of the list of priorities.</li>
                        <li>8. An urgency fee (double the current hourly rate) will be charged to any client requesting work within a time frame that One5 Digital (OK PATEL <br/>TRUST) regards as short notice.</li>
                        <li>9. One5 Digital (OK PATEL TRUST) also reserves the right to suspend services or remove a website from being live should payments be overdue. <br/>In such an event, the client is still liable for the costs to date.</li>
                        <li>10. Should the client wish to cancel a project or service at any stage then the client is liable for the cost of any work completed to date.<br/> Cancellations should be made via email even if verbal confirmation was given.</li>
                        <li>11. Following consistent non payment of an invoice One5 Digital (OK PATEL TRUST) will seek legal action.</li>
                    </ul>
                    <br/>
                    <h6>SUPPLY OF CONTENT</h6>
                    <ul>
                        <li>1. If your company has a Style Guide, regardless of how simple or complex it may be, then it is imperative that we receive this information at the <br/>beginning of the project in order to help us maintain the continuity of YOUR brand. If this information (or similar) is supplied after work has begun <br/>then penalty fees may apply for any work needing to be re-created or edited.</li>
                        <li>2. All written content MUST be supplied in electronic format (Word document, email, or similar). You will be solely responsible for the content of<br/> your project. One5 Digital (OK PATEL TRUST) is not responsible for proofreading any content.</li>
                        <li>3. All supporting visual material such as logos and images should be emailed to One5 Digital (OK PATEL TRUST) if possible. If file sizes are too large <br/>then we can accept a CD or DVD delivered to our address. (see company address 21 Eagleridge Prom, Tarneit).</li>
                    </ul>
                    <br/>
                    <h6>CONTENT LIABILITY</h6>
                    <ul>
                        <li>1. The client is solely responsible for the integrity of the written and/or visual content of any project that One5 Digital (OK PATEL TRUST) is requested <br/>to create. The client will obtain all the necessary permissions and authorities in respect of the use of all copy, graphics, registered company logos, <br/>names and trademarks or any other material supplied by the client. Supply of aforementioned material by the client shall be regarded as a <br/>guarantee by the client to One5 Digital (OK PATEL TRUST) that all such permissions and authorities have been obtained.</li>
                        <li>2. Clients are required to ensure that all content meets the current Australian legislation regarding publications. </li>
                        <li>3. The client shall indemnify One5 Digital (OK PATEL TRUST) in respect of any claims, costs and expenses that may arise from any material included <br/>in their project by One5 Digital (OK PATEL TRUST) at the client’s request. </li>
                        <li>4. Any project content you supply to One5 Digital (OK PATEL TRUST) to be used in any form, you hereby warrant that these do not infringe the rights<br/>of third parties and indemnify One5 Digital (OK PATEL TRUST) against any action taken against One5 Digital (OK PATEL TRUST) by any such third<br/> party.</li>
                        <li>5. One5 Digital (OK PATEL TRUST) reserves the right not to include any material supplied by the client if One5 Digital (OK PATEL TRUST) deems it <br/>inappropriate or offensive.</li>
                    </ul>
                    <br/>
                    <h6>PROJECT TIMELINES/DEADLINES</h6>
                    <ul>
                        <li>1. One5 Digital (OK PATEL TRUST) endeavours to respect all project timelines discussed with the client. However, all timelines provided by One5<br/> Digital (OK PATEL TRUST) are estimated on a pre-determined length of time that One5 Digital (OK PATEL TRUST) considers realistic for the<br/> completion of said project without any unforeseen problems. The timeline is an estimation and not a 100% guarantee that the project will be <br/>complete by that date.</li>
                        <li>2. All project content is required before the project start date. If the client does not provide ALL content before this date then all timelines discussed <br/>are void.</li>
                        <li>3. If a client requests changes or additions outside of the original brief during project development then all timelines discussed are void.</li>
                        <li>4. Clients should be aware that providing content one day late does not simply add one day to the timeline. One5 Digital (OK PATEL TRUST) will<br/> begin the next project in line where ALL content has been provided and the original project will be placed further down the queue.</li>
                        <li>5. One5 Digital (OK PATEL TRUST) will not be liable for costs incurred, compensation or loss of earnings due to the failure to meet agreed deadlines.</li>
                    </ul>
                    <br/>
                    <h6>TYPICAL PROJECT PROCESS</h6>
                    <h6 style={{fontSize:'14px'}}>INITIAL PHASE</h6>
                    <li>• Project brief completed and Meetings with Sales Director</li>
                    <li>• Quote sent / Quote accepted</li>
                    <li>• 50% deposit to schedule the project</li>
                    <li>• Second meeting with Sales Director & Designers/Programmers</li>
                    <li>• Technical discussion regarding hosting, emails, data etc</li>
                    <li>• All content provided DESIGN PHASE</li>
                    <li>• Concept development begins</li>
                    <li>• Concept presentation with 2 rounds of revisions</li>
                    <li>• Concept signoff</li>
                    <li>• 25% milestone is due BUILD PHASE</li>
                    <li>• Website development & build</li>
                    <li>• Development link submitted to client for review</li>
                    <li>• Website feedback & changes</li>
                    <li>• Final review and signoff</li>
                    <li>• Remaining 25% is due LIVE PHASE</li>
                    <li>• Website goes live</li>
                    <li>• Live testing and checking</li>
                    <br/>
                    <h6 style={{fontSize:'14px'}}>WEBSITE SPECIFIC INFORMATION</h6>
                    <p style={{fontSize:'14px', fontWeight:'500'}}>Domain name registration</p>
                    <li>1. All domain names that One5 Digital (OK PATEL TRUST) register and manage on behalf of the client will always remain the clients property. Clients <br/>should contact One5 Digital (OK PATEL TRUST) directly if they choose to manage the domain name themselves at a different location.</li>
                    <li>2. One5 Digital (OK PATEL TRUST) will charge an hourly rate for any management of a domain name outside of the One5 Digital (OK PATEL TRUST) <br/>domain name management account.</li>
                    <br/>
                    <p style={{fontSize:'14px', fontWeight:'500'}}>Hosting (with One5 Digital (OK PATEL TRUST))</p>
                    <li>1. One5 Digital (OK PATEL TRUST) prefers clients to host their website on the One5 Digital (OK PATEL TRUST) server for quality and technical reasons<br/> but also for easy management, installation, trouble shooting and technical support. The determined yearly fee for hosting includes 1 domain <br/>name and management.</li>
                    <li>2. Hosting with One5 Digital (OK PATEL TRUST) provides the client with (at no extra cost): Server management, domain name management, <br/>website management and technical support including the setup of emails, databases, and other server side operations that One5 Digital (OK <br/>PATEL TRUST) deems part of the services offered. We are not responsible for 3rd party technology or making it compatible with our technology. Nor <br/>are we responsible for website hacking.</li>
                    <li>3. One5 Digital (OK PATEL TRUST) does not cover technical support for your personal home/office computer or software.</li>
                    <li>4. One5 Digital (OK PATEL TRUST) insist that you host your website on the One5 Digital (OK PATEL TRUST) server if you require a One5 Digital (OK<br/> PATEL TRUST) Content Management System.</li>
                    <li>5. One5 Digital (OK PATEL TRUST) takes no responsibility for loss of business due to server down time, errors or hacking. Hosting (3rd party server)</li>
                    <br/>
                    <p>If you choose to host your website on a 3rd party server then One5 Digital (OK PATEL TRUST) would like you to consider the following:</p>
                    <ul>
                        <li>1. Your chosen server may not be compatible with some of the code in the website that we design for you. Especially if your website makes use of <br/>a database.</li>
                        <li>2. One5 Digital (OK PATEL TRUST) does not offer technical support for 3rd party servers.</li>
                        <li>3. One5 Digital (OK PATEL TRUST) will charge an hourly rate for any work involving the 3rd party server.</li>
                    </ul>
                    <br/>
                    <p style={{fontSize:'14px', fontWeight:'500'}}>Website/server errors and liabilities</p>
                    <p> One5 Digital (OK PATEL TRUST) will endeavour to ensure that your website and any scripts or programs are free of errors but cannot<br/> accept responsibility for any losses incurred due to malfunction.</p>
                    <br/>
                    <p style={{fontSize:'14px', fontWeight:'500'}}>Website/server Hacking</p>
                    <p> One5 Digital (OK PATEL TRUST) cannot be held responsible for any hacking, malicious code or illegal activities of a third party that<br/> affect the Client’s website.</p>
                    <br/>
                    <p style={{fontSize:'14px', fontWeight:'500'}}>Software Hosting</p>
                    <p>One5 Digital (Ok Patel Trust) will host all the software designed by one5 digital. It's a custom software and IP rights belongs to One5<br/> Digital.</p>
                    <br/>
                    <h6>SEARCH ENGINE OPTIMIZATION(SEO)</h6>
                    <ul>
                        <li>1. One5 Digital (OK PATEL TRUST) offers no ranking guarantee for your website on Search Engines.</li>
                        <li>2. Your website will be generated with the basic level of SEO using meta-tags for descriptions and keywords.</li>
                        <li>3. Comprehensive SEO campaigns are available.</li>
                    </ul>
                    <br/>
                    <h6>CONTENT MANAGEMENT SYSTEM (CMS)</h6>
                    <ul>
                        <li>1. Certain components of our CMS are the intellectual property of One5 Digital (OK PATEL TRUST) and we require all websites we create with a CMS <br/>to be hosted on our servers.</li>
                        <li>2. If a client wishes to take their website to a 3rd party server they will need to pay a fee (day rate) to One5 Digital (OK PATEL TRUST) for the rights <br/>to use the intellectual property on another server. Or One5 Digital (OK PATEL TRUST) can strip certain components from the CMS and the 3rd party<br/> company can re-style or re-program the missing elements.</li>
                    </ul>
                    <br/>
                    <h6>GRAPHIC DESIGN/PRINT SPECIFIC INFORMATION</h6>
                    <ul>
                        <li>1. It is the client’s responsibility to make sure all information is correct before going to print. A proof will be provided for final approval. If errors are <br/>found after printing, the client is still liable for payment.</li>
                        <li>2. One5 Digital (OK PATEL TRUST) uses the services of a 3rd party company for printing. The 3rd party may change from project to project. One5 <br/>Digital (OK PATEL TRUST) is under no obligation to divulge the identity of the 3rd party printer.</li>
                        <li>3. The client is welcome to source their own printer but is then responsible for forwarding all printing specifications to One5 Digital (OK PATEL TRUST) <br/>BEFORE the design of the project commences.</li>
                        <li>4. Print colour may vary from print run to print run and/or from job to job or from front to back. It will almost definitely vary slightly between <br/>different print stock depending on the absorbency of the paper, the differences between matte and gloss and other factors.</li>
                        <li>5. Colours will definitely vary from screen to print. Unless a specific colour code is provided One5 Digital (OK PATEL TRUST) will endeavour to match<br/> the colour from screen to print to the best of our ability.</li>
                        <li>6. One5 Digital (OK PATEL TRUST) take no responsibility for errors or colour matching based on materials from 3rd party design agencies.</li>
                    </ul>
                    <br/>
                    <h6>PERMISSIONS, COPYRIGHT AND INTELLECTUAL PROPERTY</h6>
                    <ul>
                        <li>1. All project creation files, including programming code, created by One5 Digital (OK PATEL TRUST) remains the intellectual/creative property of<br/> One5 Digital (OK PATEL TRUST).</li>
                        <li>2. One5 Digital (OK PATEL TRUST) also retains copyright of all original project design/graphics/photography/video and programming code (unless <br/>specifically agreed) and may only be commercially reproduced or resold with the permission of One5 Digital (OK PATEL TRUST). The client is <br/>permitted to use this material only as expressly authorised by One5 Digital (OK PATEL TRUST).</li>
                        <li>3. One5 Digital (OK PATEL TRUST) cannot take responsibility for any copyright infringements caused by materials submitted by the client.</li>
                        <li>4. One5 Digital (OK PATEL TRUST) reserve the right to re-use technical components developed during a project and will retain intellectual property<br/> rights on each development.</li>
                        <li>5. One5 Digital (OK PATEL TRUST) shall be free to reproduce, use, disclose, display, exhibit, transmit, perform, create derivative works, and distribute<br/> any item from your project unless specifically agreed otherwise. 6. One5 Digital (OK PATEL TRUST) observes Privacy Laws and Guidelines relating to<br/> personal data.</li>
                    </ul>
                    <br/>
                    <h6>ERRORS, TECHNICAL SUPPORT AND MAINTENANCE</h6>
                    <ul>
                        <li>1. One5 Digital (OK PATEL TRUST) offers free technical support for websites hosted with One5 Digital (OK PATEL TRUST) (does not include hacking or <br/>malicious code related issues). Clients who host their website on a 3rd party server are required to contact the 3rd party company themselves for <br/>any technical issues. One5 Digital (OK PATEL TRUST) will charge an emergency rate for support on 3rd party servers.</li>
                        <li>2. One5 Digital (OK PATEL TRUST) does not offer technical support for the general use of your computer and any installed software there on.</li>
                        <li>3. From time to time, one of the many software/technical applications that a website uses to function may be upgraded by the 3rd party <br/>company, creating errors. One5 Digital (OK PATEL TRUST) will always endeavour to make sure that any website created by One5 Digital (OK PATEL <br/>TRUST), and hosted on our server, remains in a functioning order based on One5 Digital (OK PATEL TRUST) specifications.</li>
                        <li>4. One5 Digital (OK PATEL TRUST) takes no responsibility for loss of business due to server down time, errors or hacking.</li>
                        <li>5. One5 Digital (OK PATEL TRUST) is not responsible for your internet service, home or office IT, home or office networking, computers, 3rd party <br/>software/technology, faxes, printers, routers, home or office mail-servers, phones, PDA's, mp3 players, radio and TV transmissions, or any other <br/>technical/electronic devises. Clients are encouraged to contact the technical support department for the product purchased or the support <br/>department of the company used to install said devises at your location.</li>
                    </ul>
                    <br/>
                    <h6>TECHNICAL SUPPORT IS AVAILABLE</h6>
                    <p>Monday to Thursday 10am - 5pm AEST<br/>
                    Friday 10am - 3pm AEST<br/>
                    <a href="mailto:prince@one5.com.au" className="tnc-contact">Email: prince@one5.com.au</a><br/>
                    <a href="tel:0430176036" className="tnc-contact">Call: +61 0430 176 036</a>
                    </p>
                    <br/>
                    <h6>LIABILITIES</h6>
                    <p>One5 Digital (OK PATEL TRUST) will not be liable or become involved in any disputes between the project owner and their clients or any <br/>other 3rd parties and cannot be held responsible for any wrongdoing on the part of a project client.</p>
                    <br/>
                    <h6>YOUR (THECLIENTS) OBLIGATIONS</h6>
                    <p>You:</p>
                    <ul>
                        <li>1. Agree not to use the Website (or any part of) for any illegal purpose and agree to use it in accordance with all relevant laws.</li>
                        <li>2. Agree not to upload or transmit through the Website any computer viruses, macro viruses, trojan horses, worms or anything else designed to<br/> interfere with, interrupt or disrupt the normal operating procedures of a computer.</li>
                        <li>3. Will not upload or transmit through the Website any material which is defamatory, offensive, or of an obscene or menacing character, or that <br/>may cause annoyance, inconvenience or needless anxiety.</li>
                        <li>4. Will not use the Website in a way that may cause the Website to be interrupted, damaged, rendered less efficient or such that the effectiveness <br/>or functionality of the Website is in any way impaired.</li>
                        <li>5. Will not use the Website in any manner which violates or infringes the rights of any person, firm or company (including, but not limited to, rights<br/> of intellectual property, rights of confidentiality or rights of privacy).</li>
                        <li>6. Agree that in the event that you have any right, claim or action against any Users arising out of that User's use of the Website, then you will <br/>pursue such right, claim or action independently of and without recourse to One5 Digital (OK PATEL TRUST).</li>
                    </ul>
                    <br/>
                    <h6>CHANGES TO THEE TERMS AND CONDITIONS</h6>
                    <p>One5 Digital (OK PATEL TRUST) reserves the right to update these terms and conditions at any time without notice.</p>
                </div>
            </div>
        </div>
    )
}

export default TnC


import React from 'react';
import './SBexp.css';

function SBexp() {
    return (
        <div className="sbexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 3rem 0 3rem'}}>Sn'S Babycare provides 100% natural products, so we created website that shows their purpose to the users. The themes and colors that gives the natural look to the design. Visit website for more look. </p>
            </div>
            <div>
                {/* <video className="sb-video" autoPlay loop muted>
                    <source src="./SnS_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./SnS_One5Digital.gif" alt= "Loading..." className="sb-video"  />
            </div>
        </div>
    )
}

export default SBexp

import React from 'react';
import PRabout from '../PRabout';
import PRdesc from '../PRdesc';
import PRexp from '../PRexp';
import PRpro from '../PRpro';
import {Helmet} from "react-helmet";

function Paul_Ryan_Plumbing() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Paul Ryan Plumbing & Contracting Project | One5 Melbourne</title>
            <meta name="description" content="One5's is the project for plumbing services for residential and commercial properties. Trustworthy and reliable service from licenced professionals." />
            <meta name="keywords" content="Paul Ryan Plumbing, Contracting Project, Paul Ryan Plumbing Project, One5's Paul Ryan Plumbing Project" />
        </Helmet>
            <PRabout />
            <PRexp />
            <PRpro />
            <PRdesc />
        </React.Fragment>
    )
}

export default Paul_Ryan_Plumbing

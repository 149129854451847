import React from 'react';
import './GDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function GDesignAbout() {
    return (
        <div className="GDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>GRAPHIC DESIGN</h1>
                    <h6>Welcome to One5 Digital's Graphic Design Service!</h6>
                    <p>At One5 Digital, we understand that a visually appealing design is crucial for any business. Our experienced graphic designers specialize in creating custom designs that meet your specific business needs, from branding and marketing materials to social media graphics and more.
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GDesignAbout

/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './ServicePage.css';
import { Button } from './Button';
import { Link } from "react-router-dom";

function ServicePage() {
    const [, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="servicepage">
        <div className="desc">
            <div className="left-servicedesc">
                <h1 data-aos='fade-up' data-aos-duration="500"><span style={{color: '#F04E31'}} >OUR SERVICES </span></h1>
                <p data-aos='fade-up' data-aos-duration="1500" className="service-desc">We are a full service digital agency so we provide a range of strategic, creative and technical solutions.</p>
            </div>
        </div>
            <div className="servicepage-wrapper">
            <div className="servicepageall servicepage-1">
                <div className="p1-desc">
                    <h6 data-aos='fade-up' data-aos-duration="1500">LOGO DESIGN</h6>
                    <p data-aos='fade-up' data-aos-duration="1500">We craft meaningful brands through visual identity, print and digital experience 
                     for small to medium business all over the world.</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='project-btn'>
                        <Link exact to="/Services/Logo_Design_Melbourne">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="p1-img">
                    <img src= "./service1_One5Digital.png" alt= "Logo Design" className="simg"/>
                </div>
            </div>
            <div className="servicepageall servicepage-2">
                <div data-aos='fade-up' className="p2-img">
                    <img src= "./service2_One5Digital.png" alt= "Website Design" className="simg"/>
                </div>
                <div className="p2-desc">
                    <h6 data-aos='fade-up'>WEBSITE DESIGN</h6>
                    <p data-aos='fade-up'>When we build you a website, not only are we responsible for you a modern ,
                     updated design, we are responsible for making that website a sales tool for 
                     your business.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Services/Website_Design_Melbourne">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="servicepageall servicepage-3">
                <div className="p3-desc">
                    <h6 data-aos='fade-up'>E-COMMERCE WEBSITE</h6>
                    <p data-aos='fade-up'>We work with you to understand your business, your competitors and most
                     importantly your customers.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Services/Ecommerce_Design_Melbourne">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="p3-img">
                    <img src= "./service3_One5Digital.png" alt= "E- Commerce Website " className="simg"/>
                </div>
            </div>
            <div className="servicepageall servicepage-4">
                <div data-aos='fade-up' className="p4-img">
                    <img src= "./service4_One5Digital.png" alt= "Search Engine Optimization(SEO)" className="simg" />
                </div>
                <div className="p4-desc">
                    <h6 data-aos='fade-up'>SEARCH ENGINE OPTIMIZATION (SEO)</h6>
                    <p data-aos='fade-up'>The organic SEO is the best approach to any company looking for a long term 
                    investment with fruitful return.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Services/SEO_Melbourne">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="servicepageall servicepage-5">
                <div className="p5-desc">
                    <h6 data-aos='fade-up'>PAY PER CLICK (PPC)</h6>
                    <p data-aos='fade-up'>One 5 digital can tailor a Paid Search Campaign that targets
                     the buyer your are looking for.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Services/Pay_Per_Click_Melbourne">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="p5-img">
                    <img src= "./service5_One5Digital.jpg" alt= "Pay Per Click(PPC)" className="simg"/>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ServicePage;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './RequestQuote.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function RequestQuote() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="request-quote">
            <div className="desc">
                <p data-aos='fade-up' className = 'request-shortdesc'>───── have a project in mind?</p>
                <div className="left-about">
                    <h1 data-aos='fade-up'>LET US UNDERSTAND YOUR BUSINESS TO PROVIDE A SOLUTION</h1>
                        <div data-aos='fade-up' className='service-btn'>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--white' >Request a Quote</Button>
                            </Link>
                        </div>
                </div>
                {/* <div className="right-about">
                    <img className="red-ball" src= "./red-ball.gif" alt= "Loading..." />
                </div> */}
            </div>
        </div>
    )
}
 
export default RequestQuote

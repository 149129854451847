import React from 'react';
import './BADesc2.css';

function BADesc2() {
    return (
        <div className="WDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Bing Ads Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>EXPERTISE IN BING ADS </h6>
                            <p className="ec-desc">One5 Digital specializes in Bing Ads and has extensive experience and knowledge in leveraging the platform effectively. They understand the nuances of Bing Ads and stay up-to-date to maximize your advertising results.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>CUSTOMIZED STRATEGIES</h6>
                            <p className="ec-desc">One5 Digital takes a tailored approach to meet your specific business goals. They analyze your target audience, industry, and competitors to develop customized strategies that align with your objectives.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>DATA-DRIVEN APPROACH </h6>
                            <p className="ec-desc">One5 Digital relies on data and analytics to drive decision-making. They track and measure the performance of your Bing Ads campaigns, allowing them to make data-driven optimizations and adjustments to improve results over time.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>COMPREHENSIVE SERVICES</h6>
                            <p className="ec-desc">One5 Digital offers a range of services related to Bing Ads, including campaign setup and management, keyword research, ad creation, landing page optimization, tracking implementation, and performance monitoring.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>TRANSPARENT REPORTING</h6>
                            <p className="ec-desc">One5 Digital believes in transparency and provides detailed reporting on the performance of your Bing Ads campaigns. They offer regular updates and insights and make informed decisions for future strategies.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BADesc2
import React from 'react';
import AAabout from '../AAabout';
import AAdesc from '../AAdesc';
import AAexp from '../AAexp';
import AApro from '../AApro';
import {Helmet} from "react-helmet";

function Amazing_Amazon() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Amazing Amazon Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Amazing Amazon projects have helped businesses increase sales and visibility on the world's largest online retailer." />
            <meta name="keywords" content="Amazing Amazon, Amazing Amazon Project, One5's Amazing Amazon projects" />
        </Helmet>
            <AAabout />
            <AAexp />
            <AApro />
            <AAdesc />
        </React.Fragment>
    )
}

export default Amazing_Amazon

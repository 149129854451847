import React from 'react';
import SHabout from '../SHabout';
import SHexp from '../SHexp';
import SHpro from '../SHpro';
import SHdesc from '../SHdesc';
import {Helmet} from "react-helmet";

function Staged_Homes_by_Melissa() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Staged Homes by Melissa Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's helped the Staged Homes by Melissa project boost their transformation of your home into a dream home. Professional home staging service that attracts buyers and sells your home fast." />
            <meta name="keywords" content="Staged Homes, Staged Homes Project, One5's Staged Homes Project" />
        </Helmet>
            <SHabout />
            <SHexp />
            <SHpro />
            <SHdesc />
        </React.Fragment>
    )
}

export default Staged_Homes_by_Melissa

import React from 'react';
import './SDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SDesignAbout() {
    return (
        <div className="SDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>SOCIAL MEDIA DESIGN</h1>
                    <h6>Welcome to One5 Digital's Social Media Design Service!</h6>
                    <p>At One5 Digital, we understand the importance of social media in today's digital age. Social media is a powerful tool for businesses to connect with their target audience and build brand awareness. Our experienced social media designers specialize in creating custom designs that engage your audience and help your business stand out on social media platforms.
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SDesignAbout

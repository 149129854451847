import React from 'react';
import './GPDesc2.css';

function GPDesc2() {
    return (
        <div className="WDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Google PPC Needs?</h1>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>CAMPAIGN STRATEGY </h6>
                            <p className="ec-desc">One5 Digital's team will work with you to understand your business goals, target audience, and competitive landscape to develop a customized campaign strategy tailored to your specific needs.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>AD CREATION</h6>
                            <p className="ec-desc">One5 Digital's team of experienced copywriters and designers can help you create compelling ads that will capture the attention of your target audience and drive more clicks and conversions.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>LANDING PAGE OPTIMIZATION</h6>
                            <p className="ec-desc">One5 Digital can help you optimize your landing pages to ensure that they are designed to convert visitors into customers and provide a seamless user experience.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>CAMPAIGN MANAGEMENT</h6>
                            <p className="ec-desc">One5 Digital's team will monitor your campaigns regularly and adjust as needed to ensure that they are performing at their best and delivering the desired results.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>REPORTING AND ANALYSIS</h6>
                            <p className="ec-desc">One5 Digital will provide regular reports that provide insight into your campaigns' performance and identify improvement opportunities.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GPDesc2
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './PEabout.css';
import { Link } from 'react-router-dom';

function PEabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'peabout'>
            <div className = 'peabout-container'>
				<div className = 'peaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>POWER EQUIPMENT AUSTRALASIA</h1>
                </div>
                <div className = 'peaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'peabout-desc'>Power Equipment was established on October 1st, 1990 and is the exclusive authorised Australian, New Zealand, PNG and the South Pacific Distributor of Yanmar Marine and Industrial 
                        diesel engines importing product from Yanmar plants all around the world. </p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='peabout-btn'>
                        <a href="https://www.power-equipment.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="peaboutcol-right">
                    <img className="peimg" src= "./PowerEquipAbout_One5Digital.png" alt= "Loading..." />
                    <img className="peimg2" src= "./PowerEquipAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default PEabout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './SBabout.css';
import { Link } from 'react-router-dom';

function SBabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'sbabout'>
            <div className = 'sbabout-container'>
				<div className = 'sbaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>SN'S BABYCARE</h1>
                </div>
                <div className = 'sbaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'sbabout-desc'>Sn'S Babycare provides 100% biodegradable and sustainably sourced bamboo wipes have been brought to Australian enviroment to continuous support green environment and running an environmentally friendly 
                    business like us helps reduce impact on the environment.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='sbabout-btn'>
                        <a href="https://snsbabycare.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="sbaboutcol-right">
                    <img className="sbimg" src= "./SnSAbout_One5Digital.png" alt= "Loading..." />
                    <img className="sbimg2" src= "./SnSAbout2_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    ) 
}

export default SBabout

import React from 'react';
import './WPdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function WPdesc() {
    return (
        <div className="pedesc">
            <div className="pedesc-cat2">
                <div className="pedesc2-left">
                    <p>───── NEXT CASE STUDY</p>
                    <h1>PURITY PLUMBING</h1>
                    <p>At Purity Plumbing, we understand the urgency of such situations, which is why we offer 24/7 emergency plumbing services in Melbourne. Serving the Western Suburbs of Melbourne for Over 8 Years.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Purity_Plumbing">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div className="pedesc2-right">
                    <img className="pedesc2ri" src="./PurityPlumbingProject_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default WPdesc
import React from 'react';
import './ABdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function ABdesc() {
    return (
        <div className="abdesc">
            <div className="abdesc-cat2">
                <div className="abdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>AXLEFIX</h1>
                    <p>Axlefix, Axle Correction (Campbellfield), is a specialist in Heavy Axle Correction and wheel alignment to all makes and models of trucks and buses.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Axlefix">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="abdesc2-right">
                <img className="abdesc2ri" src="./AxlefixProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default ABdesc

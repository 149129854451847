import React from 'react';
import './SHexp.css';

function SHexp() {
    return (
        <div className="shexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 4rem 0 4rem'}}>Melissa helps to maximize your home’s sales potential and minimize your time on the market by transforming spaces at minimal expense, so we've created website that represents her work more effectively. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="sh-video" autoPlay loop muted>
                    <source src="./StagedHomes_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./StagedHomes_One5Digital.gif" alt= "Loading..." className="sh-video"  />
            </div>
        </div>
    )
}

export default SHexp

import React from 'react';
import './WDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function WDesignAbout() {
    return (
        <div className="WDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>WEBSITE DESIGN</h1>
                    <h6>Welcome to One5 Digital's Website Design Service!</h6>
                    <p>At One5 Digital, we believe that a well-designed website is key to the success of any business. Your website is often the first impression that potential customers have of your business, which is why it's important to have a website that is not only visually appealing but also easy to navigate, informative, and engaging.
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WDesignAbout

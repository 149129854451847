import React from 'react';
import './VVdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function VVdesc() {
    return (
        <div className="vvdesc">
            <div className="vvdesc-cat2">
                <div className="vvdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>TIFFANY SHEN</h1>
                    <p>Miss Tiffany Shen is the proud winner of Miss Australia International 2020. This is a worldwide movement 
                        that recognizes and celebrates women whose voices strive to make a difference.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Miss_Australia_Tiffany_Shen">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="vvdesc2-right">
                <img className="vvdesc2ri" src="./MissTiffanyProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default VVdesc

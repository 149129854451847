/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './PMabout.css';
import { Link } from 'react-router-dom';

function PMabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'pmabout'>
            <div className = 'pmabout-container'>
				<div className = 'pmaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>PURITY PLUMBING</h1>
                </div>
                <div className = 'pmaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'pmabout-desc'>At Purity Plumbing, we understand the urgency of such situations, which is why we offer 24/7 emergency plumbing services in Melbourne. Serving the Western Suburbs of Melbourne for Over 8 Years.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2023</p>
                    <div className='pmabout-btn'>
                        <a href="https://purity.plumbing/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="pmaboutcol-right">
                    <img className="pmimg" src= "./PurityPlumbingAbout_One5Digital.png" alt= "Loading..." />
                    <img className="pmimg2" src= "./PurityPlumbingAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default PMabout

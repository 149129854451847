import React from 'react';
import './TFdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function TFdesc() {
    return (
        <div className="tfdesc">
            <div className="tfdesc-cat2">
                <div className="tfdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>MOTOR COMPONENTS</h1>
                    <p data-aos='fade-up'>Motor Components is an Australian based supplier and Retailer of auto parts of all
                         vehicle makes and models since 1993. The company has 20 years of experience as an importer, exporter, wholesaler and retailer of Auto products and accessories.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Motor_Components">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up'	 className="tfdesc2-right">
                <img className="tfdesc2ri" src="./MotorComponentProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default TFdesc

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';
 
function HeroSection() {
    
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'hero-section'>
            <div className = 'hero-container'>
                <div className = 'col-left'>
                    <p data-aos='fade-up' data-aos-duration="500" className = 'hero-shortdesc' style={{ color: '#F04E31' }}>───── WE ARE</p>
                    <h1 data-aos='fade-up' data-aos-duration="1500">A FULL SERVICE <br/>
                    DIGITAL AGENCY</h1>
                    <p data-aos='fade-up' data-aos-duration="2000" className = 'hero-desc'>Our creative team is focused on stunning and results-driven 
                    solutions for small to medium businesses all over the world.</p>
                    <div data-aos='fade-up' data-aos-duration="2500" className='hero-btn'>
                    <Link excat to="/Contact_One5_Digital"><Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button></Link>
                    </div>
                </div>
                <div className = 'col-right'>
                    <div className="parent">
                        <img data-aos='fade-up' className="blob" src= "./redblob_One5Digital.png" alt= "One5 Digital Agency" />
                        <img className="vector" src="./vector_One5Digital.svg" alt="One5 Digital Agency" /> 
                    </div>
                    <div className="hero-social">
                        <a className="hsocial" href="https://www.instagram.com/one5digital/" target="blank"><i class="fab fa-instagram hsocial"></i></a>
                        <a className="hsocial" href="https://au.linkedin.com/in/prince-patel" target="blank"><i class="fab fa-linkedin-in hsocial"></i></a>
                        <a className="hsocial" href="https://g.page/r/CdX-iKJfE8rSEAo" target="blank"><i class="fas fa-map-marker-alt hsocial"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection

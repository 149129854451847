import React from 'react';
import './VPDesignDesc2.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function VPDesignDesc2() {
  return (
    <div className="VPDesign-desc">
        <div data-aos='fade-up' className="design-desc">
            <p>In addition to reliability and security, One5 Digital also provides 24/7 technical support to ensure that any issues or questions you may have are addressed promptly. Their team of experts is available around the clock to assist with anything from basic troubleshooting to more complex technical issues.</p>
        </div>
    </div>
  )
}

export default VPDesignDesc2
import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import BAabout from '../BAabout';
import BADesc from '../BADesc';
import BADesc2 from '../BADesc2';
import BAcontact from '../BAcontact';

function Bing_Ads() {
  return (
    <React.Fragment>
      <Helmet>
        <title>One5 Digital in Melbourne: Your One-Stop Destination for Bing Ads Services</title>
        <meta name="description" content="Looking for a reliable digital marketing agency in Melbourne for Bing Ads services? Look no further than One5 Digital! Our experts can help you maximize your online advertising potential with Bing Ads." />
        <meta name="keywords" content="One5 Digital, Melbourne, Bing Ads services, digital marketing, online advertising" />
      </Helmet>
      <BAabout />
      <BADesc />
      <BADesc2 />
      <FeaturedProject />
      <Testimonials />
      <BAcontact />
    </React.Fragment>
  )
}

export default Bing_Ads
import React from 'react';
import './SDdetail.css';

function SDdetail() {
  return (
    <div className = 'sddetail'>
        <div className = 'sdabout-container'>
            <div className = 'sddetail-left'>
                <h1 className='sddetailcol-left'>We’re a Melbourne based eCommerce agency that's obsessed with growing your business.</h1>
                <p className = 'sddetail-desc'>As a Shopify Partner Agency and eCommerce development agency, we leverage the power of the platform to design, develop, and optimize beautiful eCommerce websites for fashion & lifestyle brands. We love partnering with merchants to help them scale through data-backed ecommerce development & UX design services, conversion rate optimization, and Klaviyo email marketing services.</p>
            </div> 
        </div>
    </div>
  )
}

export default SDdetail
import React from 'react';
import './SLDesignDesc3.css';

function SLDesignDesc3() {
  return (
    <div className="SLDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./ssl2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>In addition to SSL Certification installation services, One5 Digital Melbourne also offers a range of website security solutions, including website security audits, vulnerability assessments, and website security monitoring.</p>
            <p>At One5 Digital Melbourne, we are committed to providing our clients with the best possible website security solutions. Our team of experts is highly experienced in SSL Certification installation and will work with you to ensure that your website is safe and secure. Contact us today to learn more about our SSL Certification installation services and how we can help protect your website.</p>
        </div>
    </div>
  )
}

export default SLDesignDesc3
import React from 'react';
import './VPDesignDesc.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function VPDesignDesc() {
  return (
    <div className="VPDesign-about">
        <div data-aos='fade-up' className="left-side">
            <p>One5 Digital is a Melbourne-based web hosting company specializing in providing customers with VPS Managed Hosting services worldwide. Focusing on reliability, security, and customer support, One5 Digital offers a range of customizable plans designed to meet the needs of businesses of all sizes.</p>
            <p>Reliability is key in web hosting, and One5 Digital prides itself on its 99.99% uptime guarantee. This means that your website will always be available to your customers and clients, ensuring that you never miss out on potential business. One5 Digital also offers automatic backups and a range of security measures to protect your website from hacks and cyber threats.</p>
        </div>
        <div className='right-side' style={{textAlign: 'center'}}>
            <img data-aos='fade-up' className="designimg" src="./vps_desc_One5Digital.png" alt="Loading..." />
        </div>
    </div>
  )
}

export default VPDesignDesc
import React from 'react';
import './CCdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function CCdesc() {
    return (
        <div className="ccdesc">
            <div className="ccdesc-cat2">
                <div className="ccdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>TRINITY MAINTENANCE GROUP</h1>
                    <p>Trinity Maintenance Group pledge to provide accountable, knowledgeable cooling and heating service in Melbourne and surroundings, no matter the manufacturer. We always strive to be your one-stop, hassle-free solution to indoor air quality.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Trinity_Maintenance_Group">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="ccdesc2-right">
                <img className="ccdesc2ri" src="./TrinityProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default CCdesc

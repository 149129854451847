import React from 'react';
import GDesignAbout from '../GDesignAbout';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import GDesignDesc from '../GDesignDesc';
import GDesignDesc2 from '../GDesignDesc2';
import GDesign_contact from '../GDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Graphic_Design() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Creative Graphic Design Services | One5 Graphic Design Agency</title>
        <meta name="description" content="One5 Graphic Design Agency provides creative and professional graphic design services in Australia. Our experienced designers can help you establish your brand identity, create marketing materials, and design eye-catching visuals that communicate your message effectively. Whether you need a logo, business card, brochure, or any other graphic design service, we can help you bring your ideas to life. Contact us today for a free consultation!" />
        <meta name="keywords" content="graphic design, creative design, professional design, branding, visual identity" />
      </Helmet>
      <GDesignAbout />
      <GDesignDesc />
      <GDesignDesc2 />
      <FeaturedProject />
      <Testimonials />
      <GDesign_contact />
    </React.Fragment>
  )
}

export default Graphic_Design
import React from 'react';
import './MCDesignDesc2.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function MCDesignDesc2() {
  return (
    <div className="MCDesign-desc">
        <div data-aos='fade-up' className="design-desc">
            <p>Our services are designed to detect and remove malware, viruses, and other harmful code from your website. Our team of professionals has extensive experience in malware removal and can identify and eliminate any malware-causing issues with your website's performance.</p>
            <p>We use the latest technology and tools to remove malware from your website, ensuring that your website stays up and running without any problems. Our team also performs regular security checks to ensure your website remains secure and free from potential threats.</p>
        </div>
    </div>
  )
}

export default MCDesignDesc2
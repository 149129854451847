import React from 'react';
import BMabout from '../BMabout';
import BMdesc from '../BMdesc';
import BMexp from '../BMexp';
import BMpro from '../BMpro';
import { Helmet } from "react-helmet";

function Beat_The_Rest_Movers() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Beat The Rest Movers | One5 Digital Agency Melbourne</title>
                <meta name="description" content="One5's Axlefix project provides mechanical repairs, servicing and maintenance for all vehicle types. Visit us online for more information or to book a service." />
                <meta name="keywords" content="Axlefix, Axlefix Project, One5's Axlefix Project" />
            </Helmet>
            <BMabout />
            <BMexp />
            <BMpro />
            <BMdesc />
        </React.Fragment>
    )
}

export default Beat_The_Rest_Movers
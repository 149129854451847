import React from 'react';
import './MCexp.css';

function MCexp() {
    return (
        <div className="mcexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Motor Components' website design represents the brand and it's purpose in easy and simple way to the users. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="mc-video" autoPlay loop muted>
                    <source src="./MotorComponent_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./MotorComponent_One5Digital.gif" alt= "Loading..." className="mc-video"  />
            </div>
        </div>
    )
}

export default MCexp

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './SWabout.css';
import { Link } from 'react-router-dom';

function SWabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'swabout'>
            <div className = 'swabout-container'>
				<div className = 'swaboutcol-full'>
                    <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">SAMANTHA JWILLIS</h1>
                </div>
                <div className = 'swaboutcol-left'>
					<h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'swabout-desc'>Samantha Willis is a young Australian writer who lives in Perth, Western Australia, and Karaneshia is her first novel.
                    Up until she created the captivating characters of Karaneshia, Sam has been writing short stories of various themes. </p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2020</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='swabout-btn'>
                        <a href="https://samanthajwillis.com/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="swaboutcol-right">
                    <img className="swimg" src= "./SamanthaAbout_One5Digital.png" alt= "Loading..." />
                    <img className="swimg2" src= "./SamanthaAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default SWabout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './DGabout.css';
import { Link } from 'react-router-dom';

function DGabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'dgabout'>
            <div className = 'dgabout-container'>
				<div className = 'dgaboutcol-full'>
                     <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">DR GREEN COSMETIC GROUP</h1>
                </div>
                <div className = 'dgaboutcol-left'>
                   
                    <h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'dgabout-desc'>We have taken all the recommended steps to ensure your safety as things begin to return to normal. This includes our 
                        doctors and nurses wearing masks and gloves for all cosmetic injectable treatments.</p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2020</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='dgabout-btn'>
                        <a href="https://drgreencosmeticgroup.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="dgaboutcol-right">
                    <img className="dgimg" src= "./DrGreenAbout_One5Digital.png" alt= "Loading..." />
                    <img className="dgimg2" src= "./DrGreenAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default DGabout

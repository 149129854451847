import React from 'react';
import './MCdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function MCdesc() {
    return (
        <div className="mcdesc">
            <div className="mcdesc-cat2">
                <div className="mcdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>DR GREEN COSMETIC GROUP</h1>
                    <p>We have taken all the recommended steps to ensure your safety as things 
                        begin to return to normal. This includes our doctors and nurses wearing masks and gloves for all cosmetic injectable treatments. </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Dr_Green_Cosmetic_Group">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="mcdesc2-right">
                <img className="mcdesc2ri" src="./DrGreenProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default MCdesc

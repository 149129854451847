import React from 'react';
import GBabout from '../GBabout';
import GBdesc from '../GBdesc';
import GBexp from '../GBexp';
import GBpro from '../GBpro';
import {Helmet} from "react-helmet";

function The_Grand_Band() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>The Grand Band Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's The Grand Band project is a luxury retirement living community offering resort-style amenities and activities. Live your golden years in style.!" />
            <meta name="keywords" content="The Grand Band, The Grand Band Project, One5's The Grand Band Project" />
        </Helmet>
            <GBabout />
            <GBexp />
            <GBpro />
            <GBdesc />
        </React.Fragment>
    )
}

export default The_Grand_Band
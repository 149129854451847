/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './AboutUs.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function AboutUs() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="about-us">
            <div className="desc">
                <p data-aos='fade-up' className = 'about-shortdesc' style={{ color: '#F04E31' }}>───── ABOUT US</p>
                <div className="left-about">
                    <h1 data-aos='fade-up'>WHAT WE <span style={{color: '#F04E31'}} >BELIEVE </span></h1>
                    <p data-aos='fade-up'>We believe that creating memorable experiences are the best way 
                        to connect with your consumers. From dynamic web designs to 
                        cutting-edge digital marketing strategies, we believe that the
                        custom solutions we create today will transcend the trends of 
                        tomorrow. No matter your product or service, you have a story to tell.</p>
                    <div data-aos='fade-up' className='about-btn'>
                        <Link exact to="/About_One5_Digital">
                            <Button className='btn' buttonStyle='btn--outline' >Learn more</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="right-aboutp">
                    <img src= "./one5-circle-vector-red_One5Digital.svg" alt= "Loading..." className="avector" /> 
                </div>
            </div>
        </div>
    )
}

export default AboutUs

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './DSabout.css';
import { Link } from 'react-router-dom';

function DSabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'dsabout'>
            <div className = 'dsabout-container'>
				<div className = 'dsaboutcol-full'>
                     <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">DR GREEN COSMETIC SHOP</h1>
                </div>
                <div className = 'dsaboutcol-left'>
                   
                    <h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'dsabout-desc'>At our cosmetic clinic, we pride ourselves on communicating with you about your beauty goals and providing quality care to aid you in those goals. We look forward to speaking with you and supporting you in your beauty journey.</p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2022</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='dsabout-btn'>
                        <a href="https://shop.drgreencosmeticgroup.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="dsaboutcol-right">
                    <img className="dsimg" src= "./DrGreenShopAbout_One5Digital.png" alt= "Loading..." />
                    <img className="dsimg2" src= "./DrGreenShopAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default DSabout
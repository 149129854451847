import React from 'react';
import SSabout from '../SSabout';
import SSdesc from '../SSdesc';
import SSexp from '../SSexp';
import SSpro from '../SSpro';
import {Helmet} from "react-helmet";

function Super_Star_Boards() {
  return (
    <React.Fragment> 
    <Helmet> 
        <title>Super Star Boards Project | One5 Digital Agency Melbourne</title>
        <meta name="description" content="One5's Super Star Boards project aims to increase their online presence and sales through effective digital marketing strategies." />
        <meta name="keywords" content="Super Star Boards, Super Star Boards Project, One5's Super Star Boards Project" />
    </Helmet>
        <SSabout />
        <SSexp />
        <SSpro />
        <SSdesc />
    </React.Fragment>
  )
}

export default Super_Star_Boards
import React from 'react';
import STDesignAbout from '../STDesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import STDesignDesc from '../STDesignDesc';
import STDesignDesc2 from '../STDesignDesc2';
import STDesign_contact from '../STDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Stationary_Design() {
  return (
    <React.Fragment> 
        <Helmet> 
            <title>Professional Stationery Design Services | One5 Graphic Design Agency</title>
            <meta name="description" content="One5 Graphic Design Agency provides professional stationery design services in Australia. We can help you create custom business cards, letterheads, envelopes, and other branded stationery items that are consistent with your brand identity. Our experienced designers can help you design stationery that effectively communicates your message and helps you stand out from the competition. Contact us today for a free consultation and take the first step towards establishing a strong visual identity for your business." />
            <meta name="keywords" content="stationery design, business card design, letterhead design, envelope design, branding" />
        </Helmet>
        <STDesignAbout />
        <STDesignDesc />
        <STDesignDesc2 />
        <FeaturedProject />
        <Testimonials />
        <STDesign_contact />
    </React.Fragment>
  )
}

export default Stationary_Design
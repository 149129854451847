import React from 'react';
import './SWDabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SWDabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>SHOPIFY WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's Shopify Website Development Service!</h6>
                {/* <p>Boost Your Online Business with One5 Digital's Woo Commerce Website Development Services in Melbourne</p> */}
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>In the era of digital transformation, establishing a strong online presence is crucial for businesses of all sizes. Whether you're a small startup or an established brand, having a well-designed and functional e-commerce website is essential to capture the attention of potential customers. If you're in Melbourne and seeking exceptional Shopify website development services, look no further than One5 Digital.</p>
                        {/* <p className='smp'>PHP (Hypertext Preprocessor) is a widely-used scripting language that is particularly suited for web development. It is known for its flexibility, scalability, and compatibility with various platforms and frameworks. One5 Digital harnesses the power of PHP to create dynamic, interactive, and feature-rich websites that cater to the unique needs of your business.</p> */}
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./shopify_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SWDabout

import React from 'react';
import './SEODesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SEODesignAbout() {
    return (
        <div className="SEODesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>SEARCH ENGINE OPTIMIZATION</h1>
                    <h6>Welcome to One5 Digital's Search Engine Optimization Service!</h6>
                    <p>In today's digital age, having a strong online presence is vital for businesses of all sizes. With millions of websites competing for attention, standing out from the crowd can be challenging. That's where search engine optimization (SEO) comes in. SEO is optimizing your website to improve its visibility on search engines like Google, Bing, and Yahoo.</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SEODesignAbout

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './MCabout.css';
import { Link } from 'react-router-dom';

function MCabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'mcabout'>
            <div className = 'mcabout-container'>
				<div className = 'mcaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>MOTOR COMPONENTS</h1>
                </div>
                <div className = 'mcaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'mcabout-desc'>Motor Components is an Australian based supplier and Retailer of auto parts of all vehicle makes and models since 1993. The company has 
                    20 years of experience as an importer, exporter, wholesaler and retailer of Auto products and accessories.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='mcabout-btn'>
                        <a href="https://motorco.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="mcaboutcol-right">
                    <img className="mcimg" src= "./MotorComponentAbout_One5Digital.png" alt= "Loading..." />
                    <img className="mcimg2" src= "./MotorComponentAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default MCabout

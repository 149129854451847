import React from 'react';
import './PHPDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function PHPDesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our PHP Website Development services include:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." /> */}
                            <h6>CUSTOM WEBSITE DEVELOPMENT</h6>
                            <p>One5 Digital creates tailor-made PHP websites from scratch, ensuring that every element is designed to reflect your brand identity and resonate with your target audience. The websites are built using clean code and best practices, resulting in fast loading times.</p>
                        </ul>
                        <ul className="smdservice-2">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h6>E-COMMERCE DEVELOPMENT</h6>
                            <p>If you're planning to launch an online store, One5 Digital can help you build a robust e-commerce platform using PHP. They integrate secure payment gateways, inventory management systems, and user-friendly interfaces, allowing you to manage your online business efficiently.</p>
                        </ul>
                        <ul className="smdservice-3">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h6>CONTENT MANAGEMENT SYSTEM</h6>
                            <p>One5 Digital specializes in developing CMS websites using PHP frameworks like WordPress, Joomla, and Drupal. These frameworks provide a user-friendly interface that enables you to update and manage your website content easily, even without technical knowledge.</p>
                        </ul>
                        <ul className="smdservice-4">
                            {/* <img src="./server_One5Digital.png" alt="Loading..." /> */}
                            <h6>API DEVELOPMENT</h6>
                            <p>One5 Digital has expertise in creating APIs that enable seamless integration of your website with external systems or third-party applications. This allows for enhanced functionality and data exchange, opening up new possibilities for your business.</p>
                        </ul>
                    </div>
                    <div style={{marginTop:'2rem'}}>
                        <p>One5 Digital's team of skilled PHP developers stays updated with the latest industry trends and best practices. They have extensive experience in PHP website development, enabling them to tackle complex projects with efficiency and precision. Their attention to detail, combined with their creativity, ensures that the websites they create are visually appealing, user-friendly, and optimized for search engines.</p>
                        <p>When you choose One5 Digital for your PHP website development needs, you can expect a seamless development process, clear communication, and timely project delivery. They prioritize client satisfaction and work closely with you throughout the project to ensure that your expectations are not only met but exceeded.</p>
                    </div>
                </div>
            </div>
            {/* <div className='smdesc'>
                <p className='smp'>By leveraging One5 Digital's social media advertising services, businesses can increase their brand's reach and engagement on social media, ultimately driving more traffic and sales to their website.</p>
                <p className='smp'>In conclusion, social media advertising has become essential to any successful digital marketing strategy. With One5 Digital's social media advertising services in Melbourne, businesses can create an effective social media marketing plan that helps them achieve their business objectives. So why wait? Contact One5 Digital today to boost your brand's online presence and reach your target audience on social media!</p>
            </div> */}
        </div>
    )
}

export default PHPDesc
import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import SHW_contact from '../SHW_contact';
import SHWabout from '../SHWabout';
import SHWabout2 from '../SHWabout2';
import SHWDesc from '../SHWDesc';
import SHWDesignDesc2 from '../SHWDesignDesc2';

function Static_HTML_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Efficient Static HTML Website Development Services by One5 Digital in Melbourne</title>
        <meta name="description" content="Discover One5 Digital's top-notch Static HTML Website Development services in Melbourne. Harness the power of static websites to create fast, secure, and engaging online experiences for your business." />
        <meta name="keywords" content="Static HTML Website Development, Melbourne, One5 Digital, Fast Websites, Secure Websites, Engaging User Experiences" />
      </Helmet>
      <SHWabout />
      <SHWabout2 />
      <SHWDesc />
      <SHWDesignDesc2 />
      <FeaturedProject />
      <Testimonials />
      <SHW_contact />
    </React.Fragment>
  )
}

export default Static_HTML_Website_Development
import React from 'react';
import './MCDesignDesc.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function MCDesignDesc() {
  return (
    <div className="MCDesign-about">
        <div data-aos='fade-up' className="left-side">
            <p>In fact, according to a recent report by Google, there was a 350% increase in phishing websites in 2020 alone. Therefore, it is essential to have reliable and professional malware removal services at your disposal.</p>
            <p>One5 Digital, based in Melbourne, offers comprehensive malicious code removal services to help protect your website from malware attacks. Our team of experts is dedicated to keeping your website secure by removing any malicious code that may have been injected into your website.</p>
        </div>
        <div className='right-side' style={{textAlign: 'center'}}>
            <img data-aos='fade-up' className="designimg" src="./malicious_code_desc_One5Digital.png" alt="Loading..." />
        </div>
    </div>
  )
}

export default MCDesignDesc
import React from 'react';
import './TMdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function TMdesc() {
    return (
        <div className="tmdesc">
            <div className="tmdesc-cat2">
                <div className="tmdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>LANGYS</h1>
                    <p>Langys is an established business that supplies Quality Tools and Equipment to Bricklayers, Concreters and Plasterers amongst other trades.
                    Langys  manufactures in house adjustable  Steel Trestles,(tested to AS1170)  Pudlogs, A-Frames, Profile Clamps,  Profile steady, adjustable profiles and Posthole cleaners.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Langys">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="tmdesc2-right">
                <img className="tmdesc2ri" src="./LangysProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default TMdesc

import React from 'react';
import './EBdetails.css';

function EBdetails() {
    return (
        <div className="ebdetails">
            <div className="left-ebdetails">
                <h1 data-aos='fade-up'>A MORDEN DIGITAL PRESENCE
                FOR THE WHOLESALER
                OF PET PRODUCTS</h1>
                <p data-aos='fade-up'>Essentially Brands is an Australian owned and operated family business. Armed with 50 years of experience they specialise in delivering top quality
                products for the Australian and export market. </p>
                <p data-aos='fade-up'>Australian owned and operated family business. Armed with 50 years of experience they specialise in 
                delivering top quality products for the Australian and export market. </p>
            </div>
        </div>
    )
}

export default EBdetails

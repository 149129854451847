import React from 'react';
import './SEODesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SEODesignDesc() {
    return (
        <div className="SEODesign-about">
            <div data-aos='fade-up' className="left-side">
                <p>One5 Digital is a leading digital marketing agency based in Melbourne that offers top-notch SEO services to businesses across different industries. Our team of SEO experts has years of experience in optimizing websites for better search engine rankings, increased traffic, and improved online credibility.</p>
                <p>Our SEO services in Melbourne are designed to cater to the unique needs of each business. We begin by thoroughly analyzing your website to identify areas that need improvement. Our team then develops a customized SEO strategy that includes keyword research, on-page optimization, off-page optimization, and technical SEO.</p>
            </div>
            <div className='right-side' style={{textAlign: 'center'}}>
                <img data-aos='fade-up' className="designimg" src="./seo_design_One5Digital.png" alt="Loading..." />
            </div>
        </div>
    )
}

export default SEODesignDesc
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './ARabout.css';
import { Link } from 'react-router-dom';

function ARabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'arabout'>
            <div className = 'arabout-container'>
				<div className = 'araboutcol-full'>
                    <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">ACCORD RETAIL</h1>
                </div>
                <div className = 'araboutcol-left'>
                    
					<h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'arabout-desc'>Accord Retail is a fresh alternative to the large National Commercial Real Estate Agencies offering over 25 years of Retail 
                    & Commercial Leasing and Sales across every state and territory in Australia. We are all about being of service to our clients to deliver new shopping centres fully leased, 
                    on time, within budget and to plan with the optimum mix of Retailers.</p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2020</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='arabout-btn'>
                        <a href="https://accordretail.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="araboutcol-right">
                    <img className="arimg" src= "./AccordAbout_One5Digital.png" alt= "Loading..." />
                    <img className="arimg2" src= "./AccordAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default ARabout

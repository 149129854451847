import React from 'react';
import './MWDabout2.css';

function MWDabout2() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./magento2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>One5 Digital understands that every business is unique and has specific requirements. Their team of experienced developers works closely with clients to understand their goals, brand identity, and target audience. By combining technical expertise with a deep understanding of e-commerce trends, they create tailor-made Magento websites that align with your business objectives.</p>
            <p>Whether you're starting from scratch or looking to upgrade your existing Magento store, One5 Digital provides comprehensive website development services. From strategic planning and design to development, integration, and optimization, their experts handle every aspect of your project with utmost professionalism and attention to detai</p>
            <p>One5 Digital takes pride in delivering high-quality Magento website development services in Melbourne. Their track record of successful projects and satisfied clients is a testament to their commitment to excellence. By choosing One5 Digital, you're partnering with a reliable and experienced team that understands the intricacies of Magento and can turn your vision into a reality.</p>
            <p>In today's competitive e-commerce landscape, a professionally developed Magento website can set your business apart from the crowd. Enhance your online presence, attract customers, and boost your revenue with One5 digital</p>
        </div>
    </div>
  )
}

export default MWDabout2
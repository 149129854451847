import React from 'react';
import './LDesignDesc2.css';

function LDesignDesc2() {
    return (
        <div className="LDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Logo Design Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>PERSONALIZED APPROACH </h6>
                            <p className="ec-desc">We understand that every business is unique, which is why we take a personalized approach to each logo design project.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>PROFESSIONAL DESIGNERS</h6>
                            <p className="ec-desc">With our experience in delivering what the client needs, we can build your Ecommerce website with no hassle.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>AFFORDABLE PRICING </h6>
                            <p className="ec-desc">We'll work with you to refine your logo design until you're completely satisfied.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>FAST TURNAROUND </h6>
                            <p className="ec-desc">Once your logo design is complete, we'll provide you with the necessary files in all relevant formats (e.g., PNG, JPEG, PDF, vector files) for you to use on your website, social media, business cards, and other marketing materials.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>ONGOING SUPPORT AND MAINTENANCE </h6>
                            <p className="ec-desc">We want you to be completely satisfied with your logo design, which is why we offer unlimited revisions until you're happy with the final product.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LDesignDesc2
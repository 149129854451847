import React from 'react';
import '../App.css';
import ServicePage from '../ServicePage';
import Getintouch from '../Getintouch';
import {Helmet} from "react-helmet";

function Services() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Web, Designing & Software Development Services | One5</title>
				<meta name="description" content="Looking for the best web design, digital marketing and software development services? One5 offers a wide range of services, including web design, SEO, PPC, e-commerce, software development and more." />
				<meta name="keywords" content="Web Development Services, Designing Services, Software Development Services, Digital Marketing Services, E-Commerce Services, Pay Per Click(PPC) Services, SEO Services, One5" />
			</Helmet>
            <ServicePage />
            <Getintouch />
        </React.Fragment>
    )
}

export default Services;

import React from 'react';
import './EBdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function EBdesc() {
    return (
        <div className="ebdesc">
            <div className="ebdesc-cat">
                <div data-aos='fade-up' className="ebdesc-left">
                    <img className="ebdescri" src="./cookie_One5Digital.png" alt="Loading..." />
                </div>
                <div className="ebdesc-right">
                    <h1 data-aos='fade-up'>RESULTS</h1>
                    <p data-aos='fade-up'>Essentially Brands is an Australian owned and operated family 
                    business. Armed with 50 years of experience they specialise in
                    delivering top quality products for the Australian and export market.</p><br/>
                    <p data-aos='fade-up'>Armed with 50 years of experience they specialise in delivering top
                    quality products for the Australian and export market.</p>
                </div>
            </div>
            <div className="ebdesc-cat2">
                <div className="ebdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>ACCORD RETAIL</h1>
                    <p data-aos='fade-up'> Accord Retail is a fresh alternative to the large National Commercial Real Estate Agencies offering over 25 years of Retail 
                    & Commercial Leasing and Sales across every state and territory in Australia.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Accord_Retail">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="ebdesc2-right">
                <img className="ebdesc2ri" src="./AccordProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default EBdesc

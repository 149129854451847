/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './SDabout.css';

function SDabout() {
  return (
    <div className='sdabout'>
      <div className='sdabout-container'>
        <div className='sdaboutcol-left'>
          <h1>Shopify stores <br />that are <span style={{ color: '#F04E31' }} >built to last</span></h1>
          <h3>For future-focused brands growing internationally.</h3>
          <p className='sdabout-desc'>Flexibility and scalability are just two of the benefits of being a Shopify store owner. Whatever your long-term business goals, we develop Shopify and Shopify Plus stores that stand the test of time. We can match your brand with the right Shopify developer for your business, based on your needs, size and budget.</p>
        </div>
        <div className="sdaboutcol-right">
          <img src="./One5_Digital_Shopify_Partner_One5Digital.png" alt="Loading..." className='ssimg'/>
        </div>
      </div>
    </div>
  )
}

export default SDabout
import React from 'react';
import PPCabout from '../PPCabout';
import PPCsteps from '../PPCsteps';
import Getintouch from '../Getintouch';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';

function Pay_Per_Click_Melbourne() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Best PPC Service Company in Melbourne | One5</title>
				<meta name="description" content="One5 is the best PPC (Pay Per Click) service company in Melbourne, Australia. We offer lead-based PPC (pay per click) campaign management and advertising services at the best price in Melbourne." />
				<meta name="keywords" content="PPC, PPC Service, Best PPC Service, Pay Per Click Advertising Agency, Pay Per Click, Pay Per Click Management, PPC Marketing Melbourne, Best PPC Company in Australia, Best Pay Per Click Company in Australia, PPC Company in Australia, Pay Per Click Company in Australia, PPC Agency Melbourne, Pay Per Click Company Melbourne, PPC Company Melbourne" />
			</Helmet>
            <PPCabout />
            <PPCsteps />
            <FeaturedProject />
            <Testimonials />
            <Getintouch />
        </React.Fragment>
    )
}

export default Pay_Per_Click_Melbourne

import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

  const MenuLabel = styled.label`
    background-color: transparent;
    position: fixed;
    top: 0rem;
    right: 3rem;
    height: 6rem;
    width: 7rem;
    cursor: pointer;
    z-index: 1000;
    text-align: center;
  `;
  

  const Icon = styled.span`
    position: relative;
    background-color: ${(props) => (props.clicked ? "transparent" : "#fff")};
    width: 2rem;
    height: 2px;
    display: inline-block;
    margin-top: 3rem;
    transform: scaleX(-1);
    transition: all 0.3s;
    &::before{
      content: "";
      background-color: ${(props) => (props.clicked ? "#000" : "#fff")};
      width: 2.5rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::after {
      content: "";
      background-color: ${(props) => (props.clicked ? "#000" : "#fff")};
      width: 2.5rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::before {
      top: ${(props) => (props.clicked ? "0" : "-0.8rem")};
      transform: ${(props) => (props.clicked ? "rotate(45deg)" : "rotate(0)")};
    }
    &::after {
      top: ${(props) => (props.clicked ? "0" : "0.8rem")};
      transform: ${(props) => (props.clicked ? "rotate(-45deg)" : "rotate(0)")};
    }
    
    &.clicked {
    }
    ${MenuLabel}:hover &::before {
      top: ${(props) => (props.clicked ? "0" : "-1rem")};
    }
    ${MenuLabel}:hover &::after {
      top: ${(props) => (props.clicked ? "0" : "1rem")};
    }
  `;
  
  const Navigation = styled.nav`
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 600;
    width: ${(props) => (props.clicked ? "100%" : "0")};
    opacity: ${(props) => (props.clicked ? "1" : "0")};
    transition: all 0.5s ease;
    background: #fff;
  `;
  
  const List = styled.ul`
    position: absolute;
    list-style: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  `;

function Ham() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (
        <div className="nav">
            <MenuLabel htmlFor="navi-toggle" onClick={handleClick} className="navi-toggle">
                <Icon clicked={click} className="bar">&nbsp;</Icon>
                {/* <div className="nav-btn" clicked={click}>
                  <div class="text">
                    <span>M</span>
                    <span>E</span>
                    <span>N</span>
                    <span>U</span>
                  </div>
                  <div class="action">
                    <div class="bar"></div>
                    <div class="bar"></div>
                  </div>
                </div> */}
            </MenuLabel>

            <Navigation  clicked={click}>
                <p className="navspan">Menu</p>
                <List>
                    <li className="nav-items">
                        <NavLink  onClick={handleClick} exact to="/" className="nav-items" activeClassName="nav-item-active">
                            Home
                        </NavLink>
                    </li>
                    <li className="nav-items">
                        <div className="subnav">
                            <NavLink  onClick={handleClick} exact to="/Projects" className="nav-items" activeClassName="nav-item-active">
                                Projects
                            </NavLink>
                        </div>
                    </li>
                    <li className="nav-items">
                        <NavLink  onClick={handleClick} exact to="/SoftwareDesign" className="nav-items" activeClassName="nav-item-active">
                            Software Design
                        </NavLink>
                    </li>
                    <li className="nav-items">
                        <NavLink  onClick={handleClick} exact to="/Services" className="nav-items" activeClassName="nav-item-active">
                            Services
                        </NavLink>
                    </li>
                    <li className="nav-items">
                        <NavLink  onClick={handleClick} exact to="/About" className="nav-items" activeClassName="nav-item-active">
                            About
                        </NavLink>
                    </li>
                    <li className="nav-items">
                        <NavLink  onClick={handleClick} exact to="/Contact" className="nav-items" activeClassName="nav-item-active">
                            Contact
                        </NavLink>
                    </li>
                </List>
                {/* <div className="container-contentLink">
                  <div className="wrapper-el">
                    <div className="el el-projects">
                      <ul className="">
                        <li className="link-accord"><Link>ACCORD RETAIL</Link></li>
                        <li className="link-bayside"><Link>BAYSIDE SKIN & LASER</Link></li>
                        <li className="link-cleanpower"><Link>CLEAN POWER CO.</Link></li>
                        <li className="link-drgreen"><Link>DR GREEN COSMETIC GROUP</Link></li>
                        <li className="link-essentially"><Link>ESSENTIALLY BRANDS</Link></li>
                        <li className="link-hardware"><Link>HARDWARE JOURNAL</Link></li>
                        <li className="link-mazcon"><Link>MAZCON EXCAVATION</Link></li>
                        <li className="link-tiffnay"><Link>MISS TIFFANY SHEN</Link></li>
                        <li className="link-motor"><Link>MOTOR COMPONENTS</Link></li>
                        <li className="link-paul"><Link>PAUL RYAN PLUMBING & CONTRACTING</Link></li>
                        <li className="link-rollon"><Link>ROLLON PAINTING & DECORATING</Link></li>
                        <li className="link-ps4x4"><Link>PS4X4</Link></li>
                        <li className="link-stagedhomes"><Link>STAGED HOMES BY MELISSA</Link></li>
                        <li className="link-grandband"><Link>THE GRAND BAND</Link></li>
                      </ul>
                    </div>
                  </div>
                </div> */}
            </Navigation>
        </div>
    )
}

export default Ham
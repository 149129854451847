import React from 'react';
import './NTdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function NTdesc() {
    return (
        <div className="ntdesc">
            <div className="ntdesc-cat2">
                <div className="ntdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>CLEAN POWER CO</h1>
                    <p>Clean Power Co created by long term business partners who have been in the industry since 2009 working for a number of today’s leading solar companies. </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Clean_Power_Co">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="ntdesc2-right">
                <img className="ntdesc2ri" src="./CleanpowerProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default NTdesc

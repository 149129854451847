import React from 'react';
import EBabout from '../EBabout';
import EBdetails from '../EBdetails';
import EBexp from '../EBexp';
import EBpro from '../EBpro';
import EBdesc from '../EBdesc';
import {Helmet} from "react-helmet";

function Essentially_Brands() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Essentially Brands Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Essentially Brands Projects help businesses build, grow and establish a clear and consistent brand image across all channels." />
            <meta name="keywords" content="Essentially Brands, Essentially Brands Project, One5's Essentially Brands Project" />
        </Helmet>
            <EBabout />
            <EBdetails />
            <EBexp />
            <EBpro />
            <EBdesc />
        </React.Fragment>
    )
}

export default Essentially_Brands

import React from 'react';
import PMabout from '../PMabout';
import PMdesc from '../PMdesc';
import PMexp from '../PMexp';
import PMpro from '../PMpro';
import { Helmet } from "react-helmet";

function Purity_Plumbing() {
  return (
    <React.Fragment>
        <Helmet>
            <title>Purity Plumbing | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Axlefix project provides mechanical repairs, servicing and maintenance for all vehicle types. Visit us online for more information or to book a service." />
            <meta name="keywords" content="Axlefix, Axlefix Project, One5's Axlefix Project" />
        </Helmet>
        <PMabout />
        <PMexp />
        <PMpro />
        <PMdesc />
    </React.Fragment>
  )
}

export default Purity_Plumbing
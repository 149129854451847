import React from 'react';
import './CRexp.css';

function CRexp() {
  return (
    <div className="crexp">
        <div>
            <h1>HOMEPAGE</h1>
            <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Campus Radar is job portal so we design the Website to make it simple and easy to use. Visit website for more look.</p>
        </div>
        <div>
            {/* <video className="mg-video" autoPlay loop muted>
                <source src="./MotorComponent_One5Digital.mp4" type="video/mp4" />
            </video> */}
            <img src="./CampusRadar_One5Digital.gif" alt= "Loading..." className="cr-video"  />
        </div>
    </div>
  )
}

export default CRexp 
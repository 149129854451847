import React from 'react';
import './AboutPage.css';

function AboutPage() {
    return (
        <div className="about-page">
            <div className="ab-desc">
                <div className="about-left">
                    <h1 data-aos='fade-up' data-aos-duration="500" >WE ARE ONE5<span style={{color: '#F04E31'}} > DIGITAL </span></h1>
                    <p data-aos='fade-up' data-aos-duration="1500">We are a digital marketing agency based in Melbourne, Australia, working internationally.</p> <br/>
                    <p data-aos='fade-up' data-aos-duration="2000">We believe that creating memorable experiences are the best way to connect with your consumers. </p> <br/>
                    <p data-aos='fade-up' data-aos-duration="2500">From dynamic web designs to cutting-edge digital marketing strategies, we believe that the custom solutions we create today will transcend the trends of tomorrow.</p> <br/>
                    <p data-aos='fade-up' data-aos-duration="3000">No matter your product or service, you have a story to tell.</p>
                </div>
            </div>
            <div className="about-right">
                <img className="vector" src="./vector_One5Digital.svg" alt="One5 Digital Agency" /> 
            </div>
        </div>
    )
}

export default AboutPage;

import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import EComWDAbout from '../EComWDAbout';
import EComWDDesc from '../EComWDDesc';
import EComWD_contact from '../EComWD_contact';

function ECommerce_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Enhance Your Online Business with One5 Digital's E-Commerce Website Development Services in Melbourne</title>
        <meta name="description" content="Looking for top-notch e-commerce website development services in Melbourne? Discover how One5 Digital can help you create a robust and user-friendly online store that drives sales and boosts your business. Contact us today!" />
        <meta name="keywords" content="e-commerce website development, online store, web development services, Melbourne, One5 Digital" />
      </Helmet>
      <EComWDAbout />
      <EComWDDesc />
      <FeaturedProject />
      <Testimonials />
      <EComWD_contact />
    </React.Fragment>
  )
}

export default ECommerce_Website_Development
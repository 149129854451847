import React from 'react';
import './PPCabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function PPCabout() {
    return (
        <div className="ppcabout">
            <div className="ppc-about">
                <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link>
                <div className="ppc-desc">
                    <h1>PAY PER CLICK (PPC)</h1>
                    <p>At One5 Digital, our approach focuses on conversion that matter. </p><br/>
                    <p>Whether you are an commerce business looking to drive online sales or a trade 
                    person business seeking more leads , One 5 digital can tailor a Paid Search 
                    Campaign that targets the buyer your are looking for.</p>
                    <div className='ppcabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default PPCabout

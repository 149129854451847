import React from 'react';
import DGabout from '../DGabout';
import DGdesc from '../DGdesc';
import DGexp from '../DGexp';
import DGpro from '../DGpro';
import {Helmet} from "react-helmet";

export default function Dr_Green_Cosmetic_Group() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Dr Green Cosmetic Group Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Dr. Green Cosmetic Group's project is beauty solutions. From cosmetic injections to facelifts, we help you look and feel your best. Book your appointment now." />
            <meta name="keywords" content="Dr. Green Cosmetic Group's, Dr. Green Cosmetic Group's project, One5's Dr. Green Cosmetic Group's project" />
        </Helmet>
            <DGabout />
            <DGexp />
            <DGpro />
            <DGdesc />
        </React.Fragment>
    )
}

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './SDproject.css';
import { Button } from './Button';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

function SDproject() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    window.onload= function(){
        const videos = document.querySelectorAll("video")
        videos.forEach(video => {
            video.addEventListener("mouseover", function () {
                this.play()
            })
            
            video.addEventListener("mouseleave", function () {
                this.pause()
            })
            
            video.addEventListener("touchstart", function () {
                this.play()
            })
            
            video.addEventListener("touchend", function () {
                this.pause()
            })
        })
    }

    return (
        <div className="project">
            <div>
                <h1 data-aos='fade-up' data-aos-duration="500" >OUR SHOPIFY PROJECTS </h1>
                <p data-aos='fade-up' data-aos-duration="1500" >We offer highly innovative design for the web and mobile.
                Right from user interfaces to complete campaigns, our approach to design stems 
                from a vision for the perfect marriage of aesthetics and functionality.</p>
            </div>
            <div className="pro-wrapper">
                <div className="prorow prorow-1">
                    <ul className="prorow1-items">
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Amazing_Amazon" className="pro1">
                            <img className="proimg" src="./AmazingAmazonProject_One5Digital.png" />
                            <img className="imgpro" src="./AmazingAmazonProjectHover_One5Digital.png" />
                            </Link>
                            <h6>AMAZING AMAZON</h6>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="2000" className="projli">    
                            <Link exact to="/Projects/Ausport_Superstore" className="pro1">                     
                            <img className="proimg" src="./AusportProject_One5Digital.png" />
                            <img className="imgpro" src="./AusportProjectHover_One5Digital.png" />
                            </Link>
                            <h6>AUSPORT</h6>
                        </li>            
                        <li data-aos='fade-up'  data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Dr_Green_Shop" className="pro1">
                            <img className="proimg" src="./DrGreenShopProject_One5Digital.png" />
                            <img className="imgpro" src="./DrGreenShopProjectHover_One5Digital.png" />
                            </Link>
                            <h6>DR GREEN COSMETIC SHOP</h6>
                        </li>   
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Langys" className="pro1">
                            <img className="proimg" src="./LangysProject_One5Digital.png" />
                            <img className="imgpro" src="./LangysProjectHover_One5Digital.png" />
                            </Link>
                            <h6>LANGYS</h6>
                        </li>          
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Motor_Components" className="pro1"> 
                            <img className="proimg" src="./MotorComponentProject_One5Digital.png" />
                            <img className="imgpro" src="./MotorComponentProjectHover_One5Digital.png" />
                            </Link>
                            <h6>MOTOR COMPONENTS</h6>
                        </li>            
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Natural_By_Tara" className="pro1">
                            <img className="proimg" src="./NatureByTaraProject_One5Digital.png" />
                            <img className="imgpro" src="./NatureByTaraProjectHover_One5Digital.png" />
                            </Link>
                            <h6>NATURAL BY TARA</h6>
                        </li>       
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/PokeNPop_Collect" className="pro1">
                            <img className="proimg" src="./PokenPopProject_One5Digital.png" />
                            <img className="imgpro" src="./PokenPopProjectHover_One5Digital.png" />
                            </Link>
                            <h6>POKE N POP COLLECT</h6>
                        </li>     
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Super_Star_Boards" className="pro1">
                            <img className="proimg" src="./SSBProject_One5Digital.png" />
                            <img className="imgpro" src="./SSBProjectHover_One5Digital.png" />
                            </Link>
                            <h6>SUPER STAR BOARDS</h6>
                        </li>       
                        <li data-aos='fade-up' className="projli">                 
                            <Link exact to="/Projects/Venus_Virgin_Tomarz" className="pro1">                                      
                            <img className="proimg" src="./VenusVirginProject_One5Digital.png" />
                            <img className="imgpro" src="./VenusVirginProjectHover_One5Digital.png" />
                            </Link>
                            <h6>VENUS VIRGIN TOMARZ</h6>
                        </li>     
                        <li data-aos='fade-up' className="projli yourpromain">
                            <div className="yourpro">
                                <div><h4>YOUR <br/> SHOPIFY PROJECT <br/> HERE?</h4></div>
                                <div className='service-btn'>
                                    <Link excat to="/Contact_One5_Digital">
                                        <Button className='btn' buttonStyle='btn--white' >Contact Us</Button>
                                    </Link>
                                </div>
                            </div>
                            <h6 style={{ marginTop: '28px' }}>YOUR COMPANY</h6>
                        </li>
                        {/* <li>
                            <img className="proimg" src="./essentiallybrands.png" />
                            <h6>PROJECT:<p>ESSENTIALLY BRANDS</p></h6>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SDproject

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './SSabout.css';
import { Link } from 'react-router-dom';

function SSabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'ssabout'>
            <div className = 'ssabout-container'>
				<div className = 'ssaboutcol-full'>
                    <Link data-aos='fade-up' data-aos-duration="1500" exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1 data-aos='fade-up' data-aos-duration="1500">SUPER STAR BOARDS</h1>
                </div>
                <div className = 'ssaboutcol-left'>
					<h3 data-aos='fade-up' data-aos-duration="1500">ABOUT CLIENT</h3>
                    <p data-aos='fade-up' data-aos-duration="1500" className = 'ssabout-desc'>Super Star Boards have all had a poster of our heroes on our wall, whether it be Buddy, Billy Slater or Ellyse Perry. Now YOU can give the gift of daring to dream by 
                    creating a Superstar Board for your kid, grandchild... or big kid. It even makes a great MVP or Coaches Award!</p>
                    <h3 data-aos='fade-up' data-aos-duration="1500">SERVICE</h3>
                    <div data-aos='fade-up' data-aos-duration="1500" className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 data-aos='fade-up' data-aos-duration="1500" className="year">YEAR</h3>
                    <p data-aos='fade-up' data-aos-duration="1500">2022</p>
                    <div data-aos='fade-up' data-aos-duration="1500" className='ssabout-btn'>
                        <a href="https://superstarboards.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div data-aos='fade-up' data-aos-duration="1500" className="ssaboutcol-right">
                    <img className="ssbimg" src= "./SSBAbout_One5Digital.png" alt= "Loading..." />
                    <img className="ssbimg2" src= "./SSBAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default SSabout

import React from 'react';
import './Benefits.css';

function Benefits() {
    return (
        <div className="benefits">
            <div>
                <h1 data-aos='fade-up'>BENEFITS OF CUSTOM<br/> 
                SOFTWARE DEVELOPMENT</h1>
                <p data-aos='fade-up'>A distinctive feature of custom software is that, because it is designed to achieve
                 unique goals, it is usually created from scratch and does not have analogous 
                 applications on the market.</p>
            </div>
            <div className="benefit-wrapper">
                <ul data-aos='fade-up' className="benefit-1"> 
                    <div className="bene-visible">
                        <img src= "./optimized-main_One5Digital.png" alt= "Optimised Business Process Front Image" />
                    </div>
                    <div className="bene-desc">
                        <img className="bene-hover" src="./optimized_One5Digital.png" alt="Optimised Business Process Back Image" />
                    </div>
                </ul>
                <ul data-aos='fade-up' className="benefit-2">
                    <div className="bene-visible">
                        <img src= "./ownership-main_One5Digital.png" alt= "Full Ownership Front Image" />
                    </div>
                    <div className="bene-desc">
                        <img className="bene-hover" src="./ownership_One5Digital.png" alt="Full Ownership Back Image" />
                    </div>
                </ul>
                <ul data-aos='fade-up' className="benefit-3">
                    <div className="bene-visible">
                        <img src= "./flexibility-main_One5Digital.png" alt= "Full Flexibility Front Image" />
                    </div>
                    <div className="bene-desc">
                        <img className="bene-hover" src="./flexibility_One5Digital.png" alt="Full Flexibility Back Image" />
                    </div>
                </ul>
                <ul data-aos='fade-up' className="benefit-4">
                    <div className="bene-visible">
                        <img src= "./integration-main_One5Digital.png" alt= "Fast Integration Front Image" />
                    </div>
                    <div className="bene-desc">
                        <img className="bene-hover" src="./integration_One5Digital.png" alt="Fast Integration Back Image" />
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Benefits

import React from 'react';
import './SEOabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SEOabout() {
    return (
        <div className="seoabout">
            <div className="seo-about">
                <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link>
                <div className="seo-desc">
                    <h1>SEARCH ENGINE OPTIMIZATION (SEO)</h1>
                    <p>A word that we all have heard, but very few understand the process to deliver the best results.
                    The organic SEO is the best approach to any company looking for a long term investment with fruitful return.</p><br/>
                    <p>We will help drive more business to your website to sell more of your products and services! Start utilizing online marketing strategies that work.
                    </p><br/>
                    <p>Below are some steps to deliver the results.
                    </p>
                    <div className='seoabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default SEOabout

import React from 'react';
import './MWDabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function MWDabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>MAGENTO WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's Magento Website Development Service!</h6>
                {/* <p>Boost Your Online Business with One5 Digital's Woo Commerce Website Development Services in Melbourne</p> */}
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>In today's digital world, having a robust and visually appealing online presence is crucial for any business. When it comes to e-commerce, an optimized website can make all the difference in attracting customers and driving sales. If you're based in Melbourne and seeking top-notch Magento website development services, One5 Digital is here to provide you with professional solutions tailored to your needs.</p>
                        <p className='smp'>Magento, known for its versatility and scalability, is a leading e-commerce platform trusted by businesses of all sizes worldwide. One5 Digital specializes in leveraging the power of Magento to create captivating online stores that engage customers and deliver exceptional user experiences. With their expertise in Magento website development, they help businesses unlock their full e-commerce potential.</p>
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./magento_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MWDabout

import React from 'react';
import AUabout from '../AUabout';
import AUdesc from '../AUdesc';
import AUexp from '../AUexp';
import AUpro from '../AUpro';
import {Helmet} from "react-helmet";

function Ausport_Superstore() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Ausport Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's helped Miss Australia Tiffany Shen with her online presence. Read about this and more of our successful projects on our website." />
            <meta name="keywords" content="Ausport Superstore, Ausport Superstore Project, One5's Ausport Superstore project" />
        </Helmet>
            <AUabout />
            <AUexp />
            <AUpro />
            <AUdesc />
        </React.Fragment>
    )
}

export default Ausport_Superstore

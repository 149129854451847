import React from 'react';
import './LSexp.css';

function LSexp() {
    return (
        <div className="lsexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Langys' has simple and easy to use website design to the users. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="ls-video" autoPlay loop muted>
                    <source src="./HardwareJournal_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Langys_One5Digital.gif" alt= "Loading..." className="ls-video"  />
            </div>
        </div>
    )
}

export default LSexp
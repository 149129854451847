import React from 'react';
import './VDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function VDesignAbout() {
    return (
        <div className="VDesignabout">
            <div data-aos='fade-up' className="left-side">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>VISUAL DESIGN</h1>
                <h6>Welcome to One5 Digital's Visual Design Service!</h6>
                <p>As the digital era keeps advancing, having a solid visual identity has become more critical than ever for businesses. It's not just about creating a visually appealing logo or website; it's about crafting a cohesive and memorable brand that resonates with your target audience. That's where One5 Digital comes in.</p>
                <p>Based in Melbourne, One5 Digital is a digital marketing agency that specializes in visual identity design services. Their team of experienced designers and branding experts work closely with clients to create custom visual identities that align with their brand's values, personality, and goals.</p>
                <div className='ldabout-btn'>
                    <Link excat to="/Contact_One5_Digital">
                        <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                    </Link>
                </div>
            </div>
            <div className='right-side' style={{textAlign: 'center'}}>
                <img data-aos='fade-up' className="designimg" src="./visual_design_One5Digital.png" alt="Loading..." />
            </div>
        </div>
    )
}

export default VDesignAbout

import React from 'react';
import MCDesignAbout from '../MCDesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import MCDesign_contact from '../MCDesign_contact';
import MCDesignDesc from '../MCDesignDesc';
import FeaturedProject from '../FeaturedProject';
import MCDesignDesc2 from '../MCDesignDesc2';
import MCDesignDesc3 from '../MCDesignDesc3';

function Malicious_Code_Removal() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Keep Your Website Secure with One5 Digital's Malicious Code Removal Services in Melbourne</title>
				<meta name="description" content="Protect your website from malicious code with One5 Digital's professional and reliable malware removal services in Melbourne. Contact us now to secure your online presence!" />
				<meta name="keywords" content="Malicious code removal services, One5 Digital, Melbourne, website security, malware removal" />
			</Helmet>
            <MCDesignAbout />
            <MCDesignDesc />
            <MCDesignDesc2 />
            <MCDesignDesc3 />
            <FeaturedProject />
            <Testimonials />
            <MCDesign_contact />
        </React.Fragment>
    )
}

export default Malicious_Code_Removal
import React from 'react';
import './SLDesignDesc.css';
// import { Button } from './Button';
import { BsPaperclip } from "react-icons/bs";

function SLDesignDesc() {
  return (
    <div className="SLDesign-about">
        <div data-aos='fade-up' className="left-side">
            <p>SSL Certification is a security protocol that provides a secure, encrypted connection between a website and its visitors. This protocol ensures that any information exchanged between the website and the visitor is protected and cannot be accessed by any unauthorized parties.</p>
            <p>One5 Digital's team of experts in Melbourne are highly skilled in SSL Certification installation and can help ensure that your website is safe and secure for your visitors. With our SSL Certification installation services, we take care of the entire installation process, from obtaining the SSL Certificate to configuring it on your website.</p>
        </div>
        <div className='right-side' style={{textAlign: 'center'}}>
            <img data-aos='fade-up' className="designimg" src="./ssl_desc_One5Digital.png" alt="Loading..." />
        </div>
    </div>
  )
}

export default SLDesignDesc
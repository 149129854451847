import React from 'react';
import './RNDesignAbout.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function RNDesignAbout() {
    return (
        <div className="RNDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>RED NOTICE WEBSITE HACKED RETRIEVAL</h1>
                    <h6>One5 Digital Offers Red Notice Website Hacked Retrieval Services in Melbourne</h6>
                    <p>The internet has revolutionized our lives, from shopping to banking and even communication. However, with this increased convenience comes an increased risk of cyber-attacks. Cybercriminals are constantly looking for vulnerabilities in websites and online platforms to exploit for their gain. This is where One5 Digital comes in.
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RNDesignAbout
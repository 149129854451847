import React from 'react';
import './AAdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function AAdesc() {
    return (
        <div className="aadesc">
            <div className="aadesc-cat2">
                <div className="aadesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>ESSENTIALLY BRANDS</h1>
                    <p>We will help you to organise and manage all your sales, stock, accounting, shipping and customer data from single place.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Essentially_Brands">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="aadesc2-right">
                <img className="aadesc2ri" src="./EssentiallyBrandsProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default AAdesc

import React from 'react';
import './PPdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function PPdesc() {
    return (
        <div className="ppdesc">
            <div className="ppdesc-cat2">
                <div className="ppdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>THE GRAND BAND</h1>
                    <p> The Grand Band prides itself as something more than just ‘a band’ – a dignified and awe inspiring ensemble of remarkable musicians tasked to deliver 
                        a fun, energetic and passionate performance at your functions! Guided by their mission to turn any event into a ‘Grand Event’. </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/The_Grand_Band">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="ppdesc2-right">
                <img className="ppdesc2ri" src="./GrandBandProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default PPdesc

import React from 'react';
import './WDabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function WDabout() {
    return (
        <div className="wdabout">
            <div className="wd-about">
                <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link>
                <div className="wd-desc">
                    <h1>WEB DESIGN</h1>
                    <p className="wdp">When we build you a website, not only are we responsible for you a modern , updated 
                        we are responsible for making that website a sales tool for your business. </p>
                    <p>Through intense research and measurement, we design your site so that causal visitors become your customers. </p>
                    <div className='wdabout-btn'>
                        <Link excat to="/Contact">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default WDabout

import React from 'react';
import './LDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function LDesignDesc() {
    return (
        <div className="LDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our logo design service includes:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>CREATIVE CONSULTATION</h6>
                            <p data-aos='fade-up' className="service-desc">We'll start with a consultation to learn more about your business, target audience, and overall design preferences.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>CUSTOM DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">Our experienced designers will create a custom logo that reflects your brand identity, using the latest design trends and techniques.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>REVISIONS</h6>
                            <p data-aos='fade-up' className="service-desc">We'll work with you to refine your logo design until you're completely satisfied.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>FINAL FILES</h6>
                            <p data-aos='fade-up' className="service-desc">Once your logo design is complete, we'll provide you with the necessary files in all relevant formats (e.g., PNG, JPEG, PDF, vector files) for you to use on your website, social media, business cards, and other marketing materials.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LDesignDesc
import React from 'react';
import './Approach.css';

function Approach() {
    return (
        <div className="approach">
            <div className="app-desc">
                <div className="app-left">
                    <h1 data-aos='fade-up'>OUR STEP BY STEP 
                    APPROACH TO GROW
                    YOUR BUSINESS ONLINE</h1>
                </div>
                <div className="app-right">
                    <ul data-aos='fade-up' className="app-list scrollbar-warning">
                        <li className="app-items">
                            <h4><span style={{color: '#F04E31'}} >01.</span> Real Quality Traffic</h4>
                            <p className="item-1">We combine different source and strategic approaches to <br/> drive quality traffic.
                            Best results are a combination of <br/> Search Engine Optimisation (SEO), pay per
                            Click (PPC),<br/> Email marketing, Target display advertising & Social<br/> Media campaigns.</p>
                        </li>
                        <li className="app-items">
                            <h4><span style={{color: '#F04E31'}} >02.</span> Convert Visitors into Leads & Customers</h4>
                            <p className="item-2">This is a very important part of the sales cycle since, without this,<br/> million dollar marketing doesn't mean anything.<br/> Through competitive analysis we identify your brand <br/>
                            position in the market. We use that information to design<br/> engaging solutions, that 
                            ultimately helps users to <br/>become customers.</p>
                        </li>
                        <li className="app-items">
                            <h4><span style={{color: '#F04E31'}} >03.</span> Generate Brand Loyalty</h4>
                            <p className="item-3">We use Social media and Email marketing to create a <br/> continuous flow of visitors 
                            and customers coming back <br/> to your site. This helps to a trusting relationship 
                            between<br/> your customers and you. Trust builds brand.</p>
                        </li>
                        <li className="app-items">
                            <h4><span style={{color: '#F04E31'}} >04.</span> Get Repeat Business</h4>
                            <p className="item-4">Creating a customer-focused business environment helps<br/> the company to focus on 
                            delivering results. These helps<br/> to ensure trust and using marketing tools we can re-engage <br/>
                            with customers who are already familiar with your business.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Approach;

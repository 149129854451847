import React from 'react';
import './PRexp.css';

function PRexp() {
    return (
        <div className="prexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Paul Ryan Plumbing & Contracting website design has all the required details to the customers in easy to get way. Visit website for more look. </p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="pr-video" autoPlay loop muted>
                    <source src="./PaulRyan_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./PaulRyan_One5Digital.gif" alt= "Loading..." className="pr-video"  />
            </div>
        </div> 
    )
}

export default PRexp

import React from 'react';
import './DSdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function DSdesc() {
  return (
    <div className="dsdesc">
        <div className="dsdesc-cat2">
            <div className="dsdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                <h1>AMAZING AMAZON</h1>
                <p>We pride ourselves on our expertise with Reptiles, Aquarium and Birds. We are a specialist shop that does not sell Dogs or Cats.
                    Amazing Amazon is the premier Reptile shop in Melbourne having over 400 square metres of reptiles, aquariums and accessories.</p>
                <div className='project-btn'>
                    <Link exact to="/Projects/Amazing_Amazon">
                        <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                    </Link>
                </div>
            </div>
            <div className="dsdesc2-right">
                <img className="dsdesc2ri" src="./AmazingAmazonProject_One5Digital.png" alt="Loading..." />
            </div>
        </div>
    </div>
  )
}

export default DSdesc
import React from 'react';
import './SSexp.css';

function SSexp() {
    return (
        <div className="ssexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Super Star Boards is the Ultimate Personalised gift for Sporting SuperStars. For Super Star Boards we created eye catching varient colors for design and also we create boards for them. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="ss-video" autoPlay loop muted>
                    <source src="./Samantha_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./SSB_One5Digital.gif" alt= "Loading..." className="ss-video"  />
            </div>
        </div>
    )
}

export default SSexp

import React from 'react';
import './BrandingAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function BrandingAbout() {
    return (
        <div className="Brandingabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>BRANDING</h1>
                    <h6>Welcome to One5 Digital's Branding Service!</h6>
                    <p>At One5 Digital, we understand the importance of branding in today's competitive business landscape. Your brand is your identity, and it's crucial to establish a strong brand identity that accurately reflects your business values, personality, and unique selling proposition. Our experienced branding experts specialize in creating custom branding strategies that help businesses stand out in their respective industries
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandingAbout

import React from 'react';
import './WPexp.css';

function WPexp() {
  return (
    <div className="wpexp">
        <div>
            <h1>HOMEPAGE</h1>
            <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Experience Outstanding Carpet Cleaning & Upholstery Cleaning. Website design is simple and easy to use. Visit website for more look.</p>
        </div>
        <div>
            {/* <video className="wp-video" autoPlay loop muted>
                <source src="./MotorComponent_One5Digital.mp4" type="video/mp4" />
            </video> */}
            <img src="./WilkinsonPropertyService_One5Digital.gif" alt= "Loading..." className="wp-video"  />
        </div>
    </div>
  )
}

export default WPexp
import React from 'react';
import './BDdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function BDdesc() {
    return (
        <div className="bddesc">
            <div className="bddesc-cat2">
                <div className="bddesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>SAMANTHA WILLIS</h1>
                    <p>Samantha Willis is a young Australian writer who lives in Perth, Western Australia, and Karaneshia is her first novel.
                    Up until she created the captivating characters of Karaneshia, Sam has been writing short stories of various themes. 
                    </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Samantha_J_Willis">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="bddesc2-right">
                <img className="bddesc2ri" src="./SamanthaProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default BDdesc

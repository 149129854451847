 /* eslint-env jquery */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './OurProjects.css';
import { Button } from './Button';
import { Link, NavLink } from 'react-router-dom';

function OurProjects() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    window.onload= function(){
        const videos = document.querySelectorAll("video")
        videos.forEach(video => {
            video.addEventListener("mouseover", function () {
                this.play()
            })
            
            video.addEventListener("mouseleave", function () {
                this.pause()
            })
            
            video.addEventListener("touchstart", function () {
                this.play()
            })
            
            video.addEventListener("touchend", function () {
                this.pause()
            })
        })
    }

    return(
    <div className="our-projects">
        <div className="desc">
            <p data-aos='fade-up' className = 'project-shortdesc' style={{ color: '#F04E31' }}>───── OUR PROJECTS</p>
            <div className="left-desc">
                <h1 data-aos='fade-up'>WE BUILD SOLUTIONS<br/> 
                WITH <span style={{color: '#F04E31'}} >CREATIVE </span> DESIGN</h1>
            </div>
            <div data-aos='fade-up' className="right-desc">
                <p className="projects-desc">Client success is measured by RESULTS. We have a great team to produce outstanding results.</p>
            </div>
        </div> 
        <div className="project-wrapper">
            <div className="project-1">
                <div className="p1-desc">
                    <h3 data-aos='fade-up'>Essentially Brands</h3>
                    <p data-aos='fade-up'>We will help you to organise and manage all your sales, stock, accounting, shipping and 
                            customer data from single site.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Essentially_Brands">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="p1-img">
                    <img src= "./EssentiallyBrandsHomepage_One5Digital.png" alt= "Essentially Brands Project"  className="pimg" />
                    <img src="./EssentiallyBrandsHomepageHover_One5Digital.gif" alt= "Essentially Brands Project" className="videos"  />
                    {/* <video className="videos" id="eb"  src="./EssentiallyBrandsHomepgaeHover_One5Digital.mp4" type="video/mp4" loop muted></video> */}
                </div>
            </div>
            <div className="project-2">
                <div data-aos='fade-up' className="p2-img">
                    <img src= "./AusportHomepage_One5Digital.png" alt= "Ausport Superstore Project"  className="pimg"/>
                    <img src="./AusportHomepageHover_One5Digital.gif" alt= "Ausport Superstore Project" className="videos"  />
                    {/* <video className="videos" id="aus" src="./AusportHomepageHover_One5Digital.mp4" type="video/mp4" loop muted></video> */}
                </div>
                <div className="p2-desc">
                    <h3 data-aos='fade-up'>Ausport Superstore</h3>
                    <p data-aos='fade-up'>Established in a small warehouse in 1964, Ausport SuperStore has grown steadily to become one the largest, 
                        specialist sporting goods retailers in Australia.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Ausport_Superstore">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="project-3">
                <div className="p3-desc">
                    <h3 data-aos='fade-up'>Tiffany Shen</h3>
                    <p data-aos='fade-up'>Miss Tiffany Shen is the proud winner of Miss Australia International 2020. This is a worldwide movement that recognizes and 
                        celebrates women whose voices strive to make a difference.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Miss_Australia_Tiffany_Shen">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="p3-img">
                    <img src= "./MissTiffanyHomepage_One5Digital.png" alt= "Tiffany Shen Project" className="pimg" />
                    <img src="./MissTiffanyHomepageHover_One5Digital.gif" alt= "Tiffany Shen Project" className="videos"  />
                    {/* <video className="videos" id="tiff" src="./MissTiffanyHomepageHover_One5Digital.mp4" type="video/mp4" preload='auto' loop muted></video> */}
                </div>
            </div>
            {/* <div className="project-4">
                <div data-aos='fade-up' className="p4-img"> 
                    <img src= "./SnSHomePage_One5Digital.png" alt= "Sn'S Babycare Project"  className="pimg"/>
                    <img src="./SnSHomePageHover_One5Digital.gif" alt= "Sn'S Babycare Project" className="videos"  />
                    <video className="videos" id="snc" src="./SnSHomePageHover_One5Digital.mp4" type="video/mp4"  loop muted></video>
                </div>
                <div className="p4-desc">
                    <h3 data-aos='fade-up'>Sn'S Babycare</h3>
                    <p data-aos='fade-up'>100% biodegradable and sustainably sourced bamboo wipes have been brought to Australia to 
                        continue supporting a green environment. We run an environmentally-friendly business and our bamboo wet wipes help
                        reduce any impact on the environment.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/SnS_Baby_care">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            </div> */}
            {/* <div className="project-5">
                <div className="p5-desc">
                    <h3 data-aos='fade-up'>Staged Homes</h3>
                    <p data-aos='fade-up'>Melissa helps to maximize your home’s sales potential and minimize your time on the market by transforming spaces at minimal expence, 
                        to amaze prospective buyers with a lasting first impression.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Staged_Homes_by_Melissa">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div data-aos='fade-up' className="p5-img">
                    <img src= "./StagedHomesHomepage_One5Digital.png" alt= "Staged Homes Project" className="pimg"/>
                    <img src="./StagedHomesHomepageHover_One5Digital.gif" alt= "Staged Homes Project" className="videos"  />
                    <video className="videos" id="shs" src="./StagedHomesHomepageHover_One5Digital.mp4" type="video/mp4" preload='auto' loop muted></video>
                </div>
            </div>  */}
            <div className="project-6">
                <div data-aos='fade-up' className="p6-img">
                    <img src= "./AccordHomepage_One5Digital.png" alt= "Accord Retail Project" className="pimg"/>
                    <img src="./AccordHomepageHover_One5Digital.gif" alt= "Accord Retail Project" className="videos"  />
                    {/* <video className="videos" id="acc" src="./AccordHomepageHover_One5Digital.mp4" type="video/mp4" loop muted></video> */}
                </div>
                <div className="p6-desc">
                    <h3 data-aos='fade-up'>Accord Retail</h3>
                    <p data-aos='fade-up'>Accord Retail Leasing is a boutique-sized commercial real estate agency which offers specialised services in retail planning, budgeting and 
                        leasing for your retail portfolio or development.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/Accord_Retail">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default OurProjects;
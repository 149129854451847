import React from 'react';
import './GDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function GDesignDesc() {
    return (
        <div className="GDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our graphic design and services include:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>BRANDING</h6>
                            <p data-aos='fade-up' className="service-desc">Our designers can create a custom logo, business cards, letterheads, and other branding materials that accurately reflect your brand identity and values.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>MARKETING MATERIALS</h6>
                            <p data-aos='fade-up' className="service-desc"> We can design flyers, brochures, posters, and other marketing materials that promote your products or services and engage your target audience.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA GRAPHICS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom social media graphics, such as cover images, posts, and ads, that help you stand out on social media platforms.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>WEB DESIGN GRAPHICS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom graphics for your website, such as banners, icons, and infographics, that make your website visually appealing and informative.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>PACKAGING DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom packaging designs for your products that help them stand out on store shelves and attract customers.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GDesignDesc
import React from 'react';
import './EComWDDesc.css';

function EComWDDesc() {
    return (
        <div className="WDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your ECommerce Website Development Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>TAILORED SOLUTIONS </h6>
                            <p className="ec-desc">One5 Digital understands that every business is unique and has different requirements. They work closely with their clients to understand their goals, target audience, and brand identity.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>USER-FRIENDLY INTERFACE</h6>
                            <p className="ec-desc">One5 Digital focuses on creating user-friendly interfaces that enhance the overall user experience. They employ the latest web development technologies to ensure that your online store is easy to navigate.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>MOBILE-OPTIMIZED DESIGN </h6>
                            <p className="ec-desc">In an era where mobile devices dominate internet usage, having a mobile-friendly website is vital. One5 Digital develops e-commerce websites that are fully responsive and optimized for various devices and screen sizes.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>SECURE PAYMENT GATEWAYS</h6>
                            <p className="ec-desc">Online security is a significant concern for both businesses and customers. One5 Digital integrates secure payment gateways into your e-commerce website to ensure safe and protected transactions.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>SEO-FRIENDLY APPROACH</h6>
                            <p className="ec-desc">Building a great e-commerce website is only the first step. One5 Digital also focuses on optimizing your website for search engines, enabling your business to gain visibility and attract organic traffic.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EComWDDesc
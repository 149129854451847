/* eslint-disable no-undef */
import React, { useState } from 'react';
import './Nav.css';
import { Link } from 'react-router-dom';

function Nav() {
    const [navbar, setNavbar] = useState(false)

    const showNavbar = () => setNavbar(!navbar)
    
    return (
        <div className="navpage">
            <div className="navbtn" onClick={showNavbar}>
                <div className="menu-bars" onClick={showNavbar}>
                    <div className="bar" onClick={showNavbar}></div>
                </div>
            </div>
            <nav className={navbar ? 'nav-menu active' : 'nav-menu'}>
                <div className="navbar-toggle" onClick={showNavbar} >
					<div className="closemenu" onClick={showNavbar}>
                        <div className="close" onClick={showNavbar}></div>
                    </div>
                    <div className="container-Links">
                        <ul className="navbar-items" onClick={showNavbar}>
                            <li className="nav-text"><Link exact to="/">HOME</Link></li>
                            <li className="nav-text projects"><Link exact to="/Projects">PROJECTS</Link>
                                <ul className="pro-list"> 
                                    <li className="pro-items"><Link exact to="/Projects/Accord_Retail" className="pro-list-items">ACCORD RETAIL <span class="newtop">new</span></Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Amazing_Amazon" className="pro-list-items">AMAZING AMAZON <span class="newtop">new</span></Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Ausport_Superstore" className="pro-list-items">AUSPORT</Link></li>
                                    {/* <li className="pro-items"><Link exact to="/Projects/Axlefix" className="pro-list-items">AXLEFIX</Link></li> */}
                                    {/* <li className="pro-items"><Link exact to="/Projects/Bear_Dog_Walking_Service" className="pro-list-items">BEAR DOG WALKING SERVICE</Link></li> */}
                                    <li className="pro-items"><Link exact to="/Projects/Clean_Power_Co" className="pro-list-items">CLEAN POWER CO.</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Dr_Green_Cosmetic_Group" className="pro-list-items">DR GREEN COSMETIC GROUP</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Essentially_Brands" className="pro-list-items">ESSENTIALLY BRANDS <span class="newtop">new</span></Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/The_Australian_Hardware_Journal" className="pro-list-items">HARDWARE JOURNAL</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Miss_Australia_Tiffany_Shen" className="pro-list-items">MISS TIFFANY SHEN</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Motor_Components" className="pro-list-items">MOTOR COMPONENTS</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Natural_By_Tara" className="pro-list-items">NATURAL BY TARA</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Paul_Ryan_Plumbing" className="pro-list-items">PAUL RYAN PLUMBING & CONTRACTING</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/PokeNPop_Collect" className="pro-list-items">POKE N POP COLLECT</Link></li>
                                    <li className="pro-items"><Link exact to="/Projects/Power_Equipment_Australasia" className="pro-list-items">POWER EQUIPMENT AUSTRALASIA</Link></li>
                                    {/* <li className="pro-items"><Link exact to="/Projects/Samantha_J_Willis" className="pro-list-items">SAMANTHA J WILLIS</Link></li> */}
                                    {/* <li className="pro-items"><Link exact to="/Projects/SnS_Baby_Care" className="pro-list-items">SN'S BABYCARE</Link></li> */}
                                    {/* <li className="pro-items"><Link exact to="/Projects/Staged_Homes_by_Melissa" className="pro-list-items">STAGED HOMES BY MELISSA <span class="newtop">new</span></Link></li> */}
                                    {/* <li className="pro-items"><Link exact to="/Projects/The_Artisans_Bottega" className="pro-list-items">THE ARTISANS BOTTEGA</Link></li> */}
                                    {/* <li className="pro-items"><Link exact to="/Projects/The_Grand_Band" className="pro-list-items">THE GRAND BAND</Link></li> */}
                                    <li className="pro-items"><Link exact to="/Projects/Venus_Virgin_Tomarz" className="pro-list-items">VENUS VIRGIN TOMARZ</Link></li> 
                                    <li className="pro-items"><Link exact to="/Projects/Your_Online_Pet_Store" className="pro-list-items">YOUR ONLINE PET STORE</Link></li> 
                                </ul>
                            </li>
                            <li className="nav-text"><Link exact to="/Software_Development">SOFTWARE DEVELOPMENT</Link></li>
                            <li className="nav-text"><Link exact to="/Services">SERVICES</Link></li>
                            <li className="nav-text"><Link exact to="/About_One5_Digital">ABOUT</Link></li>
                            {/* <li className="nav-text"><Link exact to="/Shopify_Website_Design">SHOPIFY WEBSITE DESIGN</Link></li> */}
                            <li className="nav-text"><Link exact to="/Testimonials">TESTIMONIALS</Link></li>
                            <li className="nav-text"><Link exact to="/Contact_One5_Digital">CONTACT</Link></li>
                        </ul>
						<ul className="follow-us">
							<a className="social" href="https://www.instagram.com/one5digital/" target="blank"><i class="fab fa-instagram"></i></a>
							<a className="social" href="https://au.linkedin.com/in/prince-patel" target="blank"><i class="fab fa-linkedin-in"></i></a>
							<a className="social" href="https://g.page/r/CdX-iKJfE8rSEAo" target="blank"><i class="fas fa-map-marker-alt"></i></a>
						</ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav
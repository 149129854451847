import React from 'react';
import PEabout from '../PEabout';
import PEdesc from '../PEdesc';
import PEexp from '../PEexp';
import PEpro from '../PEpro';
import {Helmet} from "react-helmet";

function Power_Equipment_Australasia() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Power Equipment Australasia Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Power Equipment Australasia projects is delivers reliable and sustainable power solutions for your needs. Browse our products and services today." />
            <meta name="keywords" content="Power Equipment Australasia, Power Equipment Australasia Project, One5's Power Equipment Australasia Project" />
        </Helmet>
            <PEabout />
            <PEexp />
            <PEpro />
            <PEdesc />
        </React.Fragment>
    ) 
}

export default Power_Equipment_Australasia

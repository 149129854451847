import React from 'react';
import './FeaturedProject.css';
import { Link } from 'react-router-dom';
 
function FeaturedProject() {
    return (
        <div className="featuredpro">
            <div>
                <h1>FEATURED PROJECTS </h1>
            </div>
            <div className="featuredpro-wrapper">
                <div className="featuredprorow">
                    <ul className="featuredprorow-items">
                        <li>
                            <Link exact to="/Projects/Essentially_Brands">
                            <img className="featuredproimg" src="./EssentiallyBrandsProject_One5Digital.png" alt="Essentially Brands Project Front Image" /></Link>
                        </li>
                        <li>
                            <Link exact to="/Projects/Accord_Retail">
                            <img className="featuredproimg" src="./AccordProject_One5Digital.png" alt="Accord Retail Project Front Image" /></Link>
                        </li>
                        <li>
                            <Link exact to="/Projects/Miss_Australia_Tiffany_Shen">
                            <img className="featuredproimg" src="./MissTiffanyProject_One5Digital.png" alt="Tiffany Shen Project Front Image" /></Link>
                        </li>
                        <li>
                            <Link exact to="/Projects/Ausport_Superstore">
                            <img className="featuredproimg" src="./AusportProject_One5Digital.png" alt="Ausport Project Front Image" /></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FeaturedProject

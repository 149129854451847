/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './MGabout.css';
import { Link } from 'react-router-dom';

function MGabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'mgabout'>
            <div className = 'mgabout-container'>
				<div className = 'mgaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>MICHAEL GAZZOLA</h1>
                </div>
                <div className = 'mgaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'mgabout-desc'>Michael Gazzola is an experienced professional photographer in his 20th year, based in the heart of Fitzroy. IMG.CO Pty Ltd is short for Image Company. The IMG part has been borrowed from the default file name used in many digital cameras [eg img001.jpg] and brought together with the formation of the company.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2019</p>
                    <div className='mgabout-btn'>
                        <a href="https://www.imgco.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="mgaboutcol-right">
                    <img className="mgimg" src= "./MichaelGazzolaAbout_One5Digital.png" alt= "Loading..." />
                    <img className="mgimg2" src= "./MichaelGazzolaAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default MGabout

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Project.css';
import { Button } from './Button';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";

function Project() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    window.onload= function(){
        const videos = document.querySelectorAll("video")
        videos.forEach(video => {
            video.addEventListener("mouseover", function () {
                this.play()
            })
            
            video.addEventListener("mouseleave", function () {
                this.pause()
            })
            
            video.addEventListener("touchstart", function () {
                this.play()
            })
            
            video.addEventListener("touchend", function () {
                this.pause()
            })
        })
    }

    return (
        <div className="project">
            <div>
                <h1 data-aos='fade-up' data-aos-duration="500" >OUR PROJECTS </h1>
                <p data-aos='fade-up' data-aos-duration="1500" >We offer highly innovative design for the web and mobile.
                Right from user interfaces to complete campaigns, our approach to design stems 
                from a vision for the perfect marriage of aesthetics and functionality.</p>
            </div>
            <div className="pro-wrapper">
                <div className="prorow prorow-1">
                    <ul className="prorow1-items">
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Amazing_Amazon" className="pro1">
                            <img className="proimg" src="./AmazingAmazonProject_One5Digital.png" alt='Amazing Amazon Project Front Image'/>
                            <img className="imgpro" src="./AmazingAmazonProjectHover_One5Digital.png" alt='Amazing Amazon Project Back Image' />
                            </Link>
                            <h6>AMAZING AMAZON</h6>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Essentially_Brands" className="pro1">
                            <img className="proimg" src="./EssentiallyBrandsProject_One5Digital.png" alt='Essentially Brands Project Front Image'/>
                            <img className="imgpro" src="./EssentiallyBrandsProjectHover_One5Digital.png" alt='Essentially Brands Project Back Image'/>
                            </Link>
                            <h6>ESSENTIALLY BRANDS</h6>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Cladcraft" className="pro1">
                            <img className="proimg" src="./CladcraftProject_One5Digital.png" alt='Cladcraft Project Front Image'/>
                            <img className="imgpro" src="./CladcraftProjectHover_One5Digital.png" alt='Cladcraft Project Back Image'/>
                            </Link>
                            <h6>CLADCRAFT</h6>
                        </li>
                        <li data-aos='fade-up'data-aos-duration="2000" className="projli">
                            <Link exact to="/Projects/Campus_Radar" className="pro1">
                            <img className="proimg" src="./CampusRadarProject_One5Digital.png" alt='Campus Radar Project Front Image'/>
                            <img className="imgpro" src="./CampusRadarProjectHover_One5Digital.png" alt='Campus Radar Project Back Image' />
                            </Link>
                            <h6>CAMPUS RADAR</h6>
                        </li> 
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Super_Star_Boards" className="pro1">
                            <img className="proimg" src="./SSBProject_One5Digital.png" alt='Super Star Boards Project Front Image' />
                            <img className="imgpro" src="./SSBProjectHover_One5Digital.png" alt='Super Star Boards Project Back Image'/>
                            </Link>
                            <h6>SUPER STAR BOARDS</h6>
                        </li>    
                        <li data-aos='fade-up' data-aos-duration="2000"  className="projli">
                            <Link exact to="/Projects/Langys" className="pro1">
                            <img className="proimg" src="./LangysProject_One5Digital.png" alt='Langys Project Front Image' />
                            <img className="imgpro" src="./LangysProjectHover_One5Digital.png" alt='Langys Project Back Image'/>
                            </Link>
                            <h6>LANGYS</h6>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="2000" className="projli">   
                            <Link exact to="/Projects/Accord_Retail" className="pro1">                         
                            <img className="proimg" src="./AccordProject_One5Digital.png" alt='Accord Retail Project Front Image' />
                            <img className="imgpro" src="./AccordProjectHover_One5Digital.png" alt='Accord Retail Project Back Image'/>
                            </Link>
                            <h6>ACCORD RETAIL</h6>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="2000" className="projli">
                            <Link exact to="/Projects/Miss_Australia_Tiffany_Shen" className="pro1">
                            <img className="proimg" src="./MissTiffanyProject_One5Digital.png" alt='Tiffany Shen Project Front Image'/>
                            <img className="imgpro" src="./MissTiffanyProjectHover_One5Digital.png" alt='Tiffany Shen Project Back Image'/>
                            </Link>
                            <h6>TIFFANY SHEN</h6>
                        </li>

                        <li data-aos='fade-up' data-aos-duration="2000" className="projli">    
                            <Link exact to="/Projects/Ausport_Superstore" className="pro1">                     
                            <img className="proimg" src="./AusportProject_One5Digital.png" alt='Ausport Project Front Image'/>
                            <img className="imgpro" src="./AusportProjectHover_One5Digital.png" alt='Ausport Project Back Image'/>
                            </Link>
                            <h6>AUSPORT</h6>
                        </li>
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Motor_Components" className="pro1"> 
                            <img className="proimg" src="./MotorComponentProject_One5Digital.png" alt='Motor Components Project Front Image' />
                            <img className="imgpro" src="./MotorComponentProjectHover_One5Digital.png" alt='Motor Components Project Back Image'/>
                            </Link>
                            <h6>MOTOR COMPONENTS</h6>
                        </li>
                        {/* <li data-aos='fade-up'  className="projli">         
                            <Link exact to="/Projects/Staged_Homes_by_Melissa" className="pro1">                   
                            <img className="proimg" src="./StagedHomesProject_One5Digital.png" alt='Staged Homes By Melissa Project Front Image'/>
                            <img className="imgpro" src="./StagedHomesProjectHover_One5Digital.png" alt='Staged Homes By Melissa Project Back Image'/>
                            </Link>
                            <h6>STAGED HOMES BY MELISSA</h6>
                        </li> */}
                        {/* <li data-aos='fade-up'  className="projli">                  
                            <Link exact to="/Projects/SnS_Baby_Care" className="pro1">          
                            <img className="proimg" src="./SnSProject_One5Digital.png" alt='SNS Babycare Project Front Image'/>
                            <img className="imgpro" src="./SnSProjectHover_One5Digital.png" alt='SNS Babycare Project Back Image'/>
                            </Link>
                            <h6>SN'S BABYCARE</h6>
                        </li> */}

                        <li data-aos='fade-up'  className="projli">
                            <a href="https://tgardenfruitnveg.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./TgardenProject_One5Digital.png" alt='T-Garden Fruit & Vegetable Project Front Image'/>
                            <img className="imgpro" src="./TgardenProjectHover_One5Digital.png" alt='T-Garden Fruit & Vegetable Project Back Image'/></a>
                            <h6>T-GARDEN FRUIT & VEGETABLE</h6>
                        </li>                   
                        <li data-aos='fade-up'  className="projli">
                            <Link exact to="/Projects/Dr_Green_Cosmetic_Group" className="pro1">
                            <img className="proimg" src="./DrGreenProject_One5Digital.png" alt='Dr. Green Cosmetic Group Project Front Image'/>
                            <img className="imgpro" src="./DrGreenProjectHover_One5Digital.png" alt='Dr. Green Cosmetic Group Project Back Image'/>
                            </Link>
                            <h6>DR GREEN COSMETIC GROUP</h6>
                        </li>                   
                        <li data-aos='fade-up'  className="projli">
                            <Link exact to="/Projects/Dr_Green_Shop" className="pro1">
                            <img className="proimg" src="./DrGreenShopProject_One5Digital.png" alt='Dr. Green Cosmetic Shop Project Front Image'/>
                            <img className="imgpro" src="./DrGreenShopProjectHover_One5Digital.png" alt='Dr. Green Cosmetic Shop Project Back Image'/>
                            </Link>
                            <h6>DR GREEN COSMETIC SHOP</h6>
                        </li>
                        <li data-aos='fade-up'  className="projli">
                            <Link exact to="/Projects/The_Australian_Hardware_Journal" className="pro1">
                            <img className="proimg" src="./HardwareJournalProject_One5Digital.png" alt='Australian Hardware Journal Project Front Image'/>
                            <img className="imgpro" src="./HardwareJournalProjectHover_One5Digital.png" alt='Australian Hardware Journal Project Back Image'/>
                            </Link>
                            <h6>AUSTRALIAN HARDWARE JOURNAL</h6>
                        </li>
                        <li data-aos='fade-up'  className="projli">
                            <Link exact to="/Projects/Power_Equipment_Australasia" className="pro1">
                            <img className="proimg" src="./PowerEquipProject_One5Digital.png" alt='Power Equipment Australasia Project Front Image'/>
                            <img className="imgpro" src="./PowerEquipProjectHover_One5Digital.png" alt='Power Equipment Australasia Project Back Image'/>
                            </Link>
                            <h6>POWER EQUIPMENT AUSTRALASIA</h6>
                        </li>

                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Paul_Ryan_Plumbing" className="pro1">
                            <img className="proimg" src="./PaulRyanProject_One5Digital.png" alt='Paul Ryan Plumbing & Contracting Project Front Image'/>
                            <img className="imgpro" src="./PaulRyanProjectHover_One5Digital.png" alt='Paul Ryan Plumbing & Contracting Project Back Image'/>
                            </Link>
                            <h6>PAUL RYAN PLUMBING & CONTRACTING</h6>
                        </li>                  
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/PokeNPop_Collect" className="pro1">
                            <img className="proimg" src="./PokenPopProject_One5Digital.png" alt='POKE N POP Collect Project Front Image'/>
                            <img className="imgpro" src="./PokenPopProjectHover_One5Digital.png" alt='POKE N POP Collect Project Back Image'/>
                            </Link>
                            <h6>POKE N POP COLLECT</h6>
                        </li>
                        <li data-aos='fade-up' className="projli">
                            <a href="https://baysideskinandlaser.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./BaysideProject_One5Digital.png" alt='Bayside Skin & Laser Project Front Image'/>
                            <img className="imgpro" src="./BaysideProjectHover_One5Digital.png" alt='Bayside Skin & Laser Project Back Image'/>
                            </a>
                            <h6>BAYSIDE SKIN & LASER</h6>
                        </li>
                        <li data-aos='fade-up' className="projli">
                            <a href="https://www.covertradie.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./CovertradieProject_One5Digital.png" alt='Covertradie Insurance Project Front Image'/>
                            <img className="imgpro" src="./CovertradieProjectHover_One5Digital.png" alt='Covertradie Insurance Project Back Image'/></a>
                            <h6>COVERTRADIE INSURANCE</h6>
                        </li>

                        <li data-aos='fade-up' className="projli">
                            <a href="http://www.whfdoors.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./WHFDoorsProject_One5Digital.png" alt='WHF Doors Project Front Image'/>
                            <img className="imgpro" src="./WHFDoorsProjectHover_One5Digital.png" alt='WHF Doors Project Back Image'/></a>
                            <h6>WHF DOORS</h6>
                        </li>                
                        {/* <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/The_Grand_Band" className="pro1">
                            <img className="proimg" src="./GrandBandProject_One5Digital.png" alt='The Grand Band Project Front Image'/>
                            <img className="imgpro" src="./GrandBandProjectHover_One5Digital.png" alt='The Grand Band Project Back Image'/>
                            </Link>
                            <h6>THE GRAND BAND</h6>
                        </li> */}
                        <li data-aos='fade-up' className="projli">
                            <a href="https://maassociates.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./MassiAhmadzayProject_One5Digital.png" alt='Massi Ahmadzay Lawyer Firm Project Front Image'/>
                            <img className="imgpro" src="./MassiAhmadzayProjectHover_One5Digital.png" alt='Massi Ahmadzay Lawyer Firm Project Back Image'/></a>
                            <h6>MASSI AHMADZAY LAWYER FIRM</h6>
                        </li>
                        <li data-aos='fade-up' className="projli">
                            <a href="https://ps4x4.com.au/" target="blank" className="pro1">
                            <img className="proimg" src="./PS4Project_One5Digital.png" alt='PS4X4 Accessories Store Project Front Image'/>
                            <img className="imgpro" src="./PS4ProjectHover_One5Digital.png" alt='PS4X4 Accessories Store Project Back Image'/></a>
                            <h6>PS4X4 ACCESSORIES STORE</h6>
                        </li>

                        <li data-aos='fade-up' className="projli">                 
                            <Link exact to="/Projects/Venus_Virgin_Tomarz" className="pro1">                                      
                            <img className="proimg" src="./VenusVirginProject_One5Digital.png" alt='Venus Virgin Tomarz Project Front Image'/>
                            <img className="imgpro" src="./VenusVirginProjectHover_One5Digital.png" alt='Venus Virgin Tomarz Project Back Image'/>
                            </Link>
                            <h6>VENUS VIRGIN TOMARZ</h6>
                        </li>
                        {/* <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/The_Artisans_Bottega" className="pro1">
                            <img className="proimg" src="./ArtisansProject_One5Digital.png" alt='The Artisans Bottega Project Front Image'/>
                            <img className="imgpro" src="./ArtisansProjectHover_One5Digital.png" alt='The Artisans Bottega Project Back Image'/>
                            </Link>
                            <h6>THE ARTISANS BOTTEGA</h6>
                        </li> */}
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Natural_By_Tara" className="pro1">
                            <img className="proimg" src="./NatureByTaraProject_One5Digital.png" alt='Natural By Tara Project Front Image'/>
                            <img className="imgpro" src="./NatureByTaraProjectHover_One5Digital.png" alt='Natural By Tara Project Back Image'/>
                            </Link>
                            <h6>NATURAL BY TARA</h6>
                        </li>
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Clean_Power_Co" className="pro1">
                            <img className="proimg" src="./CleanpowerProject_One5Digital.png" alt='Clean Power Co Project Front Image'/>
                            <img className="imgpro" src="./CleanpowerProjectHover_One5Digital.png" alt='Clean Power Co Project Back Image'/>
                            </Link>
                            <h6>CLEAN POWER CO</h6>
                        </li>

                        {/* <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Bear_Dog_Walking_Service" className="pro1">
                            <img className="proimg" src="./BeardogProject_One5Digital.png" alt='Bear Dog Walking Service Project Front Image'/>
                            <img className="imgpro" src="./BeardogProjectHover_One5Digital.png" alt='Bear Dog Walking Service Project Back Image'/>
                            </Link>
                            <h6>BEAR DOG WALKING SERVICE</h6>
                        </li>                   */}
                        {/* <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Samantha_J_Willis" className="pro1">
                            <img className="proimg" src="./SamanthaProject_One5Digital.png" alt='Samantha Willis Project Front Image'/>
                            <img className="imgpro" src="./SamanthaProjectHover_One5Digital.png" alt='Samantha Willis Project Back Image'/>
                            </Link>
                            <h6>SAMANTHA WILLIS</h6>
                        </li> */}
                        {/* <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Axlefix" className="pro1">
                            <img className="proimg" src="./AxlefixProject_One5Digital.png" alt='Axlefix Project Front Image'/>
                            <img className="imgpro" src="./AxlefixProjectHover_One5Digital.png" alt='Axlefix Project Back Image'/>
                            </Link>
                            <h6>AXLEFIX</h6>
                        </li> */}
                        <li data-aos='fade-up' className="projli">
                            <Link exact to="/Projects/Trinity_Maintenance_Group" className="pro1">
                            <img className="proimg" src="./TrinityProject_One5Digital.png" alt='Trinity Maintenance Group Project Front Image'/>
                            <img className="imgpro" src="./TrinityProjectHover_One5Digital.png" alt='Trinity Maintenance Group Project Back Image'/>
                            </Link>
                            <h6>TRINITY MAINTENANCE GROUP</h6>
                        </li>
                        <li data-aos='fade-up'className="projli">
                            <Link exact to="/Projects/Michael_Gazzola" className="pro1">
                            <img className="proimg" src="./MichaelGazzolaProject_One5Digital.png" alt='Michael Gazzola Project Front Image'/>
                            <img className="imgpro" src="./MichaelGazzolaProjectHover_One5Digital.png" alt='Michael Gazzola Project Back Image'/>
                            </Link>
                            <h6>MICHAEL GAZZOLA</h6>
                        </li>
                        <li data-aos='fade-up'className="projli">
                            <Link exact to="/Projects/Beat_The_Rest_Movers" className="pro1">
                            <img className="proimg" src="./BeatTheRestMoversProject_One5Digital.png" alt='Beat The Rest Movers Project Front Image'/>
                            <img className="imgpro" src="./BeatTheRestMoversProjectHover_One5Digital.png" alt='Beat The Rest Movers Project Back Image'/>
                            </Link>
                            <h6>BEAT THE REST MOVERS</h6>
                        </li>
                        <li data-aos='fade-up'className="projli">
                            <Link exact to="/Projects/Purity_Plumbing" className="pro1">
                            <img className="proimg" src="./PurityPlumbingProject_One5Digital.png" alt='Purity Plumbing Project Front Image'/>
                            <img className="imgpro" src="./PurityPlumbingProjectHover_One5Digital.png" alt='Purity Plumbing Project Back Image'/>
                            </Link>
                            <h6>PURITY PLUMBING</h6>
                        </li>
                        <li data-aos='fade-up'className="projli">
                            <Link exact to="/Projects/Wilkinson_Property_Service" className="pro1">
                            <img className="proimg" src="./WilkinsonProject_One5Digital.png" alt='Wilkinson Property Service Project Front Image'/>
                            <img className="imgpro" src="./WilkinsonProjectHover_One5Digital.png" alt='Wilkinson Property Serivce Project Back Image'/>
                            </Link>
                            <h6>WILKINSON PROPERTY SERVICE</h6>
                        </li>
                        <li data-aos='fade-up'className="projli">
                            <Link exact to="/Projects/Your_Online_Pet_Store" className="pro1">
                            <img className="proimg" src="./PetStoreProject_One5Digital.png" alt='Online Pet Store Project Front Image'/>
                            <img className="imgpro" src="./PetStoreProjectHover_One5Digital.png" alt='Online Pet Store Project Back Image'/>
                            </Link>
                            <h6>YOUR ONLINE PET STORE</h6>
                        </li>
                        <li data-aos='fade-up' className="projli yourpromain">
                            <div className="yourpro">
                                <div><h4>YOUR <br/> PROJECT <br/> HERE?</h4></div>
                                <div className='service-btn'>
                                    <Link excat to="/Contact_One5_Digital">
                                        <Button className='btn' buttonStyle='btn--white' >Contact Us</Button>
                                    </Link>
                                </div>
                            </div>
                            <h6 style={{ marginTop: '28px' }}>YOUR COMPANY</h6>
                        </li>
                        {/* <li>
                            <img className="proimg" src="./essentiallybrands.png" />
                            <h6>PROJECT:<p>ESSENTIALLY BRANDS</p></h6>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Project

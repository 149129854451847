import React from 'react';
import NTabout from '../NTabout';
import NTdesc from '../NTdesc';
import NTexp from '../NTexp';
import NTpro from '../NTpro';
import {Helmet} from "react-helmet";

function Natural_By_Tara() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Natural By Tara Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Natural By Tara Project: Discover the Power of Nature for Your Skin. Shop Organic Skincare and Beauty Products Online Now!" />
            <meta name="keywords" content="Natural By Tara, Natural By Tara Project, One5's Natural By Tara Project" />
        </Helmet>
            <NTabout />
            <NTexp />
            <NTpro />
            <NTdesc />
        </React.Fragment>
    )
}

export default Natural_By_Tara

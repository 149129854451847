import React from 'react';
import './PHPabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function PHPabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>PHP WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's PHP Website Development Service!</h6>
                {/* <p>In today's fast-paced digital world, having a strong online presence is crucial for the success of any business.</p> */}
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>In today's digital era, having a strong online presence is crucial for businesses to thrive. A well-designed and functional website is the cornerstone of establishing an effective online presence. If you are looking for professional PHP website development services in Melbourne, One5 Digital is here to fulfill your requirements. With a dedicated team of skilled developers, One5 Digital offers top-notch PHP website development services that can take your business to new heights.</p>
                        {/* <p className='smp'>PHP (Hypertext Preprocessor) is a widely-used scripting language that is particularly suited for web development. It is known for its flexibility, scalability, and compatibility with various platforms and frameworks. One5 Digital harnesses the power of PHP to create dynamic, interactive, and feature-rich websites that cater to the unique needs of your business.</p> */}
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./php_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PHPabout

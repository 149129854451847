import React from 'react';
import './SSpro.css';
import { Button } from './Button';

function SSpro() {
    const colors = ["./SSBSlide1_One5Digital.png", "./SSBSlide2_One5Digital.png", "./SSBSlide3_One5Digital.png"];
    const delay = 2500;
    function Slideshow() {
        const [index, setIndex] = React.useState(0);
        const timeoutRef = React.useRef(null);
      
        function resetTimeout() {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }
      
        React.useEffect(() => {
          resetTimeout();
          timeoutRef.current = setTimeout(
            () =>
              setIndex((prevIndex) =>
                prevIndex === colors.length - 1 ? 0 : prevIndex + 1
              ),
            delay
          );
      
          return () => {
            resetTimeout();
          };
        }, [index]);

        return (
            <div className="slideshow">
              <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                  <img id="slide1" className="slide1" src="./SSBSlide2_One5Digital.png" alt="Loading..."/>
                  <img id="slide2" className="slide2" src="./SSBSlide1_One5Digital.png" alt="Loading..."/>
                  <img id="slide3" className="slide3" src="./SSBSlide3_One5Digital.png" alt="Loading..."/>
              </div>
        
              <div className="slideshowDots">
                {colors.map((_, idx) => (
                  <div key={idx} className={`slideshowDot${index === idx ? " active" : ""}`}
                    onClick={() => {
                      setIndex(idx);
                    }}
                  ></div>
                ))}
              </div>
            </div>
          );
        }

  return (
    <div className="sspro">
    <div>
      <h1 data-aos='fade-up'>MORE ABOUT PROJECT</h1>
      <p data-aos='fade-up'>Website has inner pages about the company, and other required pages. Check out this website for more details.</p>
      <div data-aos='fade-up' data-aos-duration="1500" className='dgabout-btn'>
        <a href="https://stagedhomesbymelissa.com/" target="blank">
          <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
        </a>
      </div>
    </div>
        <Slideshow />
    </div>
  )
}

export default SSpro
import React from 'react';
import './TFexp.css';

function TFexp() {
    return (
        <div className="tfexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 3rem 0 3rem'}}>Miss Tiffany Shen is the proud winner of Miss Australia International 2020. 
                    At homepage we've cover her journey of Miss Australia. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="tf-video" autoPlay loop muted>
                    <source src="./MissTiffany_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./MissTiffany_One5Digital.gif" alt= "Loading..." className="tf-video"  />
            </div> 
        </div>
    )
}

export default TFexp

import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import SWDabout from '../SWDabout';
import SWDabout2 from '../SWDabout2';
import SWDDesc from '../SWDDesc';
import SWD_contact from '../SWD_contact';

function Shopify_Website_Development() {
  return (
    <React.Fragment>
      <Helmet>
        <title>One5 Digital: Your Go-To Destination for Professional Shopify Website Development Services in Melbourne</title>
        <meta name="description" content="Looking for top-notch Shopify website development services in Melbourne? One5 Digital offers expert solutions tailored to meet your business needs. Discover how their skilled team can transform your online store into a thriving e-commerce platform." />
        <meta name="keywords" content="Shopify website development, Melbourne, e-commerce solutions, One5 Digital, professional services" />
      </Helmet>
      <SWDabout />
      <SWDabout2 />
      <SWDDesc />
      <FeaturedProject />
      <Testimonials />
      <SWD_contact />
    </React.Fragment>
  )
}

export default Shopify_Website_Development
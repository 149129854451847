import React from 'react';
import './AAexp.css';

function AAexp() {
    return (
        <div className="aaexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Amazing Amazon has large numbers of categories of the products they served. It has website design that is simple and easy to use to the users. Visit website for more look.
                </p>
            </div>
            <div>
                {/* <video className="au-video" autoPlay loop muted>
                    <source src="./Ausport_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./AmazingAmazon_One5Digital.gif" alt= "Loading..." className="au-video"  />
            </div>
        </div>
    )
}

export default AAexp

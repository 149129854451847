import React from 'react';
import './SHWabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SHWabout() {
    return (
        <div className="SMDesignabout">
            <div data-aos='fade-up' className="centerabout">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <h1>PHP WEBSITE DEVELOPMENT</h1>
                <h6>Welcome to One5 Digital's Static HTML Website Development Service!</h6>
                <p>Efficient Static HTML Website Development Services by One5 Digital in Melbourne</p>
                <div className='de-about'>
                    <div data-aos='fade-up' className="left-sidea">
                        <p className='smp'>In the fast-paced digital world, businesses need websites that not only captivate their audience but also deliver exceptional performance. One5 Digital, a leading web development agency based in Melbourne, offers cutting-edge Static HTML Website Development services to help businesses establish a strong online presence. With a team of skilled developers and a commitment to excellence, One5 Digital empowers businesses to create fast, secure, and engaging online experiences for their customers.</p>
                        {/* <p className='smp'>PHP (Hypertext Preprocessor) is a widely-used scripting language that is particularly suited for web development. It is known for its flexibility, scalability, and compatibility with various platforms and frameworks. One5 Digital harnesses the power of PHP to create dynamic, interactive, and feature-rich websites that cater to the unique needs of your business.</p> */}
                        <div className='ldabout-btn' style={{float: 'left'}}>
                            <Link excat to="/Contact_One5_Digital">
                                <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right-sidea'>
                        <img data-aos='fade-up' className="designimg" src="./html_desc_One5Digital.png" alt="Loading..." />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SHWabout

import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import GP_contact from '../GP_contact';
import GPabout from '../GPabout';
import GPDesc from '../GPDesc';
import GPDesc2 from '../GPDesc2';

function Google_PPC() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Boost Your Business with One5 Digital's Google PPC Services in Melbourne</title>
        <meta name="description" content="One5 Digital, a leading digital marketing agency in Melbourne, offers comprehensive Google PPC services to help businesses drive more traffic, generate leads, and increase conversions. Read on to learn more about their services and how they can benefit your business." />
        <meta name="keywords" content="One5 Digital, Google PPC, Melbourne, digital marketing, traffic, leads, conversions, advertising, online marketing" />
      </Helmet>
      <GPabout />
      <GPDesc />
      <GPDesc2 />
      <FeaturedProject />
      <Testimonials />
      <GP_contact />
    </React.Fragment>
  )
}

export default Google_PPC
import React from 'react';
import './GDesignDesc2.css';

function GDesignDesc2() {
    return (
        <div className="GDesign-desc2">
            <div className="la-about">
                <div className="ld-desc" data-aos='fade-up'>
                    <h1>Why Choose One5 Digital for Your Graphic Design Needs?</h1>

                    <div className="lda-service-wrapper">
                        <ul className="lda-service-1">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>01</h1>
                            <h6>PERSONALIZED APPROACH </h6>
                            <p className="ec-desc">We take a personalized approach to each graphic design project, ensuring that your designs accurately reflect your brand identity and meet your specific business needs.</p>
                        </ul>
                        <ul className="lda-service-2">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>02</h1>
                            <h6>PROFESSIONAL DESIGNERS</h6>
                            <p className="ec-desc">Our designers have years of experience in graphic design and are skilled in creating designs that are both visually appealing and effective.</p>
                        </ul>
                        <ul className="lda-service-3">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>03</h1>
                            <h6>AFFORDABLE PRICING </h6>
                            <p className="ec-desc">We offer affordable pricing for our graphic design services, so you can get professional designs that fit within your budget.</p>
                        </ul>
                    </div>
                    <div className="lda-service-wrapper">
                        <ul className="lda-service-4">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>04</h1>
                            <h6>FAST TURNAROUND </h6>
                            <p className="ec-desc">We aim to deliver your graphic design project within 3-5 business days, so you can start using your new designs as soon as possible.</p>
                        </ul>
                        <ul className="lda-service-5">
                            <h1 className="ecno" style={{ color: '#F04E31' }}>05</h1>
                            <h6>SATISFACTION GUARANTEED</h6>
                            <p className="ec-desc">We want you to be completely satisfied with your graphic design project, which is why we offer unlimited revisions until you're happy with the final product.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GDesignDesc2
import React from 'react';
import SEODesignAbout from '../SEODesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import SEODesign_contact from '../SEODesign_contact';
import SEODesignDesc from '../SEODesignDesc';
import FeaturedProject from '../FeaturedProject';
import SEODesignDesc2 from '../SEODesignDesc2';
import SEODesignDesc3 from '../SEODesignDesc3';

function SEO_Design() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>One5 Digital: Boosting Your Online Presence with Top-Notch SEO Services in Melbourne</title>
				<meta name="description" content="One5 Digital is your ultimate destination for optimizing your website and gaining more visibility on search engines. Our team of experts provides customized SEO services in Melbourne to help you achieve higher rankings, increased traffic, and improved online credibility." />
				<meta name="keywords" content="SEO services, Melbourne, website optimization, search engine rankings, online credibility, increased traffic" />
			</Helmet>
            <SEODesignAbout />
            <SEODesignDesc />
            <SEODesignDesc2 />
            <SEODesignDesc3 />
            <FeaturedProject />
            <Testimonials />
            <SEODesign_contact />
        </React.Fragment>
    )
}

export default SEO_Design
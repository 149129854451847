/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './VVabout.css';
import { Link } from 'react-router-dom';

function VVabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <div className = 'vvabout'>
            <div className = 'vvabout-container'>
				<div className = 'vvaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>VENUS VIRGIN</h1>
                </div>
                <div className = 'vvaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'vvabout-desc'>My world offers to make yours fun and stylish in a sparkling new way. I’ve become a whole 
                    new person to accommodate style adventurers just like you. We can all be part of my parallel universe. You can have some of that added value. I’ll propel you across the solar winds of modernity.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='vvabout-btn'>
                        <a href="https://venusvirgin.myshopify.com/?_bt=eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaDUyWlc1MWMzWnBjbWRwYmk1dGVYTm9iM0JwWm5rdVkyOXRCam9HUlZRPSIsImV4cCI6IjIwMjEtMDctMTdUMDk6NTE6MTYuODU1WiIsInB1ciI6InBlcm1hbmVudF9wYXNzd29yZF9ieXBhc3MifX0%3D--e7e38a39520f167db58f96bc478b8ff446c34bef" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="vvaboutcol-right">
                    <img className="vvimg" src= "./VenusVirginAbout_One5Digital.png" alt= "Loading..." />
                    <img className="vvimg2" src= "./VenusVirginAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default VVabout

import React from 'react';
import './PPCsteps.css';

function PPCsteps() {
    return (
        <div className="ppcsteps">
            <div className="ppc-desc">
                <div className="ppc-left">
                    <h1>DID YOU KNOW, THAT
                    WITH PPC YOU CAN</h1>
                </div>
                <div className="ppc-right">
                    <ul className="ppc-list scrollbar-warning">
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >01.</span> TRACK THE USER EXPERIENCE FROM 'CLICK TO BUY'</h4>
                        </li><br/> <br/>
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >02.</span> REMARKET DIRECTLY TO CONSUMERS THAT VISITED <br/>
                            YOUR WEBSITE BUT LEFT WITHOUT CONVERTING.</h4>
                        </li><br/> <br/>
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >03.</span> MEASURE 'TO THE LAST DOLLAR' YOUR RETURN ON <br/> INVESTMENT (ROI)</h4>
                        </li><br/> <br/>
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >04.</span> DETERMINE YOUR HIGHEST PERFORMING KEYWORD <br/> AND 
                            UTILISE FOR YOUR ORGANIC SEARCH ENGINE <br/>OPTIMIZATION</h4>
                        </li><br/> <br/>
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >05.</span> CONTINUALLY OPTIMISE FOR HIGHER CONVERSION <br/>AND 
                            LOWER 'COST-PER-CLICK'</h4>
                        </li><br/> <br/>
                        <li className="ppc-items">
                            <h4><span style={{color: '#F04E31'}} >06.</span>CONVERT THE GOOGLE SEARCH EXPERIENCE INTO <br/>
                            'SHOPPING' EXPERIENCE FOR YOUR POTENTIAL <br/>CONSUMERS</h4>
                        </li><br/> <br/>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PPCsteps

import React from 'react';
import './CPdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function CPdesc() {
    return (
        <div className="cpdesc">
            <div className="cpdesc-cat2">
                <div className="cpdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>BEAR DOG WALKING SERVICE</h1>
                    <p>Bear Dog Walking Service was developed to provide a quality service to families who wanted their dogs walked. As our lives get busier due to work, 
                        school or life events why should our pets miss out on their daily exercise? </p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Bear_Dog_Walking_Service">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="cpdesc2-right">
                <img className="cpdesc2ri" src="./BeardogProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default CPdesc

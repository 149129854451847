import React from 'react';
import './CRdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function CRdesc() {
    return (
        <div className="crdesc">
            <div className="crdesc-cat2">
                <div className="crdesc2-left">
                    <p>───── NEXT CASE STUDY</p>
                    <h1>DR GREEN COSMETIC SHOP</h1>
                    <p>At our cosmetic clinic, we pride ourselves on communicating with you about your beauty goals and providing quality care to aid you in those goals. We look forward to speaking with you and supporting you in your beauty journey.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Dr_Green_Shop">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div className="crdesc2-right">
                    <img className="crdesc2ri" src="./DrGreenShopProject_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default CRdesc 
import React from 'react';
import './SEODesignDesc2.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SEODesignDesc2() {
    return (
        <div className="SEODesign-desc">
            <div data-aos='fade-up' className="design-desc">
                <p>Keyword research is a critical aspect of SEO that involves identifying the search terms that your target audience uses to find businesses like yours. Our team uses advanced tools and techniques to conduct comprehensive keyword research and identify your business's most relevant and high-traffic keywords.</p>
                <p>On-page optimization involves optimizing the content and structure of your website to make it more search-engine friendly. This includes optimizing your website's meta tags, headings, content, images, and internal linking structure.</p>
                <p>Off-page optimization involves building high-quality backlinks to your website from other authoritative websites. This helps to increase your website's domain authority and improve its search engine rankings.</p>
            </div>
        </div>
    )
}

export default SEODesignDesc2
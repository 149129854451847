import React from 'react';
import PSabout from '../PSabout';
import PSdesc from '../PSdesc';
import PSexp from '../PSexp';
import PSpro from '../PSpro';
import { Helmet } from "react-helmet";

function Your_Online_Pet_Store() {
  return (
    <React.Fragment>
        <Helmet>
            <title>Your Online Pet Store | One5 Digital Agency Melbourne</title>
            <meta name="description" content="Discover how One5 Digital Melbourne transformed the pet retail industry with their innovative online pet store project, offering a wide range of products and services to cater to your furry friends. Explore Paws, Play, and Prosper today!" />
            <meta name="keywords" content="Your Online Pet Store, Your Online Pet Store Project, One5's Your Online Pet Store Project" />
        </Helmet>
        <PSabout />
        <PSexp />
        <PSpro />
        <PSdesc />
    </React.Fragment>
  )
}

export default Your_Online_Pet_Store
import React from 'react';
import './WOODesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function WOODesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>By embracing WOO Commerce website development, businesses can benefit in several ways:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." /> */}
                            <h6>FRATURED RICH E-COMM</h6>
                            <p>With One5 Digital, you can expect a comprehensive range of features and functionalities for your WooCommerce website. Their skilled developers can incorporate a variety of plugins and extensions to enhance your site's capabilities.</p>
                        </ul>
                        <ul className="smdservice-2">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h6>USER-FRIENDLY INTERFACE</h6>
                            <p>One5 Digital understands the importance of user experience in e-commerce. A cluttered or confusing website can lead to frustrated customers and abandoned shopping carts. That's why their expert team focuses on creating intuitive and user-friendly interfaces.</p>
                        </ul>
                        <ul className="smdservice-3">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h6>MOBILE-OPTIMIZED DESIGN</h6>
                            <p>In today's mobile-driven world, it is vital to have a website that performs flawlessly across various devices. One5 Digital excels in creating responsive designs that adapt seamlessly to different screen sizes and resolutions.</p>
                        </ul>
                        <ul className="smdservice-4">
                            {/* <img src="./server_One5Digital.png" alt="Loading..." /> */}
                            <h6>SEO-FRIENDLY</h6>
                            <p>One5 Digital understands the importance of search engine optimization (SEO) in driving organic traffic to your e-commerce store. Their developers follow industry best practices to ensure that your WooCommerce website is search engine friendly.</p>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className='smdesc'>
                <p className='smp'>By leveraging One5 Digital's social media advertising services, businesses can increase their brand's reach and engagement on social media, ultimately driving more traffic and sales to their website.</p>
                <p className='smp'>In conclusion, social media advertising has become essential to any successful digital marketing strategy. With One5 Digital's social media advertising services in Melbourne, businesses can create an effective social media marketing plan that helps them achieve their business objectives. So why wait? Contact One5 Digital today to boost your brand's online presence and reach your target audience on social media!</p>
            </div> */}
        </div>
    )
}

export default WOODesc
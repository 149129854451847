import React from 'react';
import './NTexp.css';

function NTexp() {
    return (
        <div className="ntexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Natural by Tara's website has the elegant and rich look that represents the brand in it's own way. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="nt-video" autoPlay loop muted>
                    <source src="./NatureByTara_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./NatureByTara_One5Digital.gif" alt= "Loading..." className="nt-video"  />
            </div>
        </div>
    )
}

export default NTexp

import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-wrapper">
                <ul className="addf-1">
                    <h6>MELBOURNE</h6>
                    <p>Tarneit VIC 3029, Australia.</p>
                    <div className="call1">
                        <a className="call1" href="tel:0430176036"><img className="call-icon" src="./phone-icon_One5Digital.png" alt="Phone Icon" /> 0430 176 036 </a>
					</div>
					<ul className="addf-2">
						<h6>KENTUCKY</h6>
						<p>3900 Crossby Dr, 2524, Lexington,  KY 40515, USA.</p>
						<div className="call2">
							<a className="call2" href="tel:7866140991"><img className="call-icon" src="./phone-icon_One5Digital.png" alt="Phone Icon" /> + 1 786 614 9091 </a> 
						</div> 
					</ul>
                </ul>
                <ul className="site-links">
                    <li><Link to="/" className="site-links">HOME</Link></li>
                    <li><Link to="/Projects" className="site-links">PROJECTS</Link></li>
                    <li><Link to="/Software_Development" className="site-links">SOFTWARE DEVELOPMENT</Link></li>
                    <li><Link to="/Services" className="site-links">SERVICES</Link></li>
                    <li><Link to="/Shopify_Website_Design" className="site-links">SHOPIFY WEBSITE DESIGN</Link></li>
                    <li><Link to="/About_One5_Digital" className="site-links">ABOUT</Link></li>
                    <li><Link to="/Contact_One5_Digital" className="site-links">CONTACT</Link></li>
					<ul className="follow-us">
						<h6>FOLLOW US:</h6>
						<a className="social" href="https://www.instagram.com/one5digital/" target="blank"><i class="fab fa-instagram"></i></a>
						<a className="social" href="https://au.linkedin.com/in/prince-patel" target="blank"><i class="fab fa-linkedin-in"></i></a>
						<a className="social" href="https://g.page/r/CdX-iKJfE8rSEAo" target="blank"><i class="fas fa-map-marker-alt"></i></a>
					</ul>
                </ul>
				<ul className="addf-2">
                    <h6>KENTUCKY</h6>
                    <p>3900 Crossby Dr, 2524, Lexington,  KY 40515, USA.</p>
                    <div className="call2">
                        <a className="call2" href="tel:7866140991"><img className="call-icon" src="./phone-icon_One5Digital.png" alt="Loading..." /> + 1 786 614 9091 </a> 
					</div> 
				</ul>
                <ul className="follow-us">
                    <h6>FOLLOW US:</h6>
                    <a className="social" href="https://www.instagram.com/one5digital/" target="blank"><i class="fab fa-instagram"></i></a>
                    <a className="social" href="https://au.linkedin.com/in/prince-patel" target="blank"><i class="fab fa-linkedin-in"></i></a>
                    <a className="social" href="https://g.page/r/CdX-iKJfE8rSEAo" target="blank"><i class="fas fa-map-marker-alt"></i></a>
                </ul>
            </div>
            <div className="copyright">
                <div className="copy-left">
                    <Link to="/TermsandConditions" className="copy-left">
                        <p className="left-p1">Terms & Conditions</p>
                    </Link>
                </div>
                <div className="copy-right">
                    <Link to="/Sitemap">
                    <p className="right-s">Sitemap</p></Link>
                    <p className="right-p">Copyright ©2017 All rights reserved by one5 digital</p>
                </div>
            </div>
        </div>
    )
}

export default Footer

import React from 'react';
import './DGexp.css';

function DGexp() {
    return (
        <div className="dgexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>At Dr. Green Cosmetic Group's website we created simple and easy to use design for their customers. Homepage has main banner, Details about client, Treatments they offer, Clinic timings, Their professional team details, and contact form. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="dg-video" autoPlay loop muted>
                    <source src="./DrGreen_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./DrGreen_One5Digital.gif" alt= "Loading..." className="dg-video"  />
            </div>
        </div>
    )
}

export default DGexp

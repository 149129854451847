import React from 'react';
import './Ecomchooseus.css';
import { Link } from 'react-router-dom';

function Ecomchooseus() {
    return (
        <div className="ecomus">
            <div>
                <h1>WHY CHOOSE ONE5 DIGITAL</h1>
                <p>We are a full service digital agency so we provide a range of strategic, creative 
                and technical solutions.</p>
            </div>
            <div className="ecomus-wrapper">
                <ul className="ecomus-1">
                    <div>
                        <img src= "./computer_One5Digital.png" alt= "We Are Developers & Digital Marketer" />
                        <h6>WE ARE DEVELOPERS<br/> & DIGITAL MARKETER</h6>
                    </div>
                </ul>
                <ul className="ecomus-2">
                    <div>
                        <img src= "./practice_One5Digital.png" alt= "10+ Years of Experience" />
                        <h6>10+ YEARS<br/> OF EXPERIENCE</h6>
                    </div>
                </ul>
                <ul className="ecomus-3">
                    <div>
                        <img src= "./group_One5Digital.png" alt= "We Work With Different Platforms" />
                        <h6>WE WORK WITH<br/> DIFFERENT PLATFORMS</h6>
                    </div>
                </ul>
                <ul className="ecomus-4">
                    <div>
                        <img src= "./online-shop_One5Digital.png" alt= "Experts in E-Commerce" />
                        <h6>EXPERTS<br/> IN ECOMMERCE</h6>
                    </div>
                </ul>
            </div>
        </div>
    )
}

export default Ecomchooseus

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './CPabout.css';
import { Link } from 'react-router-dom';

function CPabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'cpabout'>
            <div className = 'cpabout-container'>
				<div className = 'cpaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>CLEAN POWER CO</h1>
                </div>
                <div className = 'cpaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'cpabout-desc'>Clean Power Co created by long term business partners who have been in the industry since 2009 working for a number of today’s leading solar companies.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='cpabout-btn'>
                        <a href="https://www.cleanpowerco.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="cpaboutcol-right">
                    <img className="cpimg" src= "./CleanpowerAbout_One5Digital.png" alt= "Loading..." />
                    <img className="cpimg2" src= "./CleanpowerAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default CPabout

import React from 'react';
import '../App.css';
import Softwaredev from '../Softwaredev';
import Solutions from '../Solutions';
import Benefits from '../Benefits';
import Industries from '../Industries';
import Devprocess from '../Devprocess';
import Testimonials from '../Testimonials';
import Getintouch2 from '../Getintouch2';
import {Helmet} from "react-helmet";

function Software_Development() { 
    return (
        <React.Fragment>
			<Helmet> 
				<title>Custom Software Development Services in Melbourne | One5</title>
				<meta name="description" content="One5 is one of the leading custom software development company in Melbourne, Australia. We offer a wide range of customized software to fulfil your specific operational requirements." />
				<meta name="keywords" content="Software Development, Best Custom Software Development, Custom Software Development, Custom Software, Development Services, Custom Software Development Melbourne, Custom Software Development Australia, Software Development Company, Custom Software Development Company" />
			</Helmet>
            <Softwaredev />
            <Solutions />
            <Benefits />
            <Industries />
            <Devprocess />
            <Testimonials />
            <Getintouch2 />
        </React.Fragment>
    )
}

export default Software_Development;

import React from 'react';
import './SBdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SBdesc() {
    return (
        <div className="sbdesc">
            <div className="sbdesc-cat2">
                <div className="sbdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>STAGED HOMES</h1>
                    <p>Melissa helps to maximize your home’s sales potential and minimize your time on the market 
                        by transforming spaces at minimal expence, to amaze prospective buyers with a lasting first impression.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Staged_Homes_by_Melissa">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="sbdesc2-right">
                <img className="sbdesc2ri" src="./StagedHomesProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default SBdesc

import React from 'react';
import WPabout from '../WPabout';
import WPdesc from '../WPdesc';
import WPexp from '../WPexp';
import WPpro from '../WPpro';
import { Helmet } from "react-helmet";

function Wilkinson_Property_Service() {
  return (
    <React.Fragment>
        <Helmet>
            <title>Wilkinson Property Serivce | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Axlefix project provides mechanical repairs, servicing and maintenance for all vehicle types. Visit us online for more information or to book a service." />
            <meta name="keywords" content="Axlefix, Axlefix Project, One5's Axlefix Project" />
        </Helmet>
        <WPabout />
        <WPexp />
        <WPpro />
        <WPdesc />
    </React.Fragment>
  )
}

export default Wilkinson_Property_Service
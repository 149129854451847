import React from 'react';
import './SMDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SMDesignDesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our social media advertising services include:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            <img src="./graphic-design_One5Digital.png" alt="Loading..." />
                            <h6>SOCIAL MEDIA STRATEGY</h6>
                            <p className="smd-desc">One5 Digital's team of experts works closely with clients to create a social media strategy that aligns with their business goals and objectives. This includes identifying the target audience, determining the best social media platforms, and creating a content calendar.
                            </p>
                        </ul>
                        <ul className="smdservice-2">
                            <img src="./responsive_One5Digital.png" alt="Loading..." />
                            <h6>AD CREATION</h6>
                            <p className="smd-desc">One5 Digital's creative team creates compelling ad content that resonates with the target audience. This includes eye-catching visuals, engaging copy, and compelling calls to action.</p>
                        </ul>
                        <ul className="smdservice-3">
                            <img src="./website_One5Digital.png" alt="Loading..." />
                            <h6>AD MANAGEMENT</h6>
                            <p className="smd-desc">One5 Digital's social media experts manage and monitor ad campaigns to ensure they deliver the desired results. They continually optimize ad content and targeting to improve performance and maximize ROI.</p>
                        </ul>
                        <ul className="smdservice-4">
                            <img src="./server_One5Digital.png" alt="Loading..." />
                            <h6>ANALYTICS AND REPORTING</h6>
                            <p className="smd-desc">One5 Digital provides clients with detailed analytics and reporting on the performance of their social media advertising campaigns. This includes metrics such as reach, engagement, and conversions, allowing clients to make informed decisions and adjust their strategy as needed.</p>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='smdesc'>
                <p className='smp'>By leveraging One5 Digital's social media advertising services, businesses can increase their brand's reach and engagement on social media, ultimately driving more traffic and sales to their website.</p>
                <p className='smp'>In conclusion, social media advertising has become essential to any successful digital marketing strategy. With One5 Digital's social media advertising services in Melbourne, businesses can create an effective social media marketing plan that helps them achieve their business objectives. So why wait? Contact One5 Digital today to boost your brand's online presence and reach your target audience on social media!</p>
            </div>
        </div>
    )
}

export default SMDesignDesc
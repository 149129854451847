import React from 'react';
import './VPDesignAbout.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function VPDesignAbout() {
    return (
        <div className="VPDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>VPS MANAGED HOSTING</h1>
                    <h6>One5 Digital Offers VPS Managed Hosting Services in Melbourne</h6>
                    <p>When it comes to hosting your website, many different options are available. Virtual Private Server (VPS) hosting is becoming an increasingly popular choice for businesses and individuals, offering a range of benefits, including increased security, flexibility, and control. However, managing a VPS can be complex and time-consuming, which is where One5 Digital comes in.</p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VPDesignAbout
import React from 'react';
import LDesignAbout from '../LDesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import LDesignDesc from '../LDesignDesc';
import LDesignDesc2 from '../LDesignDesc2';
import LDesign_contact from '../LDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Logo_Design() {
  return (
    <React.Fragment> 
        <Helmet> 
            <title>Custom Logo Design Services Australia | One5 Graphic Design Agency</title>
            <meta name="description" content="One5 Graphic Design Agency offers professional custom logo design services in Australia. We provide unique and creative logo designs to help businesses stand out and establish their brand identity. Contact us today for a free consultation!" />
            <meta name="keywords" content="logo design, custom logo design, professional logo design, graphic design, branding" />
        </Helmet>
        <LDesignAbout />
        <LDesignDesc />
        <LDesignDesc2 />
        <FeaturedProject />
        <Testimonials />
        <LDesign_contact />
    </React.Fragment>
  )
}

export default Logo_Design
import React from 'react';
import './SHdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SHdesc() {
    return (
        <div className="shdesc">
            <div className="shdesc-cat2">
                <div className="shdesc2-left">
                <p>───── NEXT CASE STUDY</p>
                    <h1>AUSPORT</h1>
                    <p>Ausport Superstore established in a small warehouse in 1964, Ausport SuperStore has grown steadily to become one 
                        the largest, specialist sporting goods retailers in Australia.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Ausport_Superstore">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div className="shdesc2-right">
                <img className="shdesc2ri" src="./AusportProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default SHdesc

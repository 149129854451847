import React from 'react';
import './ARdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function ARdesc() {
    return (
        <div className="ardesc">
            <div className="ardesc-cat2">
                <div className="ardesc2-left">
                <p data-aos='fade-up' >───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>SN'S BABYCARE</h1>
                    <p data-aos='fade-up'>A website for manufacturer of 100% biodegradable and 
                    sustainably sourced bamboo wipes.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/SnS_Baby_Care">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="ardesc2-right">
                <img className="ardesc2ri" src="./SnSProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default ARdesc

import React from 'react';
import './STDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function STDesignDesc() {
    return (
        <div className="STDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our stationary design service includes:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>BUSINESS CARDS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom business cards that accurately reflect your brand identity and values. Our business cards are designed to be visually appealing and effective in promoting your business.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>LETTERHEADS</h6>
                            <p data-aos='fade-up' className="service-desc">We can design custom letterheads that accurately reflect your brand identity and values. Our letterheads are designed to be professional, visually appealing, and effective in communicating with your clients and customers.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>ENVELOPES</h6>
                            <p data-aos='fade-up' className="service-desc">We can design custom envelopes that accurately reflect your brand identity and values. Our envelopes are designed to be professional, visually appealing, and effective in communicating with your clients and customers.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>COMPLIMENT SLIPS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom compliment slips that accurately reflect your brand identity and values. Our compliment slips are designed to be professional, visually appealing, and effective in leaving a lasting impression on your clients and customers.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default STDesignDesc
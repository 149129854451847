import React from 'react';
import SDesignAbout from '../SDesignAbout';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import SDesignDesc from '../SDesignDesc';
import SDesignDesc2 from '../SDesignDesc2';
import SDesign_contact from '../SDesign_contact';
import FeaturedProject from '../FeaturedProject';

function Social_Media_Design() {
  return (
    <React.Fragment> 
        <Helmet> 
            <title>Social Media Design Services | One5 Graphic Design Agency</title>
            <meta name="description" content="One5 Graphic Design Agency offers professional social media design services in Australia. We create custom graphics that are optimized for social media platforms to help you engage with your audience and promote your brand. Our experienced designers can help you create visually appealing and shareable social media content that communicates your message effectively. Contact us today for a free consultation and take your social media game to the next level!" />
            <meta name="keywords" content="social media design, social media graphics, custom graphics, visual content, branding" />
        </Helmet>
        <SDesignAbout />
        <SDesignDesc />
        <SDesignDesc2 />
        <FeaturedProject />
        <Testimonials />
        <SDesign_contact />
    </React.Fragment>
  )
}

export default Social_Media_Design
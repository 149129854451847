import React from 'react';
import './VDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function VDesignDesc() {
    return (
        <div className="VDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our visual design and services include:</h1>
                    <div className="vdservice-wrapper">
                        <ul className="vdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." />  */}
                            <h1 className="ecno">01</h1>
                            <h6>LOGO CREATION</h6>
                            <p className="vd-desc">A logo is often the first point of contact between a business and its audience. It should represent the essence of the brand, be memorable, and stand the test of time. One5 Digital's designers understand the importance of creating a logo that captures a brand's identity and creates a lasting impression.
                            </p>
                            <p className="vd-desc">They take a collaborative approach to logo design, starting with a thorough consultation to understand the client's vision and target audience. From there, they present multiple design options and work with the client to refine the chosen design until it's perfect.
                            </p>
                        </ul>
                        <ul className="vdservice-2">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">02</h1>
                            <h6>BRANDING STRATEGIES</h6>
                            <p className="vd-desc">A strong brand identity extends beyond a logo. It's a collection of elements, including typography, color schemes, imagery, and messaging, that work together to create a cohesive and consistent brand image. One5 Digital's branding strategies aim to enhance a business's brand recognition, engagement and loyalty.
                            </p>
                            <p className="vd-desc">Their team works closely with clients to create a comprehensive brand style guide that outlines how their brand should be represented across all platforms. They help clients to determine their brand's voice, develop messaging that resonates with their audience, and create a visual language that reflects their brand's personality.
                            </p>
                        </ul>
                        <ul className="vdservice-3">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">03</h1>
                            <h6>DIGITAL DESIGN</h6>
                            <p className="vd-desc">In today's digital world, having a strong online presence is critical. One5 Digital's digital design services help businesses to create a visually appealing and functional online presence. From website design to social media graphics, they ensure that a business's online presence aligns with their brand identity and effectively communicates their message.
                            </p>
                            <p className="vd-desc">One5 Digital's visual identity design services are comprehensive and flexible, tailored to meet each client's unique needs. Whether you're starting from scratch or looking to refresh your existing brand, their team is equipped to guide you through the process and create a visual identity that will elevate your business to the next level.
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VDesignDesc
import React from 'react';
import './BDexp.css';

function BDexp() {
    return (
        <div className="bdexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Bear Dog Walking Service's website design is simple and easy to use to the users. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="bd-video" autoPlay loop muted>
                    <source src="./Beardog_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Beardog_One5Digital.gif" alt= "Loading..." className="bd-video"  />
            </div>
        </div>
    )
}

export default BDexp

import React from 'react';
import './HJexp.css';

function HJexp() {
    return (
        <div className="hjexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>The Australian Hardware Journal's website design that shows all the details in easy way. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="hj-video" autoPlay loop muted>
                    <source src="./HardwareJournal_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./HardwareJournal_One5Digital.gif" alt= "Loading..." className="hj-video"  />
            </div>
        </div>
    )
}

export default HJexp

import React from 'react';
import './TestimonialPage.css';

function TestimonialPage() {
    return (
        <div className="testimonial" id="testimonials">
            <h6 data-aos='fade-up' data-aos-duration="500" style={{textAlign:'center'}}>TESTIMONIALS</h6>
            <h1 data-aos='fade-up' data-aos-duration="1000">WHAT CLIENTS SAY</h1>
            <div className="test-content ">
                <div>
                    <ol className="test-list tlist">
                        <li data-aos='fade-up' data-aos-duration="1500">
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent h3"><p className="test-p">
                                I love working with Prince, he is always so helpful with any questions I have. I am very happy with the 
                                service he has provided, and does it all in a kind nature, uses his initiative and gives genuine advice.
                                </p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Tara Grimes</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent"><p>
                                It was a great experience working with Prince, he delivered beyond our expectations on our website and we are very happy with the work he has done.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Oscar Tsolak</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up' > 
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                We have made our website through one5 digital. Everyone loved it. Prince and his team has done a fantastic job in making this website look attractive and 
                                easy to operate from backend. Prince is very professional and always ready to help me whenever needed. I would definitely recommend one5 digital for taking your business e-commerce.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Dharm patel</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                Prince and his team went above and beyond when creating my website , the attention to detail is next to none. I would highly recommend One5 Digital to anyone who wants to make their dream a reality.
                                5 STARS ALL DAY LONG !
                                </p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Tarek Rabah</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Prince created a website for my author client, and we were extremely impressed with his professionalism and the website he created.  Thank you Prince.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Kerry McDuling</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                We have made customised online software for our Artificial Grass Wholsale business with one5 digital. We are very happy with their work. Very reliable and professional to deal with Highly recommended.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Jenish Kardani</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                Wonderful to work with Prince. Extremely reliable with a creative flare, able to interpret ideas and excellent customer service. Will definitely  be using One5 Digital again.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Hardware Journal</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Very good service,
                                Very helpful,
                                Highly recommended</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Jay patel</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Prince was professional and extremely easy to work with. From our initial meeting to making our new website go live, every step of the process went smoothly and stress free. We are extremely pleased with the end result of our website. It is exactly what we had envisioned. The design Price gave us was fresh, clean, modern and easy to operate. Prince really listened to what and how we wanted the website to look as well as giving his expert advice on design features and layout. Prince was also extremely patient in explaining how things worked, helping us set up our new email and was always prompt with responses to my many requests to have things tweaked and changed.
                                Thanks so much Prince!
                                Farmers Paradise</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Parth Shah</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Excellent service and delivered a  professionally presented website for our business. Highly recommend their services. </p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Patrick McFadden</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                Prince has done an immensely great job for creating  a logo for my business . He has very good communication skills and is also very responsive. Kudos to the One Five's team for their great service.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Jaswant Singh Khangura</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="1500">
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Great customer service, quick response and very professional.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">P Flow</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up' data-aos-duration="1500">
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Job done nicely and effectively. Has thorough knowledge about his job . Very clear and to the point explanation.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Raj Singh</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                Prince is amazing!!! I absolutely love my website and feel like he nailed the brief with very minimal amendments. Prince was so quick to respond and was often waiting on me to get back to him so he could keep going. He is clearly passionate about what he does and it comes through in his work. I contacted at least 5 other web designers who didn’t even respond to my enquiry. I couldn’t rate One5 Digital and Prince any higher. You won’t be disappointed.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Melissa Reid</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                One5 Digital were amazing, sat down with us on a number of occasions to guide and suggest ideas based on what we were wanting, they did an amazing job on our website and even the aftercare has been great. I highly recommend.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Steve Lukic</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                Prince and his team  developed our website and online system to suit our requirements perfectly. Ongoing support and response time is amazing! I highly recommend One 5 Digital.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Lisa Soliman</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                If you are after someone who can understand your business quickly, convert your abstract ideas into tangible website outcomes and all of that very fast, then you need to speak to One5 digital. Can't fault their responsiveness, promptly returned phone calls and emails and a deep understanding of their work. Exceptional value for my business.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Amit Verma</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble bemp">
                                <div className="bcontent "><p className="test-p">
                                "AWESOME EXPERIENCE"
                                Prince was very informative, professional, reasonably priced, and diligent. I never doubted that I would receive the quality website I desired from the get go.
                                Would highly recommend to anyone that is looking for a professional website with little hassle and cost, and a fast turnaround too.
                                Thank you so much.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Community Service Aid</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                He is a good bloke! He knows what he is doing. We're ranking on first page on Google now. Highly recommend Prince - One5 Digital</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Jeff Cain</span></cite>
                            </blockquote>
                        </li>
                        <li data-aos='fade-up'>
                            <blockquote className="blockquote b-bubble ">
                                <div className="bcontent "><p >
                                One of my friends has suggested me to opt for their services for website development. I must say that I made a right choice. They have provided with me an attractive website.</p></div>
                                <cite className="b-cite">
                                    <span className="spanc">Nathan Afif</span></cite>
                            </blockquote>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default TestimonialPage

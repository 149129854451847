import React from 'react';
import '../App.css';
import {Helmet} from "react-helmet";
import SDabout from '../SDabout';
import SDdetail from '../SDdetail';
import SDservices from '../SDservices';
import SDwork from '../SDwork';
import SDtestimonials from '../SDtestimonials';
import SDpartners from '../SDpartners';
import SDcontact from '../SDcontact';

    function Shopify_Website_Design() {
        return (
            <React.Fragment>
                <Helmet> 
                    <title>Shopify Website Design</title>
                    <meta name="description" content="One5 Digital creates custom software development in Melbourne for major clients world wide. Warehouse Management Software, Project Management, Prescription Software, Quoting Software." />
                    <meta name="keywords" content="Shopify Website Design Melbourne" />
                </Helmet>
                <SDabout />
                <SDdetail />
                <SDservices />
                <SDwork />
                <SDpartners />
                <SDtestimonials />
                <SDcontact />
            </React.Fragment>
        )
    }

    export default Shopify_Website_Design;

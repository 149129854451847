import React from 'react';
import './GBexp.css';

function GBexp() {
    return (
        <div className="gbexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>The Grand Band's website design has the same grand and premier look like the brand. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="gb-video" autoPlay loop muted>
                    <source src="./GrandBand_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./GrandBand_One5Digital.gif" alt= "Loading..." className="gb-video"  />
            </div>
        </div>
    )
}

export default GBexp

import React from 'react';
import SWabout from '../SWabout';
import SWdesc from '../SWdesc';
import SWexp from '../SWexp';
import SWpro from '../SWpro';
import {Helmet} from "react-helmet";

function Samantha_J_Willis() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Samantha Jwillis Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Samantha Wills jewelry collection project, with bold and luxurious designs. Visit us for stylish and affordable jewelry." />
            <meta name="keywords" content="Samantha Jwillis, Samantha Jwillis Project, One5's Samantha Jwillis Project" />
        </Helmet>
            <SWabout />
            <SWexp />
            <SWpro />
            <SWdesc />
        </React.Fragment>
    )
}

export default Samantha_J_Willis

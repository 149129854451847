import React from 'react';
import '../App.css';
import  AboutPage from '../AboutPage';
import Approach from '../Approach';
import Expertize from '../Expertize';
import Portfolio from '../Portfolio';
import Testimonials from '../Testimonials';
import Getintouch from '../Getintouch';
import {Helmet} from "react-helmet";

function About_One5_Digital() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>About One5 - IT Solution Agency in Melbourne </title>
				<meta name="description" content="One5 is an IT solution and digital marketing agency in Melbourne, Australia, that's been helping businesses succeed online for over a decade." />
				<meta name="keywords" content="About One5, Digital Marketing Agency, Digital Marketing Agency in Australia, IT Solution Agency, IT Solution Agency in Melbourne" />
			</Helmet>
            <AboutPage />
            <Expertize />
            <Approach />
            <Portfolio />
            <Testimonials />
            <Getintouch />
        </React.Fragment>
    )
}

export default About_One5_Digital;
import React from 'react';
import './LDesignAbout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function LDesignAbout() {
    return ( 
        <div className="LDesignabout">
            <div className="la-about">
                {/* <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link> */}
                <div className="ld-desc">
                    <h1>LOGO DESIGN</h1>
                    <h6>Welcome to One5 Digital's Logo Design Service!</h6>
                    <p>Your logo is the visual representation of your brand, it's the face of your business. It's crucial to have a professional and memorable logo that accurately represents your business values and mission. At One5 Digital, we specialize in creating custom logos that are tailored to your unique brand identity.
                    </p>
                    <div className='ldabout-btn'>
                        <Link excat to="/Contact_One5_Digital">
                            <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LDesignAbout

import React from 'react';
import './DSexp.css';

function DSexp() {
    return(
        <div className="dsexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>At Dr. Green Cosmetic Shop's website we created simple and easy to use design for their customers. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                <img src="./DrGreenShop_One5Digital.gif" alt= "Loading..." className="ds-video"  />
            </div>
        </div>
    )
}

export default DSexp
import React from 'react';
import Header from '../components/Header';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from '../components/Pages/Home'; 
import Footer from '../components/Footer';
import About_One5_Digital from './Pages/About_One5_Digital';
import Projects from './Pages/Projects';
import Software_Development from './Pages/Software_Development';
import Services from './Pages/Services';
import Contact_One5_Digital from './Pages/Contact_One5_Digital';
import Essentially_Brands from './Pages/Essentially_Brands';
import Logo_Design_Melbourne from './Pages/Logo_Design_Melbourne';
import Website_Design_Melbourne from './Pages/Website_Design_Melbourne';
import Ecommerce_Design_Melbourne from './Pages/Ecommerce_Design_Melbourne';
import SEO_Melbourne from './Pages/SEO_Melbourne';
import Pay_Per_Click_Melbourne from './Pages/Pay_Per_Click_Melbourne';
import ScrollToTop from '../components/ScrollToTop';
import TermsandConditions from './Pages/TermsandConditions';
import Accord_Retail from './Pages/Accord_Retail';
import SnS_Baby_Care from './Pages/SnS_Baby_Care';
import Staged_Homes_by_Melissa from './Pages/Staged_Homes_by_Melissa';
import Ausport_Superstore from './Pages/Ausport_Superstore';
import Venus_Virgin_Tomarz from './Pages/Venus_Virgin_Tomarz';
import Miss_Australia_Tiffany_Shen from './Pages/Miss_Australia_Tiffany_Shen';
import Motor_Components from './Pages/Motor_Components';
import Dr_Green_Cosmetic_Group from './Pages/Dr_Green_Cosmetic_Group';
import Paul_Ryan_Plumbing from './Pages/Paul_Ryan_Plumbing';
import PokeNPop_Collect from './Pages/PokeNPop_Collect';
import The_Grand_Band from './Pages/The_Grand_Band';
import Natural_By_Tara from './Pages/Natural_By_Tara';
import Clean_Power_Co from './Pages/Clean_Power_Co';
import Bear_Dog_Walking_Service from './Pages/Bear_Dog_Walking_Service';
import Samantha_J_Willis from './Pages/Samantha_J_Willis';
import The_Australian_Hardware_Journal from './Pages/The_Australian_Hardware_Journal';
import Power_Equipment_Australasia from './Pages/Power_Equipment_Australasia';
import Axlefix from './Pages/Axlefix';
import The_Artisans_Bottega from './Pages/The_Artisans_Bottega';
import Amazing_Amazon from './Pages/Amazing_Amazon';
import Langys from './Pages/Langys';
import Trinity_Maintenance_Group from './Pages/Trinity_Maintenance_Group';
import Cladcraft from './Pages/Cladcraft';
import Michael_Gazzola from './Pages/Michael_Gazzola';
import Testimonials from './Pages/Testimonials';
import Sitemap from './Pages/Sitemap';
import AOS from "aos";
import "aos/dist/aos.css";
import GoToTop from './GoToTop';
import Page_Not_Found from './Pages/Page_Not_Found';
import Campus_Radar from './Pages/Campus_Radar';
import Dr_Green_Shop from './Pages/Dr_Green_Shop';
import Shopify_Website_Design from './Pages/Shopify_Website_Design';
import Shopify_Projects from './Pages/Shopify_Projects';
import Super_Star_Boards from './Pages/Super_Star_Boards';
import Logo_Design from './Pages/Logo_Design';
import Website_Design from './Pages/Website_Design';
import Graphic_Design from './Pages/Graphic_Design';
import Social_Media_Design from './Pages/Social_Media_Design';
import Branding from './Pages/Branding';
import Stationary_Design from './Pages/Stationary_Design';
import Visual_Design from './Pages/Visual_Design';
import SEO_Design from './Pages/SEO_Design';
import Red_Notice_Website_Hacked_Retrieval from './Pages/Red_Notice_Website_Hacked_Retrieval';
import Malicious_Code_Removal from './Pages/Malicious_Code_Removal';
import SSL_Certification_Installation from './Pages/SSL_Certification_Installation';
import VPS_Managed_Hosting from './Pages/VPS_Managed_Hosting';
import Social_Media_Advertising from './Pages/Social_Media_Advertising';
import Malware_Removal from './Pages/Malware_Removal';
import Bing_Ads from './Pages/Bing_Ads';
import ECommerce_Website_Development from './Pages/ECommerce_Website_Development';
import Google_PPC from './Pages/Google_PPC';
import PHP_Website_Development from './Pages/PHP_Website_Development';
import Static_HTML_Website_Development from './Pages/Static_HTML_Website_Development';
import Woo_Commerce_Website_Development from './Pages/Woo_Commerce_Website_Development';
import Shopify_Website_Development from './Pages/Shopify_Website_Development';
import Magento_Website_Development from './Pages/Magento_Website_Development';
import Beat_The_Rest_Movers from './Pages/Beat_The_Rest_Movers';
import Purity_Plumbing from './Pages/Purity_Plumbing';
import Wilkinson_Property_Service from './Pages/Wilkinson_Property_Service';
import Your_Online_Pet_Store from './Pages/Your_Online_Pet_Store';
AOS.init();


// const NoMatch = ({ location }) => (
//   <div>
//         <h3>No match for <code>{location.pathname}</code> can be found.</h3>
//   </div>
// )



function App() {
  return (
    <Router>
	  <GoToTop />
      <ScrollToTop />
      <Header />
      <Switch>
        <Route path='/' exact component= {Home} />
        <Route path='/About_One5_Digital' exact component= {About_One5_Digital} />
        <Route path='/Projects' exact component= {Projects} />
        <Route path='/Software_Development' exact component= {Software_Development} />
        <Route path='/Services' exact component= {Services} />
        <Route path='/Testimonials' exact component= {Testimonials} />
        <Route path='/Contact_One5_Digital' exact component= {Contact_One5_Digital} />
        <Route path='/Services/Logo_Design_Melbourne' exact component= {Logo_Design_Melbourne} />
        <Route path='/Services/Website_Design_Melbourne' exact component= {Website_Design_Melbourne} />
        <Route path='/Services/Ecommerce_Design_Melbourne' exact component= {Ecommerce_Design_Melbourne} />
        <Route path='/Services/SEO_Melbourne' exact component= {SEO_Melbourne} />
        <Route path='/Services/Pay_Per_Click_Melbourne' exact component= {Pay_Per_Click_Melbourne} />
        <Route path='/Projects/Essentially_Brands' exact component= {Essentially_Brands} />
        <Route path='/Projects/Accord_Retail' exact component= {Accord_Retail} />
        <Route path='/Projects/SnS_Baby_Care' exact component= {SnS_Baby_Care} />
        <Route path='/Projects/Staged_Homes_by_Melissa' exact component= {Staged_Homes_by_Melissa} />
        <Route path='/Projects/Ausport_Superstore' exact component= {Ausport_Superstore} />
        <Route path='/Projects/Venus_Virgin_Tomarz' exact component= {Venus_Virgin_Tomarz} />
        <Route path='/Projects/Miss_Australia_Tiffany_Shen' exact component= {Miss_Australia_Tiffany_Shen} />
        <Route path='/Projects/Motor_Components' exact component= {Motor_Components} />
        <Route path='/Projects/Dr_Green_Cosmetic_Group' exact component= {Dr_Green_Cosmetic_Group} />
        <Route path='/Projects/Paul_Ryan_Plumbing' exact component= {Paul_Ryan_Plumbing} />
        <Route path='/Projects/PokeNPop_Collect' exact component= {PokeNPop_Collect} />
        <Route path='/Projects/The_Grand_Band' exact component= {The_Grand_Band} />
        <Route path='/Projects/Natural_By_Tara' exact component= {Natural_By_Tara} />
        <Route path='/Projects/Bear_Dog_Walking_Service' exact component= {Bear_Dog_Walking_Service} />
        <Route path='/Projects/Samantha_J_Willis' exact component= {Samantha_J_Willis} />
        <Route path='/Projects/The_Australian_Hardware_Journal' exact component= {The_Australian_Hardware_Journal} />
        <Route path='/Projects/Power_Equipment_Australasia' exact component= {Power_Equipment_Australasia} />
        <Route path='/Projects/Axlefix' exact component= {Axlefix} />
        <Route path='/Projects/Clean_Power_Co' exact component= {Clean_Power_Co} />
        <Route path='/Projects/The_Artisans_Bottega' exact component= {The_Artisans_Bottega} />
        <Route path='/Projects/Amazing_Amazon' exact component= {Amazing_Amazon} />
        <Route path='/Projects/Langys' exact component= {Langys} />
        <Route path='/Projects/Cladcraft' exact component= {Cladcraft} />
        <Route path='/Projects/Trinity_Maintenance_Group' exact component= {Trinity_Maintenance_Group} />
        <Route path='/Projects/Michael_Gazzola' exact component= {Michael_Gazzola} />
        <Route path='/Projects/Campus_Radar' exact component= {Campus_Radar} />
        <Route path='/Projects/Dr_Green_Shop' exact component= {Dr_Green_Shop} />
        <Route path='/Projects/Super_Star_Boards' exact component= {Super_Star_Boards} />
        <Route path='/Shopify_Website_Design' exact component= {Shopify_Website_Design} />
        <Route path='/TermsandConditions' exact component= {TermsandConditions} />
        <Route path='/Shopify_Projects' exact component= {Shopify_Projects} />
        <Route path='/Logo_Design' exact component= {Logo_Design} />
        <Route path='/Website_Design' exact component= {Website_Design} />
        <Route path='/Graphic_Design' exact component= {Graphic_Design} />
        <Route path='/Social_Media_Design' exact component= {Social_Media_Design} />
        <Route path='/Branding' exact component= {Branding} />
        <Route path='/Stationary_Design' exact component= {Stationary_Design} />
        <Route path='/Visual_Design' exact component= {Visual_Design} />
        <Route path='/SEO_Design' exact component= {SEO_Design} />
        <Route path='/Red_Notice_Website_Hacked_Retrieval' exact component= {Red_Notice_Website_Hacked_Retrieval} />
        <Route path='/Malicious_Code_Removal' exact component= {Malicious_Code_Removal} />
        <Route path='/SSL_Certification_Installation' exact component= {SSL_Certification_Installation} />
        <Route path='/VPS_Managed_Hosting' exact component= {VPS_Managed_Hosting} />
        <Route path='/Social_Media_Advertising' exact component= {Social_Media_Advertising} />
        <Route path='/Malware_Removal' exact component= {Malware_Removal} />
        <Route path='/Bing_Ads' exact component= {Bing_Ads} />
        <Route path='/ECommerce_Website_Development' exact component= {ECommerce_Website_Development} />
        <Route path='/Google_PPC' exact component= {Google_PPC} />
        <Route path='/PHP_Website_Development' exact component= {PHP_Website_Development} />
        <Route path='/Static_HTML_Website_Development' exact component= {Static_HTML_Website_Development} />
        <Route path='/Woo_Commerce_Website_Development' exact component= {Woo_Commerce_Website_Development} />
        <Route path='/Shopify_Website_Development' exact component= {Shopify_Website_Development} />
        <Route path='/Magento_Website_Development' exact component= {Magento_Website_Development} />  
        <Route path='/Projects/Beat_The_Rest_Movers' exact component= {Beat_The_Rest_Movers} />
        <Route path='/Projects/Purity_Plumbing' exact component= {Purity_Plumbing} />
        <Route path='/Projects/Wilkinson_Property_Service' exact component= {Wilkinson_Property_Service} />
        <Route path='/Projects/Your_Online_Pet_Store' exact component= {Your_Online_Pet_Store} />
        <Route path='/Sitemap' exact component= {Sitemap} />
        <Route component= {Page_Not_Found} />
        {/* <Route component={NoMatch} /> */}
      </Switch>
      <Footer />
    </Router>  
  );
}



export default App

import React from 'react';
import './PRdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function PRdesc() {
    return (
        <div className="prdesc">
            <div className="prdesc-cat2">
                <div className="prdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>POKE N POP COLLECT</h1>
                    <p data-aos='fade-up'> Poke N Pop Collect is a retailer of toys, memorabilia and other branded merchandise. For the avoidance of doubt, Poke N Pop Collect is not affiliated with any of the brands of products. </p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/PokeNPop_Collect">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="prdesc2-right">
                <img className="prdesc2ri" src="./PokenPopProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default PRdesc

import React from 'react';
import SEOabout from '../SEOabout';
import SEOdetails from '../SEOdetails';
import SEOsteps from '../SEOsteps';
// import SEOplans from '../SEOplans';
import {Helmet} from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';

function SEO_Melbourne() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Best SEO Services Company in Melbourne, Australia | One5</title>
				<meta name="description" content="One5 is a best SEO service company in Melbourne, Australia. Our highly experienced SEO expert team provides top quality and result oriented organic SEO services at the best price in Melbourne." />
				<meta name="keywords" content="SEO Company in Melbourne, Best SEO Company in Melbourne, SEO Company in Australia, SEO Services Melbourne, SEO Agency in Melbourne, SEO Services Australia, Search Engine Optimization Melbourne, Search Engine Optimization Australia" />
			</Helmet>
            <SEOabout />
            <SEOdetails />
            <SEOsteps />
            {/* <SEOplans /> */}
            <FeaturedProject />
            <Testimonials />
        </React.Fragment>
    )
}

export default SEO_Melbourne

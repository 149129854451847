import React from 'react';
import ARabout from '../ARabout';
import ARdesc from '../ARdesc';
import ARexp from '../ARexp';
import ARpro from '../ARpro';
import {Helmet} from "react-helmet";

function Accord_Retail() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Accord Retail Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Accord Retail project increased their online sales with our e-commerce strategies." />
            <meta name="keywords" content="Accord Retail, Accord Retail project, One5's Accord Retail project" />
        </Helmet>
            <ARabout />
            <ARexp />
            <ARpro />
            <ARdesc />
        </React.Fragment>
    )
}

export default Accord_Retail

import React from 'react';
import './MWDDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function MWDDesc() {
    return (
        <div className="WDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our magento website development and services include:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>CUSTOM WEBSITE DESIGN</h6>
                            <p data-aos='fade-up' className="service-desc">Their creative team designs visually stunning and intuitive user interfaces that reflect your brand identity and captivate your target audience.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>RESPONSIVE DEVELOPMENT</h6>
                            <p data-aos='fade-up' className="service-desc">With the increasing use of mobile devices, responsive design is crucial. One5 Digital ensures your Magento website is optimized for seamless browsing across various devices and screen sizes.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>THIRD PARTY INTEGRATIONS</h6>
                            <p data-aos='fade-up' className="service-desc">If you require integrations with payment gateways, shipping providers, CRM systems, or any other third-party software, One5 Digital has the expertise to seamlessly integrate them into your Magento store.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>EXTENSION DEVELOPMENT</h6>
                            <p data-aos='fade-up' className="service-desc">Extend the functionality of your Magento store with custom-developed extensions that cater to your unique business requirements.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>PERFORMANCE OPTIMIZATION</h6>
                            <p data-aos='fade-up' className="service-desc">Slow-loading websites can lead to a loss of customers and revenue. One5 Digital optimizes your Magento store for maximum performance, ensuring a fast and smooth user experience.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>SEO-FRIENDLY DEVELOPMENT</h6>
                            <p data-aos='fade-up' className="service-desc">One5 Digital implements best practices for on-page SEO, helping your Magento website rank higher in search engine results and driving organic traffic to your store.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>ONGOING SUPPORT</h6>
                            <p data-aos='fade-up' className="service-desc">Their dedicated support team is available to address any technical issues and provide regular maintenance to keep your Magento website running smoothly.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MWDDesc
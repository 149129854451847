import React from 'react';
import './SDesignDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SDesignDesc() {
    return (
        <div className="SDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our social media design service includes:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA POSTS</h6>
                            <p data-aos='fade-up' className="service-desc">Our designers can create custom social media posts that are visually appealing and informative. We'll work with you to create posts that align with your brand identity and messaging.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA BANNERS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom banners for your social media profiles that accurately reflect your brand identity and values. Our banners are designed to grab the attention of your target audience and help you stand out on social media platforms.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA ADS</h6>
                            <p data-aos='fade-up' className="service-desc">We can design custom social media ads that promote your products or services and drive traffic to your website. Our ads are designed to be visually appealing and effective in converting leads into customers.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>SOCIAL MEDIA GIFS</h6>
                            <p data-aos='fade-up' className="service-desc">We can create custom gifs that help your business stand out on social media platforms. Gifs are a fun and engaging way to showcase your products or services and connect with your target audience.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SDesignDesc
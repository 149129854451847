import React from 'react';
import './VPDesignDesc3.css';

function VPDesignDesc3() {
  return (
    <div className="VPDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./vps2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>One of the great things about One5 Digital's VPS Managed Hosting services is that they are fully customizable. This means that you can choose the resources and specifications that best suit your business, without having to pay for anything you don't need. One5 Digital also offers a range of add-ons, such as SSL certificates and CDN integration, to further enhance your website's performance and security.</p>
            <p>In conclusion, if you are looking for reliable and secure VPS Managed Hosting services in Melbourne, look no further than One5 Digital. With their 99.99% uptime guarantee, 24/7 technical support, and customizable plans, One5 Digital has everything you need to ensure that your website is always available, secure, and performing at its best.</p>
        </div>
    </div>
  )
}

export default VPDesignDesc3
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './Getintouch.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
 
function Getintouch() {
    const [, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="contact">
            <div className="desc">
                <p data-aos='fade-up' className = 'contact-shortdesc'>───── READY TO DISCUSS YOUR NEXT PROJECT?</p>
                <div className="left-contact">
                    <h1 data-aos='fade-up'>LET'S CREATE YOUR <br/> SUCCESS TOGETHER</h1>
                    <div data-aos='fade-up' className='contact-btn'>
                        <Link excat to="/Contact_One5_Digital" >
                            <Button className='btn' buttonStyle='btn--white' >Get in Touch</Button>
                        </Link>
                    </div> 
                </div>
                {/* <div className="right-contact">
                    <img className="red-ball" src= "./red-ball.gif" alt= "Loading..." />
                </div> */}
            </div>
        </div>
    )
}

export default Getintouch

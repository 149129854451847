import React from 'react';
import './WDservice.css';

function WDservice() {
    return (
        <div className="wdservice">
        <div>
            <h1>OUR WEBSITE DESIGN SERVICES</h1>
        </div>
        <div className="wdservice-wrapper">
            <ul className="wdservice-1">
                <img src= "./graphic-design_One5Digital.png" alt= "CUSTOM WEBSITE DESIGN" />
                <h6>CUSTOM WEBSITE DESIGN</h6>
                <p className="wd-desc">A custom website design provides you with a trustworthy digital presence that meets your business needs in terms of quality, branding and usability.
                </p>
            </ul>
            <ul className="wdservice-2">
                <img src= "./responsive_One5Digital.png" alt= "FULLY RESPONSIVE" />
                <h6>FULLY RESPONSIVE</h6>
                <p className="wd-desc">Ensure your website adapts to all screen sizes and devices to increase your customer retention. </p>
            </ul>
            <ul className="wdservice-3">
                <img src= "./website_One5Digital.png" alt= "WEBSITE OPTIMIZATION" />
                <h6>WEBSITE OPTIMIZATION</h6>
                <p className="wd-desc">Increase your leads and conversions with One5 Digital custom website design packages. </p>
            </ul>
            <ul className="wdservice-4">
                <img src= "./server_One5Digital.png" alt= "WEBSITE HOSTING" />
                <h6>WEBSITE HOSTING</h6>
                <p className="wd-desc">Our web hosting services ensure high reliability and uptime, site security and improved SEO and online performance. </p>
            </ul>
        </div>
    </div> 
    )
}

export default WDservice

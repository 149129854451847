import React from 'react';
import './PMdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function PMdesc() {
    return (
        <div className="pedesc">
            <div className="pedesc-cat2">
                <div className="pedesc2-left">
                    <p>───── NEXT CASE STUDY</p>
                    <h1>YOUR ONLINE PET STORE</h1>
                    <p>Explore our extensive selection of foods, toys, treats, and more, designed to provide your beloved pet with the best in nutrition and comfort. Store pride theirselves on speedy and efficient online service. They have all the big brands available for sale online as well as the smaller more unique items for your pet.</p>
                    <div className='project-btn'>
                        <Link exact to="/Projects/Your_Online_Pet_Store">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
                <div className="pedesc2-right">
                    <img className="pedesc2ri" src="./PetStoreProject_One5Digital.png" alt="Loading..." />
                </div>
            </div>
        </div>
    )
}

export default PMdesc
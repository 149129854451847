import React from 'react';
import Sitemappage from '../Sitemappage';

function sitemap() {
    return (
        <React.Fragment> 
            <Sitemappage />
        </React.Fragment>
    )
}

export default sitemap

import React from 'react';
import TMabout from '../TMabout';
import TMdesc from '../TMdesc';
import TMexp from '../TMexp';
import TMpro from '../TMpro';
import {Helmet} from "react-helmet";

function Trinity_Maintenance_Group() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Trinity Maintenance Group Project | One5 Melbourne</title>
            <meta name="description" content="One5's Trinity Maintenance Group Project - Quality Building Maintenance Services in Sydney. Get in touch with us today for all your maintenance needs." />
            <meta name="keywords" content="Trinity Maintenance Group, Trinity Maintenance Group Project, One5's Trinity Maintenance Group Project" />
        </Helmet>
            <TMabout />
            <TMexp />
            <TMpro />
            <TMdesc />
        </React.Fragment>
    )
}

export default Trinity_Maintenance_Group
import React from 'react';
import './Ecomabout.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function Ecomabout() {
    return (
        <div className="ecabout">
            <div className="ec-about">
                <Link exact to="/Services"><p>< img src="../Arrow_One5Digital.png" alt="Loading..." / > BACK TO SERVICES</p></Link>
                <div className="ec-desc">
                    <h1>E-COMMERCE WEBSITE</h1>
                    <p>There are lots of things to be considerate while creating an eCommerce website from intensive development to content, payment, shipping module, product loads,
                     accounting, security, taxes & data integration. It require a team who knows what they are doing and not learning while developing.
                    </p><br/>
                    <p>One5 Digital works with you to understand your business, your products/services, your competitors and most importantly your customers. </p>
                    <div className='ecabout-btn'>
                    <Link excat to="/Contact">
                        <Button className='btn' buttonStyle='btn--primary'>GET A QUOTE</Button>
                    </Link>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default Ecomabout

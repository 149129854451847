import React from 'react';
import Project from '../Project';
import {Helmet} from "react-helmet";

function Projects() {
    return (
        <React.Fragment> 
			<Helmet> 
				<title>Our Projects - One5 Digital Marketing Agency in Melbourne</title>
				<meta name="description" content="One5's portfolio of IT solutions and digital marketing projects demonstrates our commitment to driving business success. See how we can help your business grow." />
				<meta name="keywords" content="One5's portfolio, One5's Projects, Digital Marketing Projects, IT solution Projects" />
			</Helmet>
            <Project />
        </React.Fragment>
    )
}

export default Projects;

import React from 'react';
import CRabout from '../CRabout';
import CRdesc from '../CRdesc';
import CRexp from '../CRexp';
import CRpro from '../CRpro';
import {Helmet} from "react-helmet";

function Campus_Radar() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Campus Radar Project | One5 Digital Agency Melbourne</title>
            <meta name="description" content="One5's Campus Radar project is using mobile app technology to create a more engaging and connected university experience." />
            <meta name="keywords" content="Campus Radar, Campus Radar project, One5's Campus Radar project" />
        </Helmet>
            <CRabout />
            <CRexp />
            <CRpro />
            <CRdesc />
        </React.Fragment>
    )
}

export default Campus_Radar
import React from 'react';
import BDabout from '../BDabout';
import BDdesc from '../BDdesc';
import BDexp from '../BDexp';
import BDpro from '../BDpro';
import {Helmet} from "react-helmet";

function Bear_Dog_Walking_Service() {
    return (
        <React.Fragment> 
        <Helmet> 
            <title>Bear Dog Walking Service Project | One5 Melbourne</title>
            <meta name="description" content="One5's Professional dog walking and pet care services project in Melbourne. Trustworthy, caring and reliable. Contact us today for a quote." />
            <meta name="keywords" content="Bear Dog Walking Service, Bear Dog Walking Service Project, One5's Bear Dog Walking Service Project" />
        </Helmet>
            <BDabout />
            <BDexp />
            <BDpro />
            <BDdesc />
        </React.Fragment>
    )
}

export default Bear_Dog_Walking_Service

import React from 'react';
import './AXdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function AXdesc() {
    return (
        <div className="axdesc">
            <div className="axdesc-cat2">
                <div className="axdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>HARDWARE JOURNAL</h1>
                    <p data-aos='fade-up'>The Australian Hardware Journal is the gateway to the hardware industry in Australia. The magazine has
                         been published since 1886 and is well positioned to deliver the latest news, views and stories on Australian hardware. </p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/The_Australian_Hardware_Journal">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="axdesc2-right">
                <img className="axdesc2ri" src="./HardwareJournalProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default AXdesc

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './NTabout.css';
import { Link } from 'react-router-dom';

function NTabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className = 'ntabout'>
            <div className = 'ntabout-container'>
				<div className = 'ntaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>NATURAL BY TARA</h1>
                </div>
                <div className = 'ntaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'ntabout-desc'>Natural by Tara is a registered business that qualifies for the distribution of human care products around the globe. 
                        Australian made with Australian Ingredients. All Natural skincare designed to keep you glowing for the long term.</p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='ntabout-btn'>
                        <a href="https://naturalbytara.com/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="ntaboutcol-right">
                    <img className="ntimg" src= "./NatureByTaraAbout_One5Digital.png" alt= "Loading..." />
                    <img className="ntimg2" src= "./NatureByTaraAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default NTabout

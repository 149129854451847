import React from 'react';
import './BADesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function BADesc() {
    return (
        <div className="SDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Our bing ads service includes:</h1>
                    <div className="service-wrapper">
                        <ul className="service-1">
                            <h6 data-aos='fade-up'>BING ADS STRATEGY DEVELOPMENT</h6>
                            <p data-aos='fade-up' className="service-desc">Our experts will work with you to create a Bing Ads strategy that aligns with your business objectives and targets the right audience. We will also identify the best keywords to bid on and create ad copy that resonates with your target audience.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-2">
                            <h6 data-aos='fade-up'>BING ADS CAMPAGIN CREATION</h6>
                            <p data-aos='fade-up' className="service-desc">We will create a Bing Ads campaign that is visually appealing, engages your target audience, and drives conversions. Our team will create ad groups, write compelling ad copy, and set up tracking codes to measure the performance of your campaign.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Ecommerce_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-3">
                            <h6 data-aos='fade-up'>BING ADS OPTIMIZATION</h6>
                            <p data-aos='fade-up' className="service-desc">Our experts will continuously monitor your Bing Ads campaign to identify areas for improvement and optimize your ads for better performance. We will adjust your bids, keywords, and ad copy to ensure your campaign drives the highest ROI possible.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Logo_Design_Melbourne"><Button className='btn' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                        <ul className="service-4">
                            <h6 data-aos='fade-up'>REPORTING AND ANALYSIS</h6>
                            <p data-aos='fade-up' className="service-desc">At One5 Digital, we believe in transparency and accountability. We will provide you with regular reports highlighting the performance of your Bing Ads campaign and offering insights on improving your results.</p>
                            <div data-aos='fade-up' className='service-btn'>
                                {/* <Link exact to="/Services/Website_Design_melbourne"><Button className='btn btn--outline btn--medium' buttonStyle='btn--outline'>Learn more</Button></Link> */}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BADesc
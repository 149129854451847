import React from 'react';
import './PPexp.css';

function PPexp() {
    return (
        <div className="ppexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Poke N Pop Collect is a retailer of toys, memorabilia and other branded merchandise. Visit website for more look. </p>
            </div>
            <div>
                {/* <video className="pp-video" autoPlay loop muted>
                    <source src="./PokenPop_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./PokenPop_One5Digital.gif" alt= "Loading..." className="pp-video"  />
            </div>
        </div>
    )
}

export default PPexp

import React from 'react';
import './AXexp.css';

function AXexp() {
    return (
        <div className="axexp">
            <div>
                <h1 data-aos='fade-up'>HOMEPAGE</h1>
                <p data-aos='fade-up' style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>AxleFix has website design that is simple and easy to use to the users. Visit website for more look.</p>
            </div>
            <div data-aos='fade-up'>
                {/* <video className="ax-video" autoPlay loop muted>
                    <source src="./Axlefix_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./Axlefix_One5Digital.gif" alt= "Loading..." className="ax-video"  />
            </div>
        </div>
    )
}

export default AXexp

import React from 'react';
import './SSdesc.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function SSdesc() {
    return (
        <div className="ssdesc">
            <div className="ssdesc-cat2">
                <div className="ssdesc2-left">
                <p data-aos='fade-up'>───── NEXT CASE STUDY</p>
                    <h1 data-aos='fade-up'>THE ARTISANS BOTTEGA</h1>
                    <p data-aos='fade-up'>The Artisan's Bottega (formerly ‘Cellar Plus’) supplies specialty processing equipment and ingredients used to produce fermented goods and preserved products.</p>
                    <div data-aos='fade-up' className='project-btn'>
                        <Link exact to="/Projects/The_Artisans_Bottega">
                            <Button className='btn' buttonStyle='btn--white' >View Project</Button>
                        </Link>
                    </div>
                </div>
            <div data-aos='fade-up' className="ssdesc2-right">
                <img className="ssdesc2ri" src="./ArtisansProject_One5Digital.png" alt="Loading..." />
            </div>
            </div>
        </div>
    )
}

export default SSdesc

import React from 'react';
import { Helmet } from "react-helmet";
import Testimonials from '../Testimonials';
import FeaturedProject from '../FeaturedProject';
import MalwareAbout from '../MalwareAbout';
import MalwareDesc from '../MalwareDesc';
import Malware_contact from '../Malware_contact';

function Malware_Removal() {
  return (
    <React.Fragment>
      <Helmet>
        <title>One5 Digital's Malware Removal Services in Melbourne: Protecting Your Business from Cyber Threats</title>
        <meta name="description" content="Protect your business from the devastating effects of malware with One5 Digital's expert Malware Removal Services in Melbourne. Our team of cybersecurity professionals will ensure your systems are secure and free of any malicious software." />
        <meta name="keywords" content="Malware Removal, Cybersecurity, Melbourne, Business Protection, One5 Digital" />
      </Helmet>
      <MalwareAbout />
      <MalwareDesc />
      <FeaturedProject />
      <Testimonials />
      <Malware_contact />
    </React.Fragment>
  )
}

export default Malware_Removal
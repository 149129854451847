import React from 'react';
import './SHWDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SHWDesc() {
    return (
        <div className="SMDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>By embracing static HTML website development, businesses can benefit in several ways:</h1>
                    <div className="smdservice-wrapper">
                        <ul className="smdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." /> */}
                            <h6>SPEED & PERFORMANCE</h6>
                            <p>Static websites load quickly since there is no need for server-side processing or database queries. This rapid loading speed ensures an enhanced user experience and reduces bounce rates, ultimately improving search engine rankings.</p>
                        </ul>
                        <ul className="smdservice-2">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h6>ENHANCED SECURITY</h6>
                            <p>With no server-side processing, static websites are inherently more secure than dynamic websites. They are less susceptible to vulnerabilities and attacks, providing peace of mind to businesses and their customers.</p>
                        </ul>
                        <ul className="smdservice-3">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h6>SCALABILITY & RELIABILITY</h6>
                            <p>Static websites are highly scalable, making them ideal for businesses that anticipate heavy web traffic. They can handle large volumes of visitors without compromising performance or stability.</p>
                        </ul>
                        <ul className="smdservice-4">
                            {/* <img src="./server_One5Digital.png" alt="Loading..." /> */}
                            <h6>SEO-FRIENDLY</h6>
                            <p>Static websites are inherently SEO-friendly, thanks to their fast loading times and clean code structure. Search engines tend to favor websites that load quickly, resulting in improved search engine rankings and increased organic traffic.</p>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className='smdesc'>
                <p className='smp'>By leveraging One5 Digital's social media advertising services, businesses can increase their brand's reach and engagement on social media, ultimately driving more traffic and sales to their website.</p>
                <p className='smp'>In conclusion, social media advertising has become essential to any successful digital marketing strategy. With One5 Digital's social media advertising services in Melbourne, businesses can create an effective social media marketing plan that helps them achieve their business objectives. So why wait? Contact One5 Digital today to boost your brand's online presence and reach your target audience on social media!</p>
            </div> */}
        </div>
    )
}

export default SHWDesc
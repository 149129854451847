import React from 'react';
import DSabout from '../DSabout';
import DSdesc from '../DSdesc';
import DSexp from '../DSexp';
import DSpro from '../DSpro';
import {Helmet} from "react-helmet";

function Dr_Green_Shop() {
  return (
    <React.Fragment> 
    <Helmet> 
        <title>Dr Green Cosmetic Shop Project | One5 Digital Agency Melbourne</title>
        <meta name="description" content="One5's Dr. Green Cosmetic Shop project provides high-quality and natural skincare products at the Dr. Green Shop. Shop now and experience the benefits of our organic ingredients." />
        <meta name="keywords" content="Dr. Green Cosmetic Shop, Dr. Green Cosmetic Shop project, One5's Dr. Green Cosmetic Shop project " />
    </Helmet>
        <DSabout />
        <DSexp />
        <DSpro />
        <DSdesc />
    </React.Fragment>
  )
}

export default Dr_Green_Shop
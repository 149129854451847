import React from 'react';
import './SWDDesc.css';
import { Link } from 'react-router-dom';
import { Button } from './Button';

function SWDDesc() {
    return (
        <div className="VDesign-about">
            <div className="la-about">
                <div className="ld-desc">
                    <h1>Why Choose One5 Digital for Shopify Website Development Services?</h1>
                    <div className="vdservice-wrapper">
                        <ul className="vdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." />  */}
                            <h1 className="ecno">01</h1>
                            <h6>TAILROED SOLUTIONS</h6>
                            <p className="vd-desc">One5 Digital takes a client-centric approach to deliver tailored solutions that align with your unique business requirements. They collaborate closely with you to understand your goals, target audience, and brand identity to create a website that reflects your vision.</p>
                        </ul>
                        <ul className="vdservice-2">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">02</h1>
                            <h6>EXPERT TEAM</h6>
                            <p className="vd-desc">The team at One5 Digital comprises talented designers, developers, and marketers who are well-versed in Shopify's latest tools and technologies. They combine their expertise with industry best practices to build high-performing e-commerce websites that leave a lasting impression on visitors.</p>
                        </ul>
                        <ul className="vdservice-3">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">03</h1>
                            <h6>USER EXPERIENCE FOCUS</h6>
                            <p className="vd-desc">A seamless user experience is crucial for any e-commerce website's success. One5 Digital emphasizes user-centric design principles, ensuring that your website is intuitive, visually appealing, and easy to navigate. By optimizing the user journey, they enhance customer satisfaction and drive conversions.</p>
                        </ul>
                    </div>
                    <div className="vdservice-wrapper">
                        <ul className="vdservice-1">
                            {/* <img src="./graphic-design_One5Digital.png" alt="Loading..." />  */}
                            <h1 className="ecno">04</h1>
                            <h6>MOBILE RESPONSIVENESS</h6>
                            <p className="vd-desc">With the majority of online traffic coming from mobile devices, having a mobile-responsive website is non-negotiable. One5 Digital ensures that your Shopify website functions flawlessly across all screen sizes, providing a consistent experience for users on desktops, smartphones, and tablets.</p>
                        </ul>
                        <ul className="vdservice-2">
                            {/* <img src="./website_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">05</h1>
                            <h6>SEARCH ENGINE OPTIMIZATION</h6>
                            <p className="vd-desc">One5 Digital understands the importance of visibility in search engine rankings. Their team incorporates SEO best practices, optimizing your website's structure, content, and metadata to improve its organic search performance and drive relevant traffic to your online store.</p>
                        </ul>
                        <ul className="vdservice-3">
                            {/* <img src="./responsive_One5Digital.png" alt="Loading..." /> */}
                            <h1 className="ecno">06</h1>
                            <h6>ONGOING SUPPORT & MAINTENANCE</h6>
                            <p className="vd-desc">One5 Digital is committed to long-term partnerships with their clients. They provide reliable support and maintenance services to ensure your Shopify website runs smoothly even after its launch. From security updates to performance enhancements, their team has you covered.</p>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SWDDesc
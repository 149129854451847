import React from 'react';
import BrokenLinkError from '../BrokenLinkError';

function Page_Not_Found() {
    return (
        <React.Fragment> 
            <BrokenLinkError />
        </React.Fragment>
    )
}

export default Page_Not_Found

import React from 'react';
import './PEexp.css';

function PEexp() {
    return (
        <div className="peexp">
            <div>
                <h1>HOMEPAGE</h1>
                <p style={{textAlign: 'center', width: '100%', padding: '0 7rem'}}>Power Equipment Australasia magazine's website design has simple and easy to use look to the customers. Visit website for more look.</p>
            </div>
            <div>
                {/* <video className="pe-video" autoPlay loop muted>
                    <source src="./PowerEquip_One5Digital.mp4" type="video/mp4" />
                </video> */}
                <img src="./PowerEquip_One5Digital.gif" alt= "Loading..." className="pe-video"  />
            </div>
        </div>
    )
}

export default PEexp

import React from 'react';
import './RNDesignDesc3.css';

function RNDesignDesc3() {
  return (
    <div className="RNDesign-about2">
        <div data-aos='fade-up' className="sleft-side">
            <img data-aos='fade-up' className="designimg" src="./website_hacked2_desc_One5Digital.png" alt="Loading..." />
        </div>
        <div className='sright-side'>
            <p>One5 Digital offers a comprehensive approach to cybersecurity, ensuring that all aspects of your online assets are protected. They can provide ongoing support and guidance to help you avoid potential cyber threats.</p>
            <p>In conclusion, One5 Digital is a leading cybersecurity company in Melbourne that offers Red Notice website hacked retrieval services. If you're concerned about the security of your online assets, One5 Digital can help you protect them. Their team of cybersecurity experts has the expertise and knowledge to help you stay ahead of potential cyber threats. Contact One5 Digital today to learn more about their services and how they can help you protect your online assets.</p>
        </div>
    </div>
  )
}

export default RNDesignDesc3
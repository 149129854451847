/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './AUabout.css';
import { Link } from 'react-router-dom';

function AUabout() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <div className = 'auabout'>
            <div className = 'auabout-container'>
				<div className = 'auaboutcol-full'>
                    <Link exact to="/Projects"><p>< img src="../Arrow2_One5Digital.png" alt="Loading..." / > BACK TO PROJECTS</p></Link>
                    <h1>AUSPORT</h1>
                </div>
                <div className = 'auaboutcol-left'>
                    <h3>ABOUT CLIENT</h3>
                    <p className = 'auabout-desc'>Ausport SuperStore - Australia’s Baseball, Softball & TeeBall Specialists. 
                        Ausport Superstore established in a small warehouse in 1964, Ausport SuperStore has grown steadily to become one 
                        the largest, specialist sporting goods retailers in Australia.
                    </p>
                    <h3>SERVICE</h3>
                    <div className="ser-wrapper">
                        <ul className="ser-1">
                            <li>Digital Strategy</li>
                            <li>Web Design</li>
                            <li>Branding</li>
                        </ul>
                        <ul className="ser-2">
                            <li>Development</li>
                            <li>User Experience</li>
                            <li>Enterprise UX</li>
                        </ul>
                    </div>
                    <h3 className="year">YEAR</h3>
                    <p>2020</p>
                    <div className='auabout-btn'>
                        <a href="https://ausport.com.au/" target="blank">
                            <Button className='btn' buttonStyle='btn--primary'>Visit Website</Button>
                        </a>
                    </div>
                </div>
                <div className="auaboutcol-right">
                    <img className="auimg" src= "./AusportAbout_One5Digital.png" alt= "Loading..." />
                    <img className="auimg2" src= "./AusportAbout_One5Digital.png" alt= "Loading..." />
                </div>
            </div>
        </div>
    )
}

export default AUabout

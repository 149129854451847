/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import './BrokenLinkError.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function BrokenLinkError() {
    const [button, setButton] = useState(true);
        
    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };
    
    useEffect ( ( ) => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <div className="pnf">
            <div className="pnf-section">
                <img src="./404_One5_Digital.png" alt="Loading..." className="pnf-img" data-aos='fade-up' data-aos-duration="1500"/>
                <h1 data-aos='fade-up' data-aos-duration="1500">Page Not Found</h1>
                <p data-aos='fade-up' data-aos-duration="1500">Sorry can't find the page you are looking for. Click the button to come back to home page.</p>
                <div  className="pnf-btn">
                    <Link excat to="/"><Button className='btn' buttonStyle='btn--primary'>BACK TO HOMEPAGE</Button></Link>
                </div>
            </div>
        </div>
    )
}

export default BrokenLinkError
